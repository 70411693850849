import {
  AddSelection,
  HierarchyConsts,
  HierarchyTransition,
  UpdateSelection,
} from '@components/Hierarchy/HierarchyTree'
import { FinalGradeToString, getColorsAndGrade } from '@src/interfaces/scorecard'
import { RevolutTheme } from '@src/styles/theme'
import { FinalGrade } from '@src/interfaces/performance'
import { GradesMapInterface } from '@src/utils/grades'

const rectVerticalPadding = 5
const rectHorizontalPadding = 10
const rectRadius = 3
const tagMarginTop = 7

const getBackgroundColor = (theme: RevolutTheme, key?: FinalGrade) => {
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  if (key && FinalGradeToString[key]) {
    return getColorsAndGrade(theme, key).background
  }

  return theme.colors['grey-tone-2']
}

export const addTag = (
  theme: RevolutTheme,
  selection: AddSelection,
  t: HierarchyTransition,
  gradesMap: GradesMapInterface,
  showTags?: boolean,
) => {
  /* Tag Text */
  selection
    .append('text')
    .text(
      d => getColorsAndGrade(theme, d.data?.latest_review_score_label, gradesMap).grade,
    )
    .attr('class', 'TagText')
    .attr('pointer-events', 'none')
    .attr('text-anchor', 'middle')
    .attr('fill', d => getColorsAndGrade(theme, d.data?.latest_review_score_label).color)
    .attr('font-size', 11)
    .attr('opacity', showTags ? 1 : 0)
    .attr('dominant-baseline', 'hanging')
    .attr('transform', (d, index, nodes) => {
      const subtitleHeight =
        // @ts-ignore
        nodes[index].previousSibling?.getBBox?.()?.height || 0
      return `translate(${d?.parent?.x ?? d.x}, ${
        (d?.parent?.y ?? d.y) -
        HierarchyConsts.cardHeight +
        HierarchyConsts.avatarSize +
        subtitleHeight +
        tagMarginTop
      })`
    })
    .call(call =>
      call.transition(t).attr('transform', (d, index, nodes) => {
        const subtitleHeight =
          // @ts-ignore
          nodes[index].previousSibling?.getBBox?.()?.height || 0
        return `translate(${d.x}, ${
          d.y -
          HierarchyConsts.cardHeight +
          HierarchyConsts.avatarSize +
          subtitleHeight +
          tagMarginTop
        })`
      }),
    )

  /* Tag Background */
  selection
    .insert('rect', '.TagText')
    .attr('class', 'TagBackground')
    .attr('pointer-events', 'none')
    .attr('fill', d => getBackgroundColor(theme, d.data?.latest_review_score_label))
    .attr('height', 20)
    .attr('opacity', showTags ? 1 : 0)
    .attr('rx', rectRadius)
    .attr('ry', rectRadius)
    .attr('width', (_, index, nodes) => {
      // @ts-ignore
      const textWidth = nodes[index].nextSibling?.getBBox?.()?.width || 0
      return textWidth + rectHorizontalPadding * 2
    })
    .attr('transform', (d, index, nodes) => {
      // @ts-ignore
      const bBox = nodes[index].nextSibling?.getBBox?.()
      const subtitleHeight =
        // @ts-ignore
        nodes[index].previousSibling?.getBBox?.()?.height || 0
      return `translate(${
        (d?.parent?.x ?? d.x) + (bBox?.x || 0) - rectHorizontalPadding
      }, ${
        (d?.parent?.y ?? d.y) -
        HierarchyConsts.cardHeight +
        HierarchyConsts.avatarSize +
        tagMarginTop +
        subtitleHeight -
        rectVerticalPadding
      })`
    })
    .call(call =>
      call.transition(t).attr('transform', (d, index, nodes) => {
        // @ts-ignore
        const bBox = nodes[index].nextSibling?.getBBox?.()
        const subtitleHeight =
          // @ts-ignore
          nodes[index].previousSibling?.getBBox?.()?.height || 0
        return `translate(${d.x + (bBox?.x || 0) - rectHorizontalPadding}, ${
          d.y -
          HierarchyConsts.cardHeight +
          HierarchyConsts.avatarSize +
          tagMarginTop +
          subtitleHeight -
          rectVerticalPadding
        })`
      }),
    )
}

export const updateTag = (
  selection: UpdateSelection,
  t: HierarchyTransition,
  showTags?: boolean,
) => {
  /* Tag Text */
  selection
    .select('.TagText')
    .transition(t)
    .attr('opacity', showTags ? 1 : 0)
    .attr('transform', (d, index, nodes) => {
      const subtitleHeight =
        // @ts-ignore
        nodes[index].previousSibling?.previousSibling?.getBBox?.()?.height || 0
      return `translate(${d.x}, ${
        d.y -
        HierarchyConsts.cardHeight +
        HierarchyConsts.avatarSize +
        tagMarginTop +
        subtitleHeight
      })`
    })

  /* Tag Background */
  selection
    .select('.TagBackground')
    .transition(t)
    .attr('opacity', showTags ? 1 : 0)
    .attr('transform', (d, index, nodes) => {
      const subtitleHeight =
        // @ts-ignore
        nodes[index].previousSibling?.getBBox?.()?.height || 0
      // @ts-ignore
      const bBox = nodes[index].nextSibling?.getBBox?.()
      return `translate(${d.x + (bBox?.x || 0) - rectHorizontalPadding}, ${
        d.y -
        HierarchyConsts.cardHeight +
        HierarchyConsts.avatarSize +
        tagMarginTop +
        subtitleHeight -
        rectVerticalPadding
      })`
    })
}
