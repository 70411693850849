import React, { useEffect } from 'react'
import { Button, Header, Link as UIKitLink, Popup, VStack } from '@revolut/ui-kit'
import StageDetails from '@src/pages/Forms/HiringStage/FormSections/StageDetails'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import EligibleInterviewers from '@src/pages/Forms/HiringStage/FormSections/EligibleInterviewers'
import {
  formHiringProcessStagesRequest,
  formHiringProcessStagesSpecialisationRequest,
} from '@src/api/hiringProcess'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FormattedMessage } from 'react-intl'
import LapeScorecardField from '@src/pages/Forms/HiringStage/FormSections/LapeScorecardField'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { getSelectors } from '@src/api/selectors'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

import { useOpenNewTab } from '@src/actions/RouterActions'

type HiringStagePopupProps = {
  disableStageType: boolean
  isSpecialisation: boolean
  onClose: (result?: HiringProcessInterface) => void
  onRefresh: () => void
}

const HiringStagePopup = ({
  disableStageType,
  isSpecialisation,
  onClose,
  onRefresh,
}: HiringStagePopupProps) => {
  const navigateTo = useOpenNewTab()
  const { values } = useLapeContext<HiringProcessInterface>()
  const isNewStage = !values?.id

  const onFocus = async () => {
    // we create a scorecard in a new tab, close it there, user gets back to this page, we listen a focus event and prefill with the newly created scorecard
    const createdScorecardId = workspaceLocalStorage.getItem(
      LOCAL_STORAGE.HIRING_STAGE_CREATED_SCORECARD,
    )

    if (createdScorecardId) {
      const templatesResp = await getSelectors(selectorKeys.interview_scorecard_templates)
      const templates = templatesResp.data.options

      values.scorecard_template = templates?.find(item => item.id === +createdScorecardId)
      workspaceLocalStorage.removeItem(LOCAL_STORAGE.HIRING_STAGE_CREATED_SCORECARD)
    }
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)

    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [])

  return (
    <Popup open onClose={onClose} shouldKeepMaxHeight size="md">
      <Header variant="compact">
        <Header.Title>
          {isNewStage
            ? `Add ${values?.stage_type?.name ?? 'stage'}`
            : `Edit ${values.title}`}
        </Header.Title>
      </Header>
      <VStack gap="s-16">
        <StageDetails
          disableStageType={disableStageType}
          requiredDuration
          isSpecialisation={isSpecialisation}
          isRole={false}
          hiddenFields={['owner', 'skills', 'playbook', 'stageType']}
          scorecard={
            <LapeScorecardField
              isClearable
              message={
                <UIKitLink
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigateTo(
                      `${pathToUrl(
                        ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS,
                        undefined,
                        { ref: 'onboarding' },
                      )}`,
                    )
                  }}
                >
                  Add new scorecard
                </UIKitLink>
              }
            />
          }
        />
        <EligibleInterviewers isRequired />
      </VStack>
      <Popup.Actions horizontal>
        <Button
          variant="secondary"
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          useValidator
          successText={
            isNewStage
              ? `New ${values.stage_type?.name} saved successfully`
              : `${values.title} saved successfully`
          }
          hideWhenNoChanges={false}
          onAfterSubmit={result => {
            onClose(result as HiringProcessInterface)
            onRefresh()
          }}
        >
          {isNewStage ? (
            <FormattedMessage id="onboarding.interviews.stage.add" defaultMessage="Add" />
          ) : (
            <FormattedMessage
              id="onboarding.interviews.stage.save"
              defaultMessage="Save changes"
            />
          )}
        </NewSaveButtonWithPopup>
      </Popup.Actions>
    </Popup>
  )
}

type HiringStagePopupFormProps = HiringStagePopupProps & {
  stage?: Partial<HiringProcessInterface>
}

export default (props: HiringStagePopupFormProps) => {
  const api = props.isSpecialisation
    ? formHiringProcessStagesSpecialisationRequest
    : formHiringProcessStagesRequest
  return (
    <Form
      api={api}
      forceParams={{ id: props.stage?.id ? props.stage.id.toString() : undefined }}
      initialValues={props.stage}
      disableLocalStorageCaching
      disableLoading
    >
      <HiringStagePopup {...props} />
    </Form>
  )
}
