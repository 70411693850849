import React, { useState } from 'react'
import { Avatar, Box, Item, MoreBar } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklistV2'
import { timeOffPolicyTempAssignments } from '@src/api/timeOff'
import { API } from '@src/constants/api'
import {
  onboardingTimeManagementPolicyBalanceColumn,
  onboardingTimeManagementPolicyCategoryColumn,
  onboardingTimeManagementPolicyGroupColumn,
  onboardingTimeManagementPolicyNameColumn,
} from '@src/constants/columns/onboardingTimeManagement'
import { TableNames } from '@src/constants/table'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { TimeOffPolicyTableInterface } from '@src/interfaces/timeOff'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { AddNewPolicyPopup } from '@src/pages/People/PeopleSubTabs/TimeOffPage/AddNewPolicyPopup'
import { timeManagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import OnboardingActions from '@src/pages/OnboardingChecklistV2/components/OnboardingActions'
import { StepCompletedWidget } from '@src/pages/OnboardingChecklist/components/StepCompletedWidget'
import {
  CommonGenericEditableTableRowOptions,
  GenericEditableTable,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { pathToUrl } from '@src/utils/router'
import { BulkUpdateField, BulkUpdateFieldPopup } from './components/BulkUpdateFieldPopup'

type BulkUpdateFieldPopupState = {
  field: BulkUpdateField
  items: number[]
  refreshTable: () => void
}

const getRow = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<TimeOffPolicyTableInterface>> => ({
  cells: [
    {
      ...onboardingTimeManagementPolicyNameColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementPolicyCategoryColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementPolicyGroupColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementPolicyBalanceColumn(options.onChange),
      width: 200,
    },
  ],
})

export const PolicyConfiguration = () => {
  const [newPolicyPopupOpen, setNewPolicyPopupOpen] = useState(false)
  const [popupState, setPopupState] = useState<BulkUpdateFieldPopupState | null>(null)

  const { data: checkpoint } = useGetOnboardingCheckpointCategory(
    timeManagementConfig.category,
  )
  const stepIsCompleted = checkpoint?.state.id === 'completed'

  return (
    <>
      {stepIsCompleted ? (
        <StepCompletedWidget />
      ) : (
        <>
          <Box mb="s-16">
            <Item>
              <Item.Avatar>
                <Avatar useIcon="InfoOutline" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>We've created some policies for you</Item.Title>
                <Item.Description>
                  By proceeding you agree that you are responsible for being compliant for
                  your locations with the applied policies
                </Item.Description>
              </Item.Content>
            </Item>
          </Box>
          <GenericEditableTable
            apiEndpoint={`${API.TIME_OFF}/policies`}
            entity="policy"
            filterByInitial={[
              {
                columnName: 'status',
                filters: [
                  {
                    name: 'active',
                    id: 'active',
                  },
                ],
                nonResettable: true,
              },
            ]}
            row={getRow}
            onActionColumnEdit={id => {
              navigateTo(
                pathToUrl(
                  ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.CREATE_NEW_POLICY.BASICS,
                  { id: String(id) },
                ),
              )
            }}
            tableActions={props => {
              return (
                <MoreBar>
                  <MoreBar.Action
                    onClick={() => setNewPolicyPopupOpen(true)}
                    useIcon="Plus"
                  >
                    Create new policy
                  </MoreBar.Action>
                  <MoreBar.Action
                    to={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.ELIGIBILITY_GROUPS}
                    use={InternalLink}
                    useIcon="Pencil"
                  >
                    Manage groups
                  </MoreBar.Action>
                  <MoreBar.Action
                    disabled={!props.someSelected}
                    onClick={() => {
                      setPopupState({
                        items: props.getSelectedItems(),
                        field: 'category',
                        refreshTable: props.refreshTableState,
                      })
                    }}
                    useIcon="ArrowRightLeft"
                  >
                    Change category
                  </MoreBar.Action>
                  <MoreBar.Action
                    disabled={!props.someSelected}
                    onClick={() => {
                      setPopupState({
                        items: props.getSelectedItems(),
                        field: 'group',
                        refreshTable: props.refreshTableState,
                      })
                    }}
                    useIcon="People"
                  >
                    Change policy group
                  </MoreBar.Action>
                </MoreBar>
              )
            }}
            tableName={TableNames.ManageTimeOffPolicies}
            variant="existingEntities"
          />
        </>
      )}
      <OnboardingActions
        config={timeManagementConfig}
        currentStep="Policy configuration"
        isForm={false}
        isLastStep={false}
        updateSteps
        disableNext={false}
        pendingNext={false}
        nextRoute={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.ASSIGNMENTS}
        onBeforeSubmit={stepIsCompleted ? undefined : timeOffPolicyTempAssignments}
      />
      {!!popupState && (
        <BulkUpdateFieldPopup
          open
          onClose={() => setPopupState(null)}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          field={popupState.field}
          selectedItems={popupState.items}
        />
      )}
      <AddNewPolicyPopup
        open={newPolicyPopupOpen}
        onClose={() => setNewPolicyPopupOpen(false)}
      />
    </>
  )
}
