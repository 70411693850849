import React, { useState } from 'react'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { intersectionBy, uniq } from 'lodash'
import { z } from 'zod'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'

import { useQuery } from '@src/utils/queryParamsHooks'
import { PageBody } from '@src/components/Page/PageBody'
import MultiInput from '@src/components/Inputs/MultiInput/MultiInput'
import { PageActions } from '@src/components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import { inviteEmployees } from './api'
import { getBackUrl, navigateReplace } from '@src/actions/RouterActions'
import Form from '../Form/Form'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { MAX_INVITE_LIMIT } from '@src/pages/OnboardingChecklistV2/InviteTeam'

const INVALID_EMAIL_ERROR = 'Enter a valid email address.'

const validateEmail = (email: string) =>
  z
    .string()
    .email({
      message: INVALID_EMAIL_ERROR,
    })
    .safeParse(email)

export const InviteEmployeesEmailList = connect(
  ({
    nextRoute,
    assignAdminGroup,
    isCompletingOnboarding,
  }: InviteEmployeesEmailListProps) => {
    return (
      <Form disableLocalStorageCaching>
        <InviteEmployeesEmailListForm
          nextRoute={nextRoute}
          assignAdminGroup={assignAdminGroup}
          isCompletingOnboarding={isCompletingOnboarding}
        />
      </Form>
    )
  },
)

interface FormData {
  data: { email: string; isValid: boolean; apiError?: string }[]
}

interface InviteEmployeesEmailListProps {
  nextRoute: string
  assignAdminGroup: boolean
  isCompletingOnboarding: boolean
}

const InviteEmployeesEmailListForm = ({
  nextRoute,
  assignAdminGroup,
  isCompletingOnboarding,
}: InviteEmployeesEmailListProps) => {
  const { values } = useLapeContext<FormData>()
  const { forceErrors } = useSafeFormValidator()

  const { query, changeQueryParam } = useQuery()
  const params = useParams<{ action?: 'add' }>()

  const statusPopup = useStatusPopup()

  if (!values.data) {
    values.data = []
  }

  const [pending, setPending] = useState(false)

  const validateEmailFunc = (email: string) => validateEmail(email)

  const onChangeMultiInput = (value: string[]) => {
    /** Handle remove item */
    if (value.length < values.data.length) {
      values.data = intersectionBy(
        values.data,
        value.map(email => ({ email })),
        'email',
      )
      return
    }
    /** Handle add items */
    if (value.length > values.data.length) {
      values.data = [
        ...values.data,
        ...value.slice(values.data.length - value.length).map(email => ({
          email,
          isValid: validateEmailFunc(email).success,
        })),
      ]
      return
    }
    /** Handle value change */
    const editedEmails = values.data.map((email, index) => {
      const current = value[index]
      if (email.email === current) {
        return email
      }
      return {
        email: current,
        isValid: validateEmailFunc(current).success,
      }
    })
    values.data = editedEmails
  }

  return (
    <>
      <PageBody>
        <MultiInput
          label="Invitee's email"
          value={values.data.map(e => e.email)}
          onChange={onChangeMultiInput}
          inputErrors={values.data.map(({ email, isValid, apiError }) => {
            if (isValid) {
              return null
            }
            if (apiError) {
              return apiError
            }
            const result = validateEmailFunc(email)
            if (result.success) {
              return null
            }
            return result.error.issues[0].message
          })}
          splitOnPaste
          data-name="email"
        />
      </PageBody>

      <PageActions>
        <Button
          onClick={() => {
            const cleanedUpEmails = values.data
              .map(email => ({ email: email.email.trim(), isValid: email.isValid }))
              .filter(({ email }) => email.length)

            if (cleanedUpEmails.length) {
              const emailsWithValidation = cleanedUpEmails.map(({ email }) => ({
                email,
                isValid: validateEmailFunc(email).success,
              }))

              if (emailsWithValidation.find(({ isValid }) => !isValid)) {
                values.data = emailsWithValidation
                forceErrors(
                  emailsWithValidation.reduce(
                    (acc, val, i) =>
                      val.isValid ? acc : { ...acc, [`email.${i}`]: true },
                    {},
                  ),
                )
                return
              }
            }

            setPending(true)

            inviteEmployees({
              employees: cleanedUpEmails.map(e => ({ email: e.email })),
              assign_it_admin_group: assignAdminGroup,
            })
              .then(response => {
                if (!isCompletingOnboarding) {
                  const newIds = response.data.employees.map(e => `${e.id}`)
                  const previousIds = query.id?.split(',') || ''

                  changeQueryParam(
                    'id',
                    uniq([...previousIds, ...newIds])
                      .filter(Boolean)
                      .join(','),
                  )
                }

                if (params.action === 'add') {
                  const backState = getBackUrl(nextRoute)
                  navigateReplace(backState.pathname, backState.state, true)
                } else {
                  navigateReplace(nextRoute, undefined, true)
                }
              })
              .catch(error => {
                setPending(false)

                try {
                  const globalNonFieldError =
                    error.response?.data?.employees?.non_field_errors?.[0]

                  if (
                    // TODO: https://revolut.atlassian.net/browse/REVPI-3 better error hanlding needed
                    globalNonFieldError?.includes('Ensure this field has no more than')
                  ) {
                    statusPopup.show(
                      <StatusPopup variant="success-result">
                        <StatusPopup.Title>
                          We can only handle {MAX_INVITE_LIMIT} employees at a time
                        </StatusPopup.Title>
                        <StatusPopup.Description>
                          Need to add more? Send invitation by pressing ‘Continue’ and
                          start over with the rest of employees.
                        </StatusPopup.Description>
                        <StatusPopup.Actions>
                          <Button variant="secondary" onClick={statusPopup.hide}>
                            Close
                          </Button>
                        </StatusPopup.Actions>
                      </StatusPopup>,
                    )
                    return
                  }

                  /** We try to map the API error to the email fields */
                  let errorMapped = false

                  values.data = values.data.map(({ email }, i) => {
                    const emailError = error.response?.data?.employees[i]?.email?.[0]
                    const nonFieldError =
                      error.response?.data?.employees[i]?.non_field_errors?.[0]
                    const apiError =
                      typeof emailError === 'string'
                        ? emailError
                        : typeof nonFieldError === 'string'
                        ? nonFieldError
                        : undefined

                    if (apiError) {
                      errorMapped = true
                    }

                    return {
                      email,
                      isValid: !apiError,
                      apiError,
                    }
                  })

                  /** If we can't map the API error to any field, we show generic popup */
                  if (!errorMapped) {
                    throw error
                  }
                } catch {
                  statusPopup.show(
                    <StatusPopup variant="error">
                      <StatusPopup.Title>Failed to invite team</StatusPopup.Title>
                      <StatusPopup.Description>
                        {getStringMessageFromError(error)}
                      </StatusPopup.Description>
                    </StatusPopup>,
                  )
                }
              })
          }}
          disabled={values.data.length === 0 || values.data.some(e => !e.isValid)}
          pending={pending}
          elevated
        >
          Continue
        </Button>
      </PageActions>
    </>
  )
}
