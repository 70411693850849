import React, { useMemo, useRef, useState } from 'react'
import { BREAKPOINTS, MoreBar, Token } from '@revolut/ui-kit'

import {
  CommonGenericEditableTableRowOptions,
  GenericEditableTable,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { CreateRolePopup } from '@src/features/GenericEditableTable/CellPopups/CreateRole'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  ImportInterface,
  ImportSessionStatsInterface,
} from '@src/interfaces/bulkDataImport'
import { API } from '@src/constants/api'
import {
  onboardingRoleHeadcountColumn,
  onboardingRoleNameColumn,
  onboardingRoleOwnerColumn,
  onboardingRoleSeniorityColumn,
} from '@src/constants/columns/onboardingRoles'
import { SpecialisationInterface } from '@src/interfaces/roles'
import {
  BulkUpdateRolePopup,
  ChangeSenioritiesPopup,
  DeleteRolesConfirmationPopup,
  MergeRolesPopup,
} from './components'
import { useOnboardingContext } from '@src/pages/OnboardingChecklistV2/components/OnboardingContext'
import { useTableReturnType } from '@src/components/Table/hooks'
import { GetRequestData } from '@src/interfaces'
import { useGetRolesStats } from './hooks'
import { PageBody } from '@src/components/Page/PageBody'
import OnboardingActions from '../components/OnboardingActions'
import { rolesConfig } from '../common/checkpointsConfig'
import { StatFilters } from '@src/components/StatFilters/StatFilters'

const row =
  (
    onSenioritiesClick: (
      data: SpecialisationInterface,
      refreshTableState: () => void,
    ) => void,
  ) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<SpecialisationInterface>> => ({
    cells: [
      {
        ...onboardingRoleNameColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingRoleHeadcountColumn(options.onChange),
        width: 100,
      },
      {
        ...onboardingRoleSeniorityColumn(options.onChange, data =>
          onSenioritiesClick(data, options.refreshTableState),
        ),
        width: 200,
      },
      {
        ...onboardingRoleOwnerColumn(options.onChange),
        width: 200,
      },
    ],
  })

type AddRolePopupState = {
  type: 'add-role'
  refreshTable: () => void
}
type BulkUpdateFieldPopupState = {
  type: 'bulk-update-field'
  field?: 'owner'
  items?: number[]
  refreshTable: () => void
}
type BulkDeletePopupState = {
  type: 'bulk-delete'
  items?: number[]
  refreshTable: () => void
}
type MergeRolesPopupState = {
  type: 'merge-roles'
  items?: number[]
  refreshTable: () => void
}
type ChangeSenioritiesPopupState = {
  type: 'change-seniorities'
  data: SpecialisationInterface
  refreshTable: () => void
}
type PopupState =
  | null
  | AddRolePopupState
  | BulkUpdateFieldPopupState
  | BulkDeletePopupState
  | MergeRolesPopupState
  | ChangeSenioritiesPopupState

const initialFilter = [
  {
    filters: [
      { id: 'pending', name: 'pending' },
      { id: 'approved', name: 'approved' },
      { id: 'draft', name: 'draft' },
    ],
    columnName: 'status',
    nonResettable: true,
  },
]

export const ReviewRoles = () => {
  const { setNextButtonState } = useOnboardingContext()
  const [popupState, setPopupState] = useState<PopupState>(null)

  const tableRef =
    useRef<
      useTableReturnType<
        ImportInterface<SpecialisationInterface>,
        ImportSessionStatsInterface,
        GetRequestData<ImportInterface<SpecialisationInterface>>
      >
    >()

  const roleStats = useGetRolesStats(initialFilter)

  const filters = useMemo(() => {
    return [
      {
        id: 'total' as const,
        title: 'Total',
        value: roleStats.total,
        loading: roleStats.isLoading,
      },
      roleStats.errors && roleStats.errors > 0
        ? {
            id: 'errors' as const,
            title: 'Errors',
            value: roleStats.errors,
            loading: roleStats.isLoading,
            color: Token.color.error,
          }
        : null,
    ].filter(Boolean)
  }, [roleStats.errors, roleStats.total, roleStats.isLoading])

  const onSenioritiesClick = (
    data: SpecialisationInterface,
    refreshTable: () => void,
  ) => {
    setPopupState({ type: 'change-seniorities', data, refreshTable })
  }

  const onErrorFilterChange = (filter: 'total' | 'errors') => {
    if (!tableRef.current) {
      return
    }
    if (filter === 'total') {
      tableRef.current.resetFiltersAndSorting()
      return
    }
    tableRef.current.onFilterChange([
      {
        columnName: 'id',
        filters: roleStats.errorRows?.map(r => ({ id: r.id, name: '' })) || [],
      },
    ])
  }

  return (
    <>
      <PageBody maxWidthMd={BREAKPOINTS.lg}>
        <GenericEditableTable
          setTableRef={ref => {
            tableRef.current = ref
          }}
          tableInfo={
            <StatFilters
              filters={filters}
              onClick={onErrorFilterChange}
              selectedFilter="none"
            />
          }
          apiEndpoint={API.SPECIALISATIONS}
          tableName={TableNames.RolesOnboarding}
          row={row(onSenioritiesClick)}
          entity="role"
          variant="existingEntities"
          filterByInitial={initialFilter}
          onChangeValidationState={state => {
            if (roleStats.isLoading || roleStats.errors == null || roleStats.errors > 0) {
              return
            }

            if (state === 'valid') {
              setNextButtonState('active')
            } else if (state === 'invalid') {
              setNextButtonState('disabled')
            }
          }}
          sortByInitial={[{ sortBy: 'name', nonResettable: true }]}
          onAfterRefresh={() => roleStats.refetch()}
          tableDataTransform={(data: GetRequestData<SpecialisationInterface>) => ({
            ...data,
            results: data.results.map(r => ({
              id: r.id,
              data: r,
              errors: r.owner == null ? { owner: ['Owner is required'] } : {},
              state: { id: 'pending' as const, name: 'Pending' },
              error_message: null,
              loading: {},
            })),
          })}
          tableActions={props => {
            const selectedItems = props.getSelectedItems()
            const disableActions = selectedItems?.length < 1
            const disableMerge = selectedItems?.length < 2

            return (
              <MoreBar>
                <MoreBar.Action
                  useIcon="Plus"
                  onClick={() => {
                    setPopupState({
                      type: 'add-role',
                      refreshTable: props.refreshTableState,
                    })
                  }}
                >
                  Add role
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon="Materials"
                  onClick={() => {
                    setPopupState({
                      type: 'merge-roles',
                      items: props.getSelectedItems(),
                      refreshTable: props.refreshTableState,
                    })
                  }}
                  disabled={disableMerge}
                >
                  Merge roles
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon="ArrowRightLeft"
                  onClick={() => {
                    setPopupState({
                      type: 'bulk-update-field',
                      field: 'owner',
                      items: props.getSelectedItems(),
                      refreshTable: props.refreshTableState,
                    })
                  }}
                  disabled={disableActions}
                >
                  Change owner
                </MoreBar.Action>
                <MoreBar.Action
                  onClick={() => {
                    setPopupState({
                      type: 'bulk-delete',
                      items: props.getSelectedItems(),
                      refreshTable: props.refreshTableState,
                    })
                  }}
                  variant="negative"
                  useIcon="Delete"
                  disabled={disableActions}
                >
                  Delete
                </MoreBar.Action>
              </MoreBar>
            )
          }}
          deleteConfirmation={props =>
            props.id ? (
              <DeleteRolesConfirmationPopup
                open={props.open}
                onClose={props.onClose}
                rolesToDelete={[props.id]}
                onSuccess={props?.onSuccess}
              />
            ) : null
          }
        />
      </PageBody>

      {popupState?.type === 'add-role' && (
        <CreateRolePopup
          open
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
        />
      )}

      {popupState?.type === 'merge-roles' && (
        <MergeRolesPopup
          open
          onClose={() => setPopupState(null)}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          unitsToMerge={popupState.items}
        />
      )}

      {popupState?.type === 'bulk-update-field' && (
        <BulkUpdateRolePopup
          open
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
          entity={popupState.field}
          selectedRoles={popupState.items}
        />
      )}

      {popupState?.type === 'bulk-delete' && (
        <DeleteRolesConfirmationPopup
          open
          onClose={() => setPopupState(null)}
          rolesToDelete={popupState.items}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
        />
      )}

      {popupState?.type === 'change-seniorities' && (
        <ChangeSenioritiesPopup
          open
          data={popupState.data}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => setPopupState(null)}
        />
      )}

      <OnboardingActions
        currentStep="Review roles"
        config={rolesConfig}
        isLastStep
        disableNext={
          roleStats.isLoading || roleStats.errors == null || roleStats.errors > 0
        }
        pendingNext={false}
        updateSteps
        nextRoute=""
        isForm={false}
      />
    </>
  )
}
