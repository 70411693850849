import { CustomFieldTypes } from '@src/constants/customFields'

export const IS_EMPLOYEE_UPDATE_QUERY_KEY = 'employeeUpdate'

export const supportedEmployeeCustomFieldsTypes: CustomFieldTypes[] = [
  CustomFieldTypes.Text,
  CustomFieldTypes.Number,
  CustomFieldTypes.Date,
  CustomFieldTypes.Dropdown,
  CustomFieldTypes.MultipleSelector,
]
