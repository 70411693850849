import React, { useEffect, useMemo, useState } from 'react'
import {
  ColumnCellInterface,
  FilterByInterface,
  FilterType,
  RowInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Bar, chain, Color, FilterButton, MoreBar, TableWidget } from '@revolut/ui-kit'

import {
  candidateActionColumn,
  candidateInterviewerColumn,
  candidateInterviewStageColumn,
  candidateLastActivityDateColumn,
  candidateNameColumn,
  candidateNextInterviewColumn,
  candidateOfferApprovalStatusColumn,
  candidateOfferLocationColumn,
  candidateOfferStartDateColumn,
  candidateOfferTeamColumn,
  candidateOriginColumn,
  candidateRecruiterColumn,
  createCandidateRequisitionColumn,
  candidateSeniorityColumn,
  candidateTagsColumn,
  candidateSpecialisationColumn,
  candidateStateColumn,
  candidateStatusColumn,
  candidateWorkExperienceColumn,
  candidateYearsOfExperienceColumn,
  candidateSnoozedUntilDateColumn,
} from '@src/constants/columns/candidates'
import {
  CandidateInterface,
  InterviewRoundState,
  InterviewType,
} from '@src/interfaces/interviewTool'
import { interviewCandidatesRequests } from '@src/api/recruitment/interviews'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import {
  Anonymous,
  Archive,
  Backpack,
  Basketball,
  Document,
  DocumentsPair,
  Dot,
  Filter,
  Groups,
  LineChart,
  LocationPin,
  MapPoint,
  Plus,
  Profile,
  RepairTool,
  Services,
  Time,
  TimeAndMoney,
} from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { getSelectCellConfig } from '@components/Table/AdvancedCells/SelectCell/SelectCell'
import CandidatesPipelineFilter, {
  getCandidatesPipelineFilters,
  getSelectedTab,
} from '@components/CandidatesPipelineFilter/CandidatesPipelineFilter'
import FiltersSidebar, {
  getAppliedFiltersCount,
} from '@components/FiltersSidebar/FiltersSidebar'
import { FiltersSidebarItemInterface } from '@components/FiltersSidebar/FiltersSidebarItem'
import { getColor } from '@src/styles/colors'
import CandidateSendEmailSidebar from '@components/CandidateSendEmailSidebar/CandidateSendEmailSidebar'
import ArchivingOpportunitySidebar from '@components/ArchivingCandidateSidebar/ArchivingCandidateSidebar'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { HiringPipelineInterface } from '@src/interfaces/hiringPipelines'
import { useGetCandidatesPipelineView } from '@src/api/recruitment/candidates'
import ScreenCandidatesButton from '@src/features/CommonCandidatesTable/ScreenCandidatesButton'
import ReassignRecruiterSidebar, {
  ReassignRecruiterAction,
} from './ReassignRecruiterSidebar'
import MoveToStageSidebar, {
  MoveToStageAction,
} from '@src/features/CommonCandidatesTable/MoveToStageSidebar'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import SendOnlineTestSidebar, { SendOnlineTestAction } from './SendOnlineTestSidebar'
import { useGetCandidateSettings, useGetHiringProcessSettings } from '@src/api/settings'
import { getConfidentialFilter } from '../CommonRequisitionTable/ShowConfidentialFilter'
import { renderOptionFilterSidebarItem } from '@src/components/JobPostingOption/JobPostingOption'
import {
  BulkSnoozeAction,
  BulkSnoozePopup,
} from '@src/pages/Forms/Candidate/InterviewProgress/components/Snooze'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { IdAndName } from '@src/interfaces'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { useFetchBulkIds } from '@src/hooks/useFetchBulkIds'
import { SettingsButton } from '../SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import { BookingLinkAction } from '@src/features/CommonCandidatesTable/Actions/BookingLinkAction'
import { OffersAction } from '@src/features/CommonCandidatesTable/Actions/OffersAction'
import { EmailTemplatesAction } from '@src/features/CommonCandidatesTable/Actions/EmailTemplatesAction'
import { CRMAction } from '@src/features/CommonCandidatesTable/Actions/CRMAction'
import { ReferralBonusAction } from '@src/features/CommonCandidatesTable/Actions/ReferralBonusAction'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { ViewBulkAction } from '@src/features/CommonCandidatesTable/Actions/ViewBulkAction'

type CandidatesType =
  | 'specialisation'
  | 'requisition'
  | 'common'
  | 'hiringPipeline'
  | 'cvScreeningSummary'

interface Props {
  data?: SpecialisationInterface | RequisitionInterface | HiringPipelineInterface
  type: CandidatesType
  filterBy?: FilterByInterface[]
  compact?: boolean
  children?: React.ReactNode
}

const sidebarFilters: FiltersSidebarItemInterface[] = [
  {
    label: 'Candidate state',
    field: 'active_interview_round__state',
    filterType: FilterType.selector,
    selector: selectorKeys.interview_round_states,
    icon: Dot,
  },
  {
    label: 'Last activity date',
    field: 'latest_event_date_time',
    filterType: FilterType.date,
    icon: LineChart,
  },
  {
    label: 'Confidential candidates only',
    field: 'is_confidential',
    icon: Anonymous,
    filterType: FilterType.boolean,
  },
  {
    label: 'Sourced by',
    field: 'active_interview_round__created_by',
    filterType: FilterType.selector,
    selector: selectorKeys.employee,
    icon: Profile,
  },
  {
    label: 'Main job posting',
    field: 'active_interview_round__application_forms__job_posting',
    filterType: FilterType.selector,
    selector: selectorKeys.all_job_postings_locations_type,
    renderOption: renderOptionFilterSidebarItem,
    icon: Document,
  },
  {
    label: 'Hiring manager',
    field: 'hiring_manager',
    filterType: FilterType.selector,
    selector: selectorKeys.employee,
    icon: Profile,
  },
  {
    label: 'Archival reason',
    field: 'active_interview_round__archived_reason',
    filterType: FilterType.selector,
    selector: selectorKeys.interview_round_archived_reasons,
    icon: Archive,
  },
  {
    label: 'Candidate’s current country',
    field: 'country',
    filterType: FilterType.selector,
    selector: selectorKeys.countries,
    icon: LocationPin,
  },
  {
    label: 'Candidate’s preferred work location',
    field: 'preferred_locations',
    filterType: FilterType.selector,
    selector: selectorKeys.location_names,
    icon: MapPoint,
  },
  {
    label: 'Candidate’s companies',
    field: 'work_experiences__company',
    filterType: FilterType.selector,
    selector: selectorKeys.work_experience_companies,
    icon: Services,
  },
  {
    label: 'Candidate’s current company',
    field: 'current_work_experience__company',
    filterType: FilterType.selector,
    selector: selectorKeys.work_experience_companies,
    icon: Services,
  },
  {
    label: 'Candidate’s titles',
    field: 'work_experiences__position',
    filterType: FilterType.selector,
    selector: selectorKeys.work_experience_positions,
    icon: RepairTool,
  },
  {
    label: 'Candidate’s current title',
    field: 'current_work_experience__position',
    filterType: FilterType.selector,
    selector: selectorKeys.work_experience_positions,
    icon: RepairTool,
  },
  {
    label: 'Candidate’s university',
    field: 'educations__university',
    filterType: FilterType.selector,
    selector: selectorKeys.universities,
    icon: Backpack,
  },
  {
    label: 'Candidate’s major',
    field: 'educations__major',
    filterType: FilterType.selector,
    selector: selectorKeys.education_majors,
    icon: Groups,
  },
  {
    label: 'Candidate’s degree',
    field: 'educations',
    filterType: FilterType.selector,
    selector: selectorKeys.degrees,
    icon: Basketball,
  },
  {
    label: 'Candidate’s created date',
    field: 'created_date_time',
    filterType: FilterType.date,
    icon: TimeAndMoney,
  },
  {
    label: 'Interview round created date',
    field: 'active_interview_round__created_date_time',
    filterType: FilterType.date,
    icon: Time,
  },
  {
    label: 'Snoozed',
    field: 'is_snoozed',
    filterType: FilterType.selector,
    selector: selectorKeys.yes_no_uppercase_value_options,
    icon: Time,
  },
  {
    label: 'Snoozed date',
    field: 'active_snoozing__snooze_until',
    filterType: FilterType.shortDate,
    icon: Time,
  },
  {
    label: 'All job postings',
    field: 'interview_rounds__application_forms__job_posting',
    filterType: FilterType.selector,
    selector: selectorKeys.all_job_postings_locations_type,
    renderOption: renderOptionFilterSidebarItem,
    icon: DocumentsPair,
  },
]

const row = (
  showAssignedToMe: boolean,
  showStatus: boolean,
  showSnoozedUntil: boolean,
  type: CandidatesType,
  isConfidential?: boolean,
  stageType?: InterviewType,
): RowInterface<CandidateInterface> => {
  const columns = {
    name: {
      ...candidateNameColumn,
      width: 250,
      filterKey: type === 'cvScreeningSummary' ? null : candidateNameColumn.filterKey,
    },
    specialisation: { ...candidateSpecialisationColumn, width: 250 },
    seniority: { ...candidateSeniorityColumn, width: 140 },
    tags: { ...candidateTagsColumn, width: 180 },
    interviewStage: { ...candidateInterviewStageColumn, width: 200 },
    status: { ...candidateStatusColumn, width: showStatus ? 200 : 250 },
    lastActivityDate: { ...candidateLastActivityDateColumn, width: 180 },
    origin: { ...candidateOriginColumn, width: 140 },
    recruiter: {
      ...candidateRecruiterColumn,
      filterKey: showAssignedToMe ? null : 'recruiter',
      selectorsKey: showAssignedToMe ? selectorKeys.none : selectorKeys.employee,
      width: showStatus ? 160 : 210,
    },
    startDate: { ...candidateOfferStartDateColumn, width: 140 },
    company: { ...candidateWorkExperienceColumn, width: 210 },
    yearsOfExperience: { ...candidateYearsOfExperienceColumn, width: 140 },
    location: { ...candidateOfferLocationColumn, width: 140 },
    team: { ...candidateOfferTeamColumn, width: 140 },
    approvalStatus: { ...candidateOfferApprovalStatusColumn, width: 140 },
    interviewer: { ...candidateInterviewerColumn, width: 140 },
    nextInterviewer: { ...candidateNextInterviewColumn, width: 140 },
    action: { ...candidateActionColumn, width: showStatus ? 160 : 210 },
    state: { ...candidateStateColumn, width: 150 },
    requisition: { ...createCandidateRequisitionColumn(isConfidential), width: 200 },
    snoozedUntilDate: { ...candidateSnoozedUntilDateColumn, width: 140 },
  }
  let cells: ColumnCellInterface<CandidateInterface>[] = []
  switch (stageType) {
    case 'offer': {
      cells = [
        columns.name,
        columns.lastActivityDate,
        columns.recruiter,
        columns.specialisation,
        columns.company,
        columns.yearsOfExperience,
        columns.seniority,
        columns.tags,
        columns.startDate,
        columns.location,
        columns.team,
        columns.status,
        columns.approvalStatus,
        ...(showSnoozedUntil ? [columns.snoozedUntilDate] : []),
      ]
      break
    }
    default: {
      cells = [
        columns.name,
        ...(type !== 'specialisation' && type !== 'requisition'
          ? [columns.specialisation]
          : []),
        columns.company,
        columns.yearsOfExperience,
        columns.seniority,
        columns.tags,
        columns.interviewStage,
        columns.status,
        columns.lastActivityDate,
        columns.origin,
        columns.recruiter,
        ...(stageType === 'cv_screening'
          ? []
          : [columns.interviewer, columns.nextInterviewer]),
        columns.action,
        ...(showStatus ? [columns.state] : []),
        columns.requisition,
        ...(showSnoozedUntil ? [columns.snoozedUntilDate] : []),
      ]
      break
    }
  }
  return {
    highlight: (data, theme) =>
      data.active_interview_round?.state === 'archived'
        ? getColor(theme, Color.GREY_TONE_5)
        : '',
    cells: [{ ...getSelectCellConfig() }, ...cells],
  }
}

type OpenSidebarType =
  | 'filters'
  | 'sendEmails'
  | 'archive'
  | 'reassign-recruiter'
  | 'move-to-stage'
  | 'send-online-test'
  | 'snooze'
  | 'unsnooze'

const CommonCandidatesTableBase = ({
  data,
  type,
  filterBy,
  compact,
  children,
}: Props) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const [isConfidential, setIsConfidential] = useState<boolean>()
  const [showAssignedToMe, setShowAssignedToMe] = useState(
    !!workspaceLocalStorage.getItem(LOCAL_STORAGE.SHOW_CANDIDATES_ASSIGNED_TO_ME),
  )
  const [showArchived, setShowArchived] = useState(
    !!workspaceLocalStorage.getItem(LOCAL_STORAGE.SHOW_ARCHIVED_CANDIDATES),
  )
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<CandidateInterface>>()
  const [openSidebar, setOpenSidebar] = useState<OpenSidebarType>()

  const canAddCandidate = permissions?.includes(PermissionTypes.AddRecruitmentCandidate)
  const canImportCandidates = permissions?.includes(
    PermissionTypes.RecruitmentPermissions,
  )
  const { data: candidateSettings } = useGetCandidateSettings()
  const { data: hiringProcessSettings } = useGetHiringProcessSettings()
  const [, setSavedCandidatesContext] = useLocalStorage<{
    filterBy: FilterByInterface[]
    candidates: IdAndName[]
  }>(LOCAL_STORAGE.CANDIDATES_TABLE_CONTEXT, { filterBy: [], candidates: [] })

  const getFilterByRecruiter = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'recruiter',
    nonResettable: true,
  })

  const getFilterByState = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          { id: InterviewRoundState.active, name: InterviewRoundState.active },
          { id: InterviewRoundState.archived, name: InterviewRoundState.archived },
          { id: InterviewRoundState.hired, name: InterviewRoundState.hired },
        ]
      : [{ id: InterviewRoundState.active, name: InterviewRoundState.active }],
    columnName: 'active_interview_round__state',
    nonResettable: true,
  })

  const getInitialFilter = () => {
    const filter: FilterByInterface[] = [
      ...(filterBy || []),
      getConfidentialFilter(),
      {
        filters: [
          {
            name: 'False',
            id: 'False',
          },
        ],
        columnName: 'is_snoozed',
        nonResettable: true,
      },
    ]

    if (data) {
      if (type === 'specialisation') {
        filter.push({
          filters: [{ name: (data as SpecialisationInterface).name, id: data.id }],
          columnName: 'active_interview_round__specialisation',
          nonResettable: true,
        })
      }

      if (type === 'requisition') {
        const specialisation = (data as RequisitionInterface).specialisation

        filter.push({
          filters: [
            {
              name: specialisation.name,
              id: specialisation.id,
            },
          ],
          columnName: 'active_interview_round__specialisation',
          nonResettable: true,
        })
      }

      if (type === 'hiringPipeline') {
        const hiringPipelineId = (data as HiringPipelineInterface).id

        filter.push({
          filters: [
            {
              name: String(hiringPipelineId),
              id: hiringPipelineId,
            },
          ],
          columnName: 'active_hiring_pipeline',
          nonResettable: true,
        })
      }
    }

    if (showAssignedToMe) {
      filter.push(getFilterByRecruiter(true))
    }

    filter.push(getFilterByState(showArchived))

    return filter
  }

  const initialSortBy = [
    {
      sortBy: 'inactivity_reason',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const initialFilter = uniqBy(getInitialFilter(), 'columnName')

  const table = useTable<CandidateInterface, undefined>(
    interviewCandidatesRequests,
    initialFilter,
    initialSortBy,
  )

  useEffect(() => {
    if (filterBy) {
      table.onFilterChange(filterBy)
    }
  }, [filterBy])

  useEffect(() => {
    setSavedCandidatesContext({
      filterBy: table.filterBy,
      candidates: table.data.slice(0, 10000).map(item => ({
        id: item.id,
        name: item.full_name,
      })),
    })
  }, [table.data, table.filterBy])

  const { data: candidatesPipelineData } = useGetCandidatesPipelineView(
    getCandidatesPipelineFilters(table.filterBy),
  )

  const onToggleMyCandidates = () => {
    if (showAssignedToMe) {
      workspaceLocalStorage.removeItem(LOCAL_STORAGE.SHOW_CANDIDATES_ASSIGNED_TO_ME)
    } else {
      workspaceLocalStorage.setItem(LOCAL_STORAGE.SHOW_CANDIDATES_ASSIGNED_TO_ME, 'true')
    }
    setShowAssignedToMe(!showAssignedToMe)
    table.onFilterChange(getFilterByRecruiter(!showAssignedToMe))
  }

  const onToggleArchivedCandidates = () => {
    if (showArchived) {
      workspaceLocalStorage.removeItem(LOCAL_STORAGE.SHOW_ARCHIVED_CANDIDATES)
    } else {
      workspaceLocalStorage.setItem(LOCAL_STORAGE.SHOW_ARCHIVED_CANDIDATES, 'true')
    }
    setShowArchived(!showArchived)
    table.onFilterChange(getFilterByState(!showArchived))
  }

  const [
    selectedIds,
    selectedCandidates,
    selectedSpecialisationIds,
    allCandidatesAreSnoozed,
  ] = useMemo(() => {
    let candidateIds = selectedData?.selectedRowsIds
      ? Array.from(selectedData?.selectedRowsIds)
      : []
    if (selectedData?.isAllSelected) {
      candidateIds = table.data.reduce<string[]>((acc, item) => {
        if (!selectedData?.excludeListIds?.has(String(item.id))) {
          acc.push(String(item.id))
        }

        return acc
      }, [])
    }
    const candidates = table.data.filter(({ id }) => candidateIds.includes(String(id)))
    const specialisations = uniqBy(
      map(candidates, 'active_interview_round.specialisation'),
      'id',
    )
    const specialisationIds = map(specialisations, 'id')
    const snoozed = candidates.every(({ active_snoozing }) => active_snoozing)
    return [candidateIds, candidates, specialisationIds, snoozed]
  }, [selectedData, table.data])
  const bulkActionMode = !!selectedIds.length

  const { bulkIds, fetchBulkIds } = useFetchBulkIds<CandidateInterface>(
    '/interviews/candidates',
    table,
    selectedData,
  )

  const stageType = getSelectedTab(table.filterBy, candidatesPipelineData)?.stage_type
  const showStatus = !!table.filterBy
    .find(item => item.columnName === 'active_interview_round__state')
    ?.filters?.some(item => item.id !== 'active')
  const showSnoozedUntil = !!table.filterBy
    .find(item => item.columnName === 'is_snoozed')
    ?.filters?.some(item => item.id === 'True')
  const disabledBulkActions = !selectedIds.length && !selectedData?.isAllSelected

  const searchBar = (
    <SearchTable
      placeholder="Search by name, email, role, current company"
      onFilter={table.onFilterChange}
      ml={0}
    />
  )

  return (
    <>
      <TableWidget>
        {compact ? (
          <>
            <TableWidget.Info>{children}</TableWidget.Info>
            <TableWidget.Search>{searchBar}</TableWidget.Search>
          </>
        ) : (
          <>
            <TableWidget.Info>
              <CandidatesPipelineFilter
                filters={table.filterBy}
                onFilterChange={table.onFilterChange}
              />
            </TableWidget.Info>
            <TableWidget.Search>{searchBar}</TableWidget.Search>
            <TableWidget.Actions>
              <MoreBar
                maxCount={bulkActionMode ? undefined : 3}
                labelMoreButton="More candidate actions"
              >
                {!bulkActionMode && (
                  <>
                    {canAddCandidate && (
                      <MoreBar.Action
                        useIcon={Plus}
                        use={InternalLink}
                        to={pathToUrl(ROUTES.FORMS.NEW_CANDIDATE.GENERAL)}
                      >
                        Add candidate
                      </MoreBar.Action>
                    )}
                    {type === 'common' && (
                      <>
                        <BookingLinkAction />
                        <SettingsButton
                          path={ROUTES.SETTINGS.CANDIDATES.LIST}
                          canView={settingsConfig.candidates.canView}
                        />
                        <CRMAction />
                        <EmailTemplatesAction />
                        <ViewBulkAction />
                        <OffersAction />
                        <ReferralBonusAction />
                        {canImportCandidates && (
                          <MoreBar.Action
                            use={InternalLink}
                            to={pathToUrl(
                              ROUTES.FORMS.IMPORT_DATA.CANDIDATES.UPLOAD_FILE,
                            )}
                            useIcon="ShareIOs"
                          >
                            Import candidates
                          </MoreBar.Action>
                        )}
                      </>
                    )}
                  </>
                )}
                {stageType === 'cv_screening' && (
                  <ScreenCandidatesButton
                    candidates={selectedCandidates}
                    disabled={disabledBulkActions}
                  />
                )}
                {bulkActionMode && (
                  <>
                    {candidateSettings?.enable_emails && (
                      <MoreBar.Action
                        onClick={async () => {
                          sendAnalyticsEvent(AnalyticsEvents.click_bulk_send_emails_btn)
                          await fetchBulkIds()
                          setOpenSidebar('sendEmails')
                        }}
                        disabled={disabledBulkActions}
                        useIcon="Envelope"
                      >
                        Send emails
                      </MoreBar.Action>
                    )}
                    <MoreBar.Action
                      onClick={async () => {
                        sendAnalyticsEvent(AnalyticsEvents.click_bulk_archive_btn)
                        await fetchBulkIds()
                        setOpenSidebar('archive')
                      }}
                      disabled={disabledBulkActions}
                      useIcon={Archive}
                      variant="negative"
                    >
                      Archive all
                    </MoreBar.Action>
                    <BulkSnoozeAction
                      disabled={false}
                      unsnooze={false}
                      onClick={async () => {
                        await fetchBulkIds()
                        setOpenSidebar('snooze')
                      }}
                    />
                    <BulkSnoozeAction
                      disabled={!allCandidatesAreSnoozed}
                      unsnooze
                      onClick={async () => {
                        await fetchBulkIds()
                        setOpenSidebar('unsnooze')
                      }}
                    />
                    <ReassignRecruiterAction
                      onClick={async () => {
                        await fetchBulkIds()
                        setOpenSidebar('reassign-recruiter')
                      }}
                      disabled={disabledBulkActions}
                    />

                    <MoveToStageAction
                      disabled={selectedSpecialisationIds.length > 1}
                      onClick={async () => {
                        await fetchBulkIds()
                        setOpenSidebar('move-to-stage')
                      }}
                    />
                    {hiringProcessSettings?.enable_online_test_stage && (
                      <SendOnlineTestAction
                        disabled={selectedSpecialisationIds.length > 1}
                        onClick={async () => {
                          await fetchBulkIds()
                          setOpenSidebar('send-online-test')
                        }}
                      />
                    )}
                  </>
                )}
              </MoreBar>
            </TableWidget.Actions>
            <TableWidget.Filters>
              <Bar>
                <FilterButton onClick={onToggleArchivedCandidates} active={showArchived}>
                  Archived
                </FilterButton>
                <FilterButton onClick={onToggleMyCandidates} active={showAssignedToMe}>
                  My candidates
                </FilterButton>
                <FilterButton
                  useIcon={Filter}
                  onClick={() => {
                    sendAnalyticsEvent(AnalyticsEvents.click_candidates_table_filter_btn)
                    setOpenSidebar(prevState =>
                      prevState === 'filters' ? undefined : 'filters',
                    )
                  }}
                  onClear={() => {
                    table.resetFiltersAndSorting()
                  }}
                >
                  {chain(
                    'Filters',
                    getAppliedFiltersCount(table.filterBy, sidebarFilters),
                  )}
                </FilterButton>
              </Bar>
            </TableWidget.Filters>
          </>
        )}
        <TableWidget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
          >
            <AdjustableTable<CandidateInterface>
              name={TableNames.CommonCandidates}
              useWindowScroll
              dataType="Candidate"
              row={row(
                showAssignedToMe,
                showStatus,
                showSnoozedUntil,
                type,
                isConfidential,
                stageType,
              )}
              {...table}
              noDataMessage="Candidates will appear here. Please check your referred candidates in your HR profile"
              tableSettings={{
                hidden: ['Requisition'],
                visible: [],
              }}
            />
          </SelectTableWrapper>
        </TableWidget.Table>
      </TableWidget>

      <FiltersSidebar
        open={openSidebar === 'filters'}
        onClose={() => setOpenSidebar(undefined)}
        items={sidebarFilters}
        filters={table.filterBy}
        onFilter={filters => {
          table.onFilterChange(
            filters.map(f => {
              if (f.columnName === 'is_confidential') {
                const value = f.filters[0].id === 'True'
                setIsConfidential(value ? true : undefined)
                return getConfidentialFilter(value ? true : undefined)
              }
              return f
            }),
          )
        }}
      />

      {candidateSettings?.enable_emails && (
        <CandidateSendEmailSidebar
          isOpen={openSidebar === 'sendEmails'}
          onClose={() => setOpenSidebar(undefined)}
          candidateIds={bulkIds}
          bulk
        />
      )}

      <ArchivingOpportunitySidebar
        candidateIds={bulkIds}
        open={openSidebar === 'archive'}
        onClose={() => setOpenSidebar(undefined)}
        onAfterArchive={() => setOpenSidebar(undefined)}
      />

      <ReassignRecruiterSidebar
        candidateIds={bulkIds}
        open={openSidebar === 'reassign-recruiter'}
        onClose={() => setOpenSidebar(undefined)}
      />

      <MoveToStageSidebar
        candidateIds={bulkIds}
        specialisationIds={selectedSpecialisationIds}
        open={openSidebar === 'move-to-stage'}
        onClose={() => setOpenSidebar(undefined)}
      />

      {openSidebar === 'send-online-test' && (
        <SendOnlineTestSidebar
          candidates={selectedCandidates}
          candidateIds={bulkIds}
          specialisationIds={selectedSpecialisationIds}
          open
          onClose={() => setOpenSidebar(undefined)}
        />
      )}

      <BulkSnoozePopup
        open={openSidebar === 'snooze'}
        onClose={() => setOpenSidebar(undefined)}
        unsnooze={false}
        onAfterSubmit={() => {
          table.refresh()
          setSelectedData(undefined)
        }}
        candidateIds={bulkIds}
      />

      <BulkSnoozePopup
        open={openSidebar === 'unsnooze'}
        onClose={() => setOpenSidebar(undefined)}
        onAfterSubmit={() => {
          table.refresh()
          setSelectedData(undefined)
        }}
        candidateIds={bulkIds}
        unsnooze
      />
    </>
  )
}

const CommonCandidatesTable = (props: Props) => {
  if (props.type === 'common') {
    return (
      <OnboardingAppScreen category="candidates">
        <CommonCandidatesTableBase {...props} />
      </OnboardingAppScreen>
    )
  }

  return <CommonCandidatesTableBase {...props} />
}

export default CommonCandidatesTable
