import React, { useMemo } from 'react'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { Box, chain, Flex, Icon, Text, Token } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Id } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { HiringStageJobPostings } from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/HiringStageJobPostings'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import sortBy from 'lodash/sortBy'
import last from 'lodash/last'

type OptionType = InterviewRoundInterface | Id<string>

type SpecialisationHiringStageProps = {
  item: InterviewRoundInterface
  isMainRound: boolean
}

const SpecialisationHiringStage = ({
  item,
  isMainRound,
}: SpecialisationHiringStageProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const baseInterviewRoundOnJobPosting = featureFlags.includes(
    FeatureFlags.BaseInterviewRoundOnJobPosting,
  )
  const mainJobPosting = last(sortBy(item.application_forms, 'id'))
  if ('archived_reason' in item) {
    const specialisationName = item.specialisation.name
    const jobPostingName = mainJobPosting?.job_posting?.name
    const opportunityTitle = baseInterviewRoundOnJobPosting
      ? jobPostingName || specialisationName
      : specialisationName
    return (
      <>
        <Text
          variant="h6"
          color={item.archived_reason ? Token.color.greyTone50 : undefined}
        >
          {chain(
            opportunityTitle,
            isMainRound && <Text color={Token.color.green}>Main</Text>,
            !!item.archived_reason && <Text>Archived</Text>,
          )}
        </Text>
        {item.archived_reason && (
          <Box>
            <Text variant="small" color={Token.color.greyTone50}>
              {chain(
                item.latest_interview_stage_updated_date_time &&
                  formatDate(item.latest_interview_stage_updated_date_time),
                item.archived_reason.name,
              )}
            </Text>
          </Box>
        )}
        {item.latest_interview_stage && (
          <Box>
            <Text variant="small" color={Token.color.greyTone50}>
              {item.latest_interview_stage.interview_type_display}&nbsp;
              {!!item.stage_count &&
                Number.isFinite(item.latest_interview_stage.interview_number) &&
                `(${item.latest_interview_stage.interview_number}/${item.stage_count})`}
            </Text>
          </Box>
        )}
        {baseInterviewRoundOnJobPosting ? (
          jobPostingName && (
            <Box>
              <Text variant="small" color={Token.color.greyTone50}>
                Specialisation: {specialisationName}
              </Text>
            </Box>
          )
        ) : (
          <HiringStageJobPostings
            round={item}
            textVariant="small"
            labelVariant="small"
            textColor={Token.color.greyTone50}
          />
        )}
      </>
    )
  }
  return (
    <Flex alignItems="center" color={Token.color.blue}>
      <Icon name="Plus" size={16} color={Token.color.blue} />
      &nbsp;Consider for another opportunity
    </Flex>
  )
}

type Props = {
  candidateId: number
  roundId: number
  mainRoundId?: number
  onChange: (roundId: number) => void
}

export const HiringStageSwitcher = ({
  candidateId,
  roundId,
  mainRoundId,
  onChange,
}: Props) => {
  const { data, isLoading } = useGetFullInterviewRounds(candidateId || null)

  const value = data?.find(item => item.id === roundId)

  const options = useMemo<RadioSelectOption<OptionType>[]>(() => {
    const list = (data || []).reduce<{
      active: InterviewRoundInterface[]
      archived: InterviewRoundInterface[]
    }>(
      (acc, item) => {
        if (item.archived_reason) {
          acc.archived.push(item)
        } else {
          acc.active.push(item)
        }

        return acc
      },
      { active: [], archived: [] },
    )

    return [...list.active, { id: 'new', value: { id: 'new' } }, ...list.archived].map(
      item => ({
        id: item.id,
        value: item,
      }),
    )
  }, [data])

  return (
    <RadioSelectInput<OptionType>
      label="Opportunity"
      value={value}
      labelPath="specialisation.name"
      searchable={false}
      onChange={item => {
        if (item && 'specialisation' in item) {
          onChange(item.id)
        } else {
          navigateTo(
            pathToUrl(ROUTES.FORMS.ADD_INTERVIEW_ROUND.GENERAL, {
              candidateId,
            }),
          )
        }
      }}
      loading={isLoading}
      disabled={isLoading}
      options={options}
      allowCreateNewOption
      referenceUrl={
        value?.specialisation
          ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
              id: value.specialisation.id,
            })
          : undefined
      }
    >
      {option => (
        <SpecialisationHiringStage
          item={option.value as InterviewRoundInterface}
          isMainRound={mainRoundId === option.value.id}
        />
      )}
    </RadioSelectInput>
  )
}
