import React, { useEffect, useRef, useState } from 'react'
import { Box, Color, Flex, Text } from '@revolut/ui-kit'
import { Pencil } from '@revolut/icons'
import { css } from 'styled-components'
import { connect } from 'lape'
import { colorGetter } from '@src/styles/colors'
import { cellWrapperRole } from '@components/Table/Cell'
import TableCellInput, {
  TableCellInputProps,
} from '@components/Inputs/TableCellInput/TableCellInput'

export const stateAttr = 'editable_cell_state'

export enum CellState {
  inactive = 'inactive',
  active = 'active',
  hovered = 'hovered',
}

export const WrapperCss = css`
  &[data-${stateAttr}=${CellState.active}],
  &[data-${stateAttr}=${CellState.hovered}] {
    background-color: ${colorGetter('blue-opaque-5')};
    box-shadow: inset 0 0 0 1px ${colorGetter('primary')};
  }
`

export interface EditableCellProps extends TableCellInputProps {
  suffix?: string
  testid?: string
  color?: Color
  inactiveEditableInsert?: () => React.ReactNode | undefined
  hidePencil?: boolean
  onClick?: () => void
  readonly?: boolean
}

const EditableCell = ({
  suffix = '',
  testid = 'editable-cell',
  color,
  inactiveEditableInsert,
  hidePencil,
  onClick,
  ...props
}: EditableCellProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [cellState, setCellState] = useState<CellState>(CellState.inactive)

  useEffect(() => {
    const cellWrapper: HTMLElement | null | undefined = containerRef.current?.closest(
      `[role=${cellWrapperRole}]`,
    )
    if (cellWrapper) {
      cellWrapper.dataset[stateAttr] = cellState
    }
  }, [cellState])

  if (cellState === CellState.active && !props.disabled) {
    return (
      <Box
        data-testid="editable-cell"
        ref={containerRef}
        onClick={e => e.stopPropagation()}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            setCellState(CellState.inactive)
          }
        }}
      >
        <TableCellInput
          {...props}
          onBlur={val => {
            setCellState(CellState.inactive)
            props.onBlur?.(val)
          }}
        />
      </Box>
    )
  }

  const inactiveEditableInserted = inactiveEditableInsert?.()

  return (
    <Flex
      data-testid={testid}
      ref={containerRef}
      alignItems="center"
      onMouseEnter={() => setCellState(CellState.hovered)}
      onMouseLeave={() => setCellState(CellState.inactive)}
      onClick={e => {
        setCellState(CellState.active)
        e.stopPropagation()
        onClick?.()
      }}
    >
      {cellState !== CellState.active && inactiveEditableInserted ? (
        inactiveEditableInserted
      ) : (
        <Text color={color}>
          {props.value}
          {suffix}
        </Text>
      )}

      {/* pencil icon */}
      {props.disabled ? null : (
        <Flex justifyContent="flex-end" width="100%" height="100%" alignItems="center">
          <Pencil
            size={16}
            color={cellState === CellState.hovered ? 'primary' : 'grey-tone-50'}
            visibility={hidePencil ? 'hidden' : 'initial'}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default connect(EditableCell)
