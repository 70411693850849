import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const TeamsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Teams.mov"
      videoPosterUrl="/intros/Intro%20-%20Teams.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.teams.intro.tips.title"
            defaultMessage="Evaluate and Adjust Your Teams and Departments"
          />
        }
        text={
          <FormattedMessage
            id="onboardingV2.teams.intro.tips.text"
            defaultMessage="Explore and assess your established team and department structure; a crucial foundation for unlocking future value through recruitment and performance features. Add some examples"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.teams.intro.tips.assign.title"
                defaultMessage="Assign owners"
              />
            ),
            icon: 'Services',
            text: (
              <FormattedMessage
                id="onboardingV2.teams.intro.tips.assign.text"
                defaultMessage="Every team and department requires an owner—picture this owner as the team's manager. It is imperative to assign owners for both teams and departments, as they will hold authority in overseeing goals, talents, and performance reviews."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.teams.intro.tips.enable.title"
                defaultMessage="Enable departments if you need"
              />
            ),
            icon: 'RepairTool',
            text: (
              <FormattedMessage
                id="onboardingV2.teams.intro.tips.enable.text"
                defaultMessage="Explore the advantage of organizing your teams within departments; this can be beneficial for various reasons."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
