import React, { useState } from 'react'
import {
  Group,
  Widget,
  InputGroup,
  VStack,
  TransitionCollapse,
  Link,
  Button,
  Box,
  Text,
  Token,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API, selectorKeys } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { LifecycleSettingsInterface } from '@src/interfaces/settings'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import SideBar from '@src/components/SideBar/SideBar'
import { StartPageView } from '@src/pages/Onboarding/common'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

export const Lifecycle = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)

  const { values } = useLapeContext<LifecycleSettingsInterface>()

  const disableEdit = !permissions.includes(PermissionTypes.ChangeLifecycleSettings)

  const [welcomePreviewOpen, setWelcomePreviewOpen] = useState(false)

  const { options: allowedEmailDomains } = useFetchOptions<IdAndName<string>>(
    selectorKeys.allowed_email_domains,
  )

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle
            title="General settings"
            subtitle="Settings which apply for all employee lifecycle functionalities"
          />
          <Widget>
            <LapeNewSwitch
              disabled={disableEdit}
              itemTypeProps={{
                title: 'Enable onboarding for external employees',
                description:
                  'If enabled, external employees will go through onboarding just like internal employees',
              }}
              name="enable_external_employees_onboarding"
            />
            <VStack p="s-16" space="s-8">
              <LapeNewInput
                title="HR contact email address"
                label="contact_hr@example.com"
                name="contact_hr_email"
                message="Email to address that can be used by employees to contact the HR team"
                required
                disabled={disableEdit}
              />
              <LapeNewInput
                title="HR contact display name"
                label="Your company HR name"
                name="contact_hr_name"
                message="This will be used as the name of the sender for all the contact emails sent to the HR team"
                required
                disabled={disableEdit}
              />
            </VStack>
          </Widget>

          <NewStepperTitle
            title="Onboarding portal for employees"
            subtitle="Settings for the onboarding panel"
          />
          <Widget>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow gathering diversity details',
                description: (
                  <>
                    Allows the step in which employees are asked to provide their
                    diversity details
                    <br />
                    <Text color={Token.color.warning}>
                      Please consider seeking legal advice on the legal implications of
                      gathering diversity data before enabling the functionality.
                    </Text>
                  </>
                ),
              }}
              name="enable_diversity"
              disabled={disableEdit}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allows gathering IT devices delivery information',
                description:
                  'Allows storing the information about where the IT devices (laptop, keyboard, etc) should be delivered',
              }}
              name="enable_equipment_delivery"
              disabled={disableEdit}
            />
            <HideIfCommercial>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Allows selecting the email address',
                  description:
                    'Allows the employee to select from a list of generated email addresses',
                }}
                name="enable_email_selector"
                disabled={disableEdit}
              />
              <TransitionCollapse in={values.enable_email_selector}>
                <VStack p="s-16" space="s-8">
                  <LapeRadioSelectInput
                    label="Internal email domain"
                    name="internal_email_domain"
                    message={<EmailDomainMessage type="internal" />}
                    disabled={disableEdit}
                    options={allowedEmailDomains}
                    value={
                      allowedEmailDomains.find(
                        item => item.key === values.internal_email_domain,
                      )?.value
                    }
                    onChange={val => {
                      if (val) {
                        values.internal_email_domain = val.id
                      }
                    }}
                  />
                  <LapeRadioSelectInput
                    label="External email domain"
                    name="external_email_domain"
                    message={<EmailDomainMessage type="external" />}
                    disabled={disableEdit}
                    options={allowedEmailDomains}
                    value={
                      allowedEmailDomains.find(
                        item => item.key === values.external_email_domain,
                      )?.value
                    }
                    onChange={val => {
                      if (val) {
                        values.external_email_domain = val.id
                      }
                    }}
                  />
                </VStack>
              </TransitionCollapse>
            </HideIfCommercial>
          </Widget>

          <NewStepperTitle title="Onboarding notifications" />
          <Widget p="s-16">
            <InputGroup>
              <LapeRadioSelectInput
                label="Onboarding account activation email"
                name="activation_email_notification"
                message="This will be the email received by the employee inviting them to start the onboarding on the tool."
                selector={selectorKeys.activation_email_notifications}
                disabled={disableEdit}
              />
              <LapeRadioSelectInput
                label="Onboarding magic link email"
                name="magic_link_email_notification"
                message="The email with the magic link that gets sent when activating the account."
                selector={selectorKeys.magic_link_email_notifications}
                disabled={disableEdit}
              />
            </InputGroup>
          </Widget>

          <NewStepperTitle
            title="Onboarding welcome page"
            subtitle="Settings for the welcome page, this will be the first page the employee views when they land on the platform. It usually contains a presentation of the company and some details about the onboarding process."
          />
          <Widget p="s-16">
            <InputGroup>
              <Button
                onClick={() => setWelcomePreviewOpen(true)}
                variant="secondary"
                size="sm"
              >
                Preview
              </Button>
              <LapeNewInput
                label="Welcome page title"
                name="welcome_page_title"
                message="This will be the title of the welcome page. The first page the employee will see landing on the onboarding page."
                required
                disabled={disableEdit}
              />
              <LapeNewInput
                label="Welcome page video URL"
                name="welcome_page_video_url"
                message="This will be used as the onboarding video for new joiners."
                disabled={disableEdit}
              />
              <LapeHTMLEditor
                placeholder="Welcome page text"
                name="welcome_page_text"
                message="This will be the content displayed on the welcome page"
                required
                height={300}
                readOnly={disableEdit}
              />
            </InputGroup>
          </Widget>

          <HideIfCommercial>
            <NewStepperTitle
              title="Onboarding landing page"
              subtitle="Settings for the landing page, this will be the last page the employee views when they finish all the onboarding steps"
            />
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Allow onboarding landing page',
                  description: 'Allows the onboarding employee to see the landing page',
                }}
                name="enable_onboarding_landing_page"
                disabled={disableEdit}
              />
            </Group>
          </HideIfCommercial>

          <NewStepperTitle
            title="Onboarding HR panel"
            subtitle="Settings for the onboarding backoffice used by HR managers"
          />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow Screening check tab',
                description:
                  'Allows tracking the screening process in the HR onboarding admin panel',
              }}
              name="enable_screening_tab"
              disabled={disableEdit}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow Right to Work check tab',
                description:
                  'Allows tracking the right to work process in the HR onboarding admin panel',
              }}
              name="enable_right_to_work_tab"
              disabled={disableEdit}
            />
            <HideIfCommercial>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Allow tracking SLAs and deadlines',
                  description:
                    'Displays SLAs and deadlines for each step of the onboarding process on the HR onboarding admin panel',
                }}
                name="enable_sla_tracking"
                disabled={disableEdit}
              />
            </HideIfCommercial>
          </Group>

          <NewStepperTitle
            title="Offboarding HR panel"
            subtitle="Settings for the offboarding section used by HR managers to help employees hand over all responsibilities before leaving the company"
          />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow access retention for offboarded employees',
                description:
                  'Allows employees to preserve the access to the platform while they are in gardening leave',
              }}
              name="enable_access_retention"
              disabled={disableEdit}
            />
            <HideIfCommercial>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Allow offboarding tickets task',
                  description:
                    'Displays an offboarding task tracking all the tickets that need to be closed in Jira to mark the offboarding as done',
                }}
                name="enable_offboarding_tickets_task"
                disabled={disableEdit}
              />
            </HideIfCommercial>
          </Group>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.LIFECYCLE_SETTINGS)
          }}
          useValidator
        />
      </PageActions>

      <SideBar
        onClose={() => setWelcomePreviewOpen(false)}
        isOpen={welcomePreviewOpen}
        variant="wide"
      >
        <Box mt="-s-32">
          <StartPageView
            title={values.welcome_page_title}
            videoUrl={values.welcome_page_video_url}
            text={values.welcome_page_text}
            name="{employee_name}"
            joiningDateTime={new Date().toISOString()}
          />
        </Box>
      </SideBar>
    </>
  )
}

interface EmailDomainMessageProps {
  type: 'internal' | 'external'
}

const EmailDomainMessage = ({ type }: EmailDomainMessageProps) => {
  return (
    <>
      This will be the email domain used to generate emails for {type} employees. Email
      domains are configured in{' '}
      <Link href={ROUTES.SETTINGS.EMPLOYEES.GENERAL} target="_blank">
        employee app settings
      </Link>
    </>
  )
}
