import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const PerformanceReviewsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Performance%20Reviews.mov"
      videoPosterUrl="/intros/Intro%20-%20Performance%20Reviews.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.reviews.intro.tips.title"
            defaultMessage="How are employees assessed during the review?"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.goals.title"
                defaultMessage="How goals impact performance?"
              />
            ),
            icon: 'Target',
            text: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.goals.text"
                defaultMessage="The delivery of business goals forms a key part of the performance assessment. Employees will be rated against the overall progress of the goals they own, or on their impact towards the organisation’s goals that they contributed to, if they are not an owner. Higher contributions and progress will result in higher grading for this section."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.skills.title"
                defaultMessage="How the skills are evaluated?"
              />
            ),
            icon: 'Palette',
            text: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.skills.text"
                defaultMessage="Each role within the organisation has associated skills, and a set of expected competencies for each seniority. During the assessment, reviewers will mark whether the employee meets the criteria for each competency level of each skill. Whether the assessed rating is above or below the expectations determines the grading for this section."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.values.text"
                defaultMessage="Demonstrating the company’s cultural values"
              />
            ),
            icon: 'Heart',
            text: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.values.text"
                defaultMessage="Values are at the heart of your company's culture. Reviewers will assess how the employee aligns with these values and behaviours, rating whether they are performing above or below the expectations described within each. The average result for all Values determines the grading for this section."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.grades.title"
                defaultMessage="Turning review feedback into performance grades"
              />
            ),
            icon: 'StarSemi',
            text: (
              <FormattedMessage
                id="onboardingV2.reviews.intro.tips.grades.text"
                defaultMessage="Employees are graded on a 5-point scale (Unsatisfactory through to Exceptional), where the grading for each section of the review forms a recommendation for the employee - 50% Goals, 25% Skills, and 25% Values as our preferred setup. {br}{br}Managers can provide evidence and rationale directly into their assessment for this or any other grade. This ensures an objective, transparent, and comprehensive scoring for everyone, which can be cross-checked and calibrated if required after assessments are submitted. {br}{br}Calibration can be conducted at a company level, or completed by department and function owners for larger organisations."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
