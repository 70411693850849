import {
  ActionButton,
  ButtonSkeleton,
  Details,
  DetailsCellSkeleton,
  HStack,
  StatusPopup,
  Tag,
  Token,
  VStack,
  Widget,
  createChain,
  useStatusPopup,
} from '@revolut/ui-kit'
import {
  updateAdditionalScorecardSections,
  useGetCustomScorecardSections,
} from '@src/api/performanceSettings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import ConfirmationDialog, {
  ConfirmationDialogInterface,
} from '@src/features/Popups/ConfirmationDialog'
import {
  PerformanceScorecardQuestionInterface,
  PerformanceScorecardQuestionType,
  PerformanceScorecardSectionInterface,
  PerformanceSettingsInterface,
} from '@src/interfaces/settings'
import { EditSection } from '@src/pages/Settings/Performance/Scorecards/EditSection'
import React, { useEffect, useState } from 'react'
import ScorecardSectionControls from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/ScorecardSectionControls'

type Callback = () => void

const useConfirmation = () => {
  const emptyFn: Callback = () => {}
  const [open, setOpen] = useState(false)
  const [confirmCb, setConfirmCb] = useState(() => emptyFn)
  const [cancelCb, setCancelCb] = useState(() => emptyFn)
  const [dialogProps, setDialogProps] =
    useState<
      Omit<ConfirmationDialogInterface, 'onConfirm' | 'onReject' | 'onClose' | 'open'>
    >()

  const executeAndClose =
    (cb: Callback): Callback =>
    () => {
      setOpen(false)
      cb()
    }

  const showConfirm = async ({
    onConfirm,
    onReject,
    ...props
  }: Omit<ConfirmationDialogInterface, 'onClose' | 'open'>) => {
    setConfirmCb(() => executeAndClose(onConfirm))
    onReject && setCancelCb(() => executeAndClose(onReject))
    setDialogProps(props)
    setOpen(true)
  }

  const confirmationDialog = (
    <ConfirmationDialog
      open={open}
      onClose={cancelCb}
      onConfirm={confirmCb}
      onReject={cancelCb}
      {...dialogProps}
    />
  )

  return { confirmationDialog, showConfirm }
}

export const ScorecardQuestionsWidget = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const statusPopup = useStatusPopup()
  const [openSectionIdx, setOpenSectionIdx] = useState<number | null>(null)
  const { showConfirm, confirmationDialog } = useConfirmation()
  const { data, isLoading } = useGetCustomScorecardSections()
  const sections = data?.custom_scorecard_sections

  useEffect(() => {
    if (!values.custom_scorecard_sections && !isLoading) {
      values.custom_scorecard_sections = sections
    }
  }, [values.custom_scorecard_sections, sections, isLoading])

  const renderQuestion = (
    question: PerformanceScorecardQuestionInterface,
    idx: number,
  ) => {
    const getQuestionType = (questionType: PerformanceScorecardQuestionType) => {
      switch (questionType?.id) {
        case 'checkbox':
          return 'Multiple choice'
        case 'option':
        case 'dropdown':
          return 'Single choice'
        case 'text':
        case 'textarea':
          return 'Free text'
      }
      return 'unknown'
    }
    return (
      <Details p={0} key={idx}>
        <Details.Title variant="h6" color={Token.color.foreground}>
          {question.title}
        </Details.Title>
        {!!question.question_type && (
          <Details.Content>
            <Tag variant="outlined" color={Token.color.deepGrey}>
              {getQuestionType(question.question_type)}
            </Tag>
          </Details.Content>
        )}
      </Details>
    )
  }
  const renderSection = (section: PerformanceScorecardSectionInterface, idx: number) => {
    const showForChain = createChain(', ')
    const shownForNames = section.show_for?.map(({ name }) => name)

    return (
      <Widget p="s-24" key={idx} aria-label="Custom questions section">
        <VStack space="s-16">
          <Details p={0}>
            <Details.Title
              fontSize="18px"
              fontWeight={600}
              color={Token.color.foreground}
            >
              {section.title}
            </Details.Title>
            <Details.Content>
              <HStack space="s-8">
                <ScorecardSectionControls
                  id={idx}
                  onDelete={() => onDelete(section, idx)}
                  onEdit={() => setOpenSectionIdx(idx)}
                  sections={values.custom_scorecard_sections}
                  isView
                />
              </HStack>
            </Details.Content>
            <Details.Note variant="caption" color={Token.color.greyTone50}>
              Shown for: {showForChain(shownForNames)}
            </Details.Note>
          </Details>
          {section.questions.map(renderQuestion)}
        </VStack>
      </Widget>
    )
  }

  const onSave = async () => {
    await updateAdditionalScorecardSections(values.custom_scorecard_sections)
    setOpenSectionIdx(null)
  }

  const onAdd = () => {
    if (!values.custom_scorecard_sections) {
      values.custom_scorecard_sections = []
    }
    const idx = values.custom_scorecard_sections.length
    values.custom_scorecard_sections.push({
      title: 'New section',
      description: '',
      questions: [],
      show_for: null,
    })
    setOpenSectionIdx(idx)
  }

  const doDelete = async (idx: number) => {
    const updatedSections = values.custom_scorecard_sections?.filter((_, i) => i !== idx)
    statusPopup.show(
      <StatusPopup variant="pending">
        <StatusPopup.Title>Section is being deleted</StatusPopup.Title>
      </StatusPopup>,
    )
    try {
      await updateAdditionalScorecardSections(updatedSections)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Section deleted</StatusPopup.Title>
        </StatusPopup>,
      )
      values.custom_scorecard_sections = updatedSections
    } catch {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to delete section</StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }

  const onDelete = (section: PerformanceScorecardSectionInterface, idx: number) => {
    if (section.id) {
      showConfirm({
        onConfirm: () => doDelete(idx),
        onReject: () => {},
        label: `Delete ${section.title} section?`,
        body: 'This will delete section permanently',
        yesMessage: 'Delete',
        noMessage: 'Cancel',
      })
    } else {
      values.custom_scorecard_sections = values.custom_scorecard_sections?.filter(
        s => s !== section,
      )
    }
  }

  const onEditCanceled = () => {
    if (
      openSectionIdx !== null &&
      values.custom_scorecard_sections &&
      values.custom_scorecard_sections[openSectionIdx] &&
      !values.custom_scorecard_sections[openSectionIdx]?.id
    ) {
      values.custom_scorecard_sections = values.custom_scorecard_sections.filter(
        (_, idx) => idx !== openSectionIdx,
      )
    }

    setOpenSectionIdx(null)
  }

  if (isLoading) {
    return (
      <VStack space="s-16">
        <DetailsCellSkeleton height={220} />
        <ButtonSkeleton width={190} />
      </VStack>
    )
  }

  return (
    <VStack space="s-16">
      {values.custom_scorecard_sections?.map(renderSection)}
      <ActionButton useIcon="Plus" onClick={onAdd}>
        Add new section
      </ActionButton>
      <EditSection
        renderInPopup
        idx={openSectionIdx}
        onClose={onEditCanceled}
        onSave={onSave}
      />
      {confirmationDialog}
    </VStack>
  )
}
