import {
  ActionButton,
  Box,
  Flex,
  VStack,
  Widget,
  Text,
  IconButton,
  Token,
  InputGroup,
  Button,
  Avatar,
  Badge,
  Dropdown,
  useDropdown,
  Header,
  Popup,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { cultureValuesRequestsNew, deleteCultureValues } from '@src/api/cultureValues'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { PageActions } from '@src/components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CultureValueInterface, CultureValueType } from '@src/interfaces/cultureValues'
import { CellWithItem } from '@src/pages/Forms/GoalForm/common/CellWithItem'
import { pathToUrl } from '@src/utils/router'
import React, { MouseEvent, useRef, useState } from 'react'
import { InnerRoute } from '@src/pages/OnboardingChecklistV2/PerformanceReviews/common/InnerRoute'
import { Delete } from '@revolut/icons'

const CultureValuesFormContent = () => {
  const { values } = useLapeContext<CultureValueInterface>()
  const suggestionsDropdown = useDropdown()
  const inputRef = useRef<HTMLInputElement>(null)
  const [deletePending, setDeletePending] = useState(false)
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)
  const statusPopup = useStatusPopup()

  const suggestions = [
    {
      title: 'Never Settle',
      description:
        'We constantly push, rethink, and rework to get 10x further from where we are now. We aren’t afraid to be ambitious — and we’re always looking for the next big thing',
      behaviours: [
        {
          name: 'Set high goals',
          description:
            '✩ Developing - No winning attitude: does not thrive for better. Close-minded: unreasonably uncomfortable with new approaches.\n\n✓ Performing - Always looks to improve things. Sets ambitious goals. Is not afraid of challenges in order to achieve goals. Comfortable with risk taking. Does not tolerate mediocrity. \n\n★ Exceeding - Demonstrates a unique ability to achieve seemingly impossible goals.',
        },
        {
          name: 'Have a can-do attitude',
          description:
            '✩ Developing - Needs encouragement to take initiative.\n\n✓ Performing - Willingly embraces challenges, even those beyond their usual scope of work.\n\n★ Exceeding - Consistently goes above and beyond. Inspires others to achieve the impossible.',
        },
      ],
    },
    {
      title: 'Dream Team',
      description:
        'We believe the key to winning is building diverse, lean teams of brilliant go-getters who break down barriers',
      behaviours: [
        {
          name: 'Be collaborative',
          description:
            "✩ Developing - Prefers working in silos or plays politics.\n\n✓ Performing - Goes the extra mile for the company's success even outside their scope of work.\n\n★ Exceeding - Excels in enabling others for collective success. Consistently empowers those around them.",
        },
        {
          name: 'Never compromise on talent',
          description:
            '✩ Developing - Has a low talent bar. Does not address underperformance effectively.\n\n✓ Performing - Consistently raises talent bar. Recognises high performance and proactively acts on underperformance.\n\n★ Exceeding - Exceptional ability to spot, coach, and grow talent for the organisation.',
        },
        {
          name: 'Be honest, direct and respectful',
          description:
            '✩ Developing - Close-minded and defensive. Shies away from receiving and giving harsh feedback.\n\n✓ Performing - Consistently provides feedback that is necessary, clear, direct, and professional.\n\n★ Exceeding - Builds a culture of excellence. Shares unpopular points in a motivating manner.',
        },
      ],
    },
    {
      title: 'Think Deeper',
      description:
        "We believe logic, reason, and common sense prevail over everything else in decision-making. We dive deep until we get to atoms. If we don't know something – we bet, collect the data, and reiterate",
      behaviours: [
        {
          name: 'Be logical and innovative',
          description:
            '✩ Developing - Lack of structure, logic, and reasoning in problem-solving.\n\n✓ Performing - Ability to simplify complex issues and consistently find solutions. Effectively solves for root causes. \n\n★ Exceeding - Consistently superior logic and problem solving. Exceptional at finding the best and most innovative solutions.',
        },
        {
          name: 'Think long term',
          description:
            "✩ Developing - Struggles to think beyond the immediate task and overlooks broader implications.\n\n✓ Performing - Consistently solves for the 'now' while keeping the 'future' in perspective.\n\n★ Exceeding - Provides a clear and compelling strategic vision. Moves fast towards aggressive goals.",
        },
        {
          name: 'Be open minded',
          description:
            '✩ Developing - Resists feedback. Reluctance towards alternative views and challenging their own assumptions.\n\n✓ Performing - Seeks feedback for continual improvement disregarding ego, title or politics.\n\n★ Exceeding - Actively seeks to improve themselves and others. Enables constructive feedback and open communication.',
        },
      ],
    },
    {
      title: 'Get It Done',
      description:
        'We believe that ideas are great, but execution is everything. That’s why respect comes from sweat and stretch',
      behaviours: [
        {
          name: 'Achieve goals. Fast. End-to-end.',
          description:
            '✩ Developing - Moves slowly and rarely puts in the additional effort when required.\n\n✓ Performing - Moves fast and relentlessly solves for blockers to achieve goals.\n\n★ Exceeding - A delivery machine. Always achieves exceptional results.',
        },
        {
          name: 'Act like an owner',
          description:
            '✩ Developing - Lack of accountability for outcomes. Looks for excuses.\n\n✓ Performing - Assumes full responsibility for their tasks and shows accountability. Self-directs effectively.\n\n★ Exceeding - Assumes ownership of all outcomes and drives them to success with exceptional proficiency.',
        },
      ],
    },
    {
      title: 'Deliver WOW',
      description:
        'We believe that everything we do should solve our customers’ needs. To create awe and inspire, we pay attention to every single detail',
      behaviours: [
        {
          name: 'Quality above quantity',
          description:
            '✩ Developing - Misses details and delivers solutions that are merely "good enough."\n\n✓ Performing - Demonstrates a high level of detail and focus. Work has no loose ends. \n\n★ Exceeding - Consistently delivers quality work that exceeds expectations and sets new standards in user satisfaction.',
        },
        {
          name: 'Keep it simple',
          description:
            '✩ Developing - Overcomplicates issues causing stakeholder confusion and friction.\n\n✓ Performing - Consistently simplifies for efficient and scalable solutions removing friction.\n\n★ Exceeding - Optimises for efficiency and simplicity through elegant solutions.',
        },
      ],
    },
  ]

  const handleAddBehaviour = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    values.behaviours = [...(values.behaviours || []), { description: '', name: '' }]
  }

  const showSuccessDelete = () => {
    statusPopup.show(
      <StatusPopup
        onClose={() => {
          statusPopup.hide()
          navigateTo(pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD))
        }}
        variant="success"
      >
        <StatusPopup.Title>Successfully deleted company value</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const showErrorDelete = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>Could not delete company value</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const onDelete = async () => {
    setDeletePending(true)
    try {
      await deleteCultureValues(values.id)
      showSuccessDelete()
    } catch {
      showErrorDelete()
    } finally {
      setDeletePopupOpen(false)
      setDeletePending(false)
    }
  }

  const handleDeleteBehaviour = (id: number) => {
    values.behaviours = values.behaviours?.filter((_, i) => i !== id)
  }

  const backUrl = pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD)

  return (
    <InnerRoute
      title={`${values.id ? 'Edit' : 'Add'} company values`}
      description="Define the value details"
    >
      <VStack space="s-16" mb="s-16">
        {values.id && (
          <>
            <Button
              variant="negative"
              size="sm"
              useIcon={Delete}
              pending={deletePending}
              onClick={() => setDeletePopupOpen(true)}
            >
              Delete
            </Button>
            <Popup
              open={deletePopupOpen}
              onClose={() => setDeletePopupOpen(false)}
              size="sm"
            >
              <Header variant="item">
                <Header.CloseButton aria-label="Close" />
                <Text variant="h5">Are you sure you want to delete this value?</Text>
                <Header.Description>This action can not be undone</Header.Description>
              </Header>

              <Popup.Actions horizontal>
                <Button variant="secondary" onClick={() => setDeletePopupOpen(false)}>
                  Cancel
                </Button>
                <Button variant="negative" onClick={onDelete} pending={deletePending}>
                  Yes, delete
                </Button>
              </Popup.Actions>
            </Popup>
          </>
        )}
        <CellWithItem
          avatar={
            <Avatar>
              <Badge bg={Token.color.deepGrey}>1</Badge>
            </Avatar>
          }
          title="General info"
          description="Define the name, description and link to playbook if you have any"
        >
          <LapeNewInput
            {...suggestionsDropdown.getAnchorProps()}
            containerRef={inputRef}
            required
            name="name"
            label="Value Name"
            message="Type here to create your own or select values from our library"
          />
          <Dropdown
            {...suggestionsDropdown.getTargetProps()}
            open={values.name ? false : suggestionsDropdown.getTargetProps().open}
            focusTrap={false}
            anchorRef={inputRef}
            minWidth="auto"
            fitInAnchor
            closeOnEsc
          >
            {suggestions.map(({ title, description, behaviours }) => (
              <Dropdown.Item
                use="button"
                key={title}
                onClick={() => {
                  values.name = title
                  values.description = description
                  values.behaviours = behaviours

                  suggestionsDropdown.toggle()
                }}
              >
                <VStack
                  space="s-4"
                  maxWidth={
                    // Even though dropdown is instructed to fitInAnchor it still sets min-width: fit-content
                    // Means long content is rendered in one line, so I need to set max-width to prevent expanding outside boundaries
                    // - 32 is to compensate dropdown paddings
                    // slack thread in the ui-kit support channel: https://team-revolut.slack.com/archives/CDXMY0GF9/p1712779092236309
                    inputRef.current?.getBoundingClientRect().width
                      ? inputRef.current?.getBoundingClientRect().width - 32
                      : 'auto'
                  }
                >
                  <Text variant="secondary">{title}</Text>
                  <Text variant="small" color={Token.color.greyTone50} maxWidth="100%">
                    {description}
                  </Text>
                </VStack>
              </Dropdown.Item>
            ))}
          </Dropdown>
          <LapeNewTextArea
            rows={5}
            name="description"
            label="Description and definition of done"
            required
          />
        </CellWithItem>
        <CellWithItem
          avatar={
            <Avatar>
              <Badge bg={Token.color.deepGrey}>2</Badge>
            </Avatar>
          }
          title="Value based behaviours"
          description="Actions reflecting a company's principles"
          side={
            <ActionButton useIcon="Plus" onClick={handleAddBehaviour}>
              Add behaviour
            </ActionButton>
          }
        >
          {values.behaviours?.map((_, id) => {
            return (
              <Widget
                key={id}
                style={{ border: `1px solid ${Token.color.greyTone10}` }}
                data-testid="behaviour-form"
              >
                <Box p="s-16">
                  <VStack gap="s-16" mb="s-16">
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text fontWeight={500} variant="primary">
                        Behaviour {id + 1}
                      </Text>
                      <IconButton
                        aria-label="Delete"
                        color={Token.color.greyTone20}
                        onClick={() => handleDeleteBehaviour(id)}
                        useIcon="Delete"
                      />
                    </Flex>
                    <InputGroup>
                      <LapeNewInput
                        name={`behaviours.${id}.name`}
                        label="Name"
                        required
                      />
                      <LapeNewTextArea
                        name={`behaviours.${id}.description`}
                        label="Description"
                        required
                        rows={5}
                      />
                    </InputGroup>
                  </VStack>
                </Box>
              </Widget>
            )
          })}
        </CellWithItem>
      </VStack>
      <PageActions>
        <Button variant="secondary" onClick={() => navigateReplace(backUrl)}>
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          afterSubmitUrl={backUrl}
          useValidator
          disabled={!values.behaviours || values.behaviours.length === 0}
        />
      </PageActions>
    </InnerRoute>
  )
}

export const CultureValuesForm = () => {
  return (
    <Form
      api={cultureValuesRequestsNew}
      initialValues={{
        category: CultureValueType.Culture,
        is_enabled: true,
      }}
    >
      <CultureValuesFormContent />
    </Form>
  )
}
