import React, { useState } from 'react'
import {
  Checkbox,
  CheckboxGroup,
  Group,
  IconButton,
  Item,
  ItemSkeleton,
  MoreBar,
  Skeleton,
  Subheader,
  SubheaderSkeleton,
  Text,
  Token,
  VStack,
  textChain,
  times,
} from '@revolut/ui-kit'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklistV2'
import { createInitialPolicies, useGetTimeOffCategories } from '@src/api/timeOff'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { timeManagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import OnboardingActions from '@src/pages/OnboardingChecklistV2/components/OnboardingActions'
import { StepCompletedWidget } from '@src/pages/OnboardingChecklist/components/StepCompletedWidget'
import { CategoryPopup } from './components/CategoryPopup'

export const SelectPolicies = () => {
  const {
    data: categories,
    isLoading,
    refetch: refetchCategories,
  } = useGetTimeOffCategories()
  const [checkBoxValue, setCheckBoxValue] = useState<number[]>([])
  const [popupState, setPopupState] = useState<string>()

  const { data: checkpoint } = useGetOnboardingCheckpointCategory(
    timeManagementConfig.category,
  )
  const stepIsCompleted = checkpoint?.state.id === 'completed'

  if (isLoading) {
    return (
      <PageBody>
        <SubheaderSkeleton>
          <SubheaderSkeleton.Title />
        </SubheaderSkeleton>
        <Group>
          {times(
            index => (
              <ItemSkeleton
                key={index}
                useIcon={<Skeleton radius={Token.radius.r8} size={24} />}
              >
                <ItemSkeleton.Content>
                  <ItemSkeleton.Title />
                  <ItemSkeleton.Description />
                </ItemSkeleton.Content>
              </ItemSkeleton>
            ),
            3,
          )}
        </Group>
      </PageBody>
    )
  }

  const onChange = (values: number[]) => {
    const selected = categories?.filter(o => values.includes(o.id)).map(o => o.id)
    setCheckBoxValue(selected || [])
  }

  return (
    <>
      <PageBody>
        {stepIsCompleted ? (
          <StepCompletedWidget />
        ) : (
          <>
            <Subheader>
              <Subheader.Title>
                Select which types of policies you want to create
              </Subheader.Title>
            </Subheader>
            <VStack gap="s-16">
              <Text color={Token.color.greyTone50} variant="caption">
                We’ll automatically create some policies for you to customise in the next
                step, for now we just need to know which types of policies you want
              </Text>
              <MoreBar>
                <MoreBar.Action onClick={() => setPopupState('new')} useIcon="Plus">
                  Create new category
                </MoreBar.Action>
              </MoreBar>
              <CheckboxGroup<number> onChange={onChange} value={checkBoxValue}>
                {group => (
                  <VStack gap="s-16">
                    {categories?.map(category => (
                      <Item key={category.id} use="label">
                        <Item.Prefix>
                          <Checkbox
                            aria-labelledby="category-title-clickable"
                            {...group.getInputProps({ value: category.id })}
                          />
                        </Item.Prefix>
                        <Item.Content>
                          <Item.Title id="category-title-clickable">
                            {category.name}
                          </Item.Title>
                          <Item.Description id="category-description-clickable">
                            {category.description}
                          </Item.Description>
                        </Item.Content>
                        <Item.Side>
                          <IconButton
                            color={Token.color.greyTone50}
                            onClick={() => setPopupState(String(category.id))}
                            useIcon="Pencil"
                          />
                        </Item.Side>
                      </Item>
                    ))}
                  </VStack>
                )}
              </CheckboxGroup>
            </VStack>
          </>
        )}
      </PageBody>

      <OnboardingActions
        config={timeManagementConfig}
        currentStep="Select policies"
        isForm={false}
        isLastStep={false}
        updateSteps
        disableNext={!checkBoxValue.length}
        pendingNext={false}
        nextButtonLabel={
          checkBoxValue.length ? textChain('Next', checkBoxValue.length) : 'Next'
        }
        nextRoute={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.CONFIGURATION}
        onBeforeSubmit={
          stepIsCompleted ? undefined : () => createInitialPolicies(checkBoxValue)
        }
      />
      <CategoryPopup
        id={popupState}
        onClose={() => setPopupState(undefined)}
        onSuccess={() => {
          setPopupState(undefined)
          refetchCategories()
        }}
      />
    </>
  )
}
