import React from 'react'

import { EditableGoalsTable } from '@src/features/EditableGoalsTable'
import { OnboardingChecklistHeader } from '../components/OnboardingChecklistHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const ImportGoals = () => {
  return (
    <EditableGoalsTable
      importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.UPLOAD.IMPORT}
      sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.UPLOAD.SESSION}
      anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.UPLOAD.ANY}
      header={
        <OnboardingChecklistHeader
          title="Import goals"
          backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL)}
        />
      }
    />
  )
}
