import { ColumnInterface, CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import React from 'react'
import {
  PerformanceReviewInterface,
  ReviewerRelationToShortTitle,
} from '@src/interfaces/performance'
import { getStatusColor } from '@components/CommonSC/General'

export const performanceReviewEmployeeColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'reviewed_employee.id',
    dataPoint: 'reviewed_employee.full_name',
    sortKey: 'reviewed_employee__full_name',
    filterKey: 'reviewed_employee__id',
    selectorsKey: selectorKeys.employee,
    insert: ({ data }) => (
      <UserWithAvatar
        id={data.reviewed_employee.id}
        name={data.reviewed_employee.full_name}
        avatar={data.reviewed_employee.avatar}
      />
    ),
    title: 'Employee',
  }

export const performanceReviewReviewerColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'reviewer.id',
    dataPoint: 'reviewer.full_name',
    sortKey: 'reviewer__full_name',
    filterKey: 'reviewer__id',
    selectorsKey: selectorKeys.employee,
    insert: ({ data }) =>
      data.reviewer?.full_name ? (
        <UserWithAvatar
          name={data.reviewer.full_name}
          avatar={data.reviewer.avatar}
          id={data.reviewer.id}
        />
      ) : (
        '-'
      ),
    title: 'Reviewer',
  }

export const performanceReviewSeniorityColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.text,
    idPoint: 'eligibility.seniority.id',
    dataPoint: 'eligibility.seniority.name',
    sortKey: 'eligibility__seniority__name',
    filterKey: 'eligibility__seniority__id',
    selectorsKey: selectorKeys.seniority,
    title: 'Seniority',
  }

export const performanceReviewTeamColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.text,
  idPoint: 'eligibility.team.id',
  dataPoint: 'eligibility.team.name',
  sortKey: 'eligibility__team__name',
  filterKey: 'eligibility__team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const performanceReviewRoleColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.text,
  idPoint: 'eligibility.role.id',
  dataPoint: 'eligibility.role.name',
  sortKey: 'eligibility__role__name',
  filterKey: 'eligibility__role__id',
  selectorsKey: selectorKeys.role,
  title: 'Role',
}

export const performanceReviewStatusColumn: ColumnInterface<PerformanceReviewInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status',
    dataPoint: 'status',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.scorecards_status,
    title: 'Status',
    colors: data => getStatusColor(data.status),
  }

export const performanceReviewTypeColumn: ColumnInterface<PerformanceReviewInterface> = {
  type: CellTypes.insert,
  idPoint: 'review_type',
  dataPoint: 'review_type',
  sortKey: 'review_type',
  filterKey: 'review_type',
  selectorsKey: selectorKeys.scorecards_review_type,
  insert: ({ data }) =>
    data.reviewer_relation
      ? `${ReviewerRelationToShortTitle[data.reviewer_relation]} review`
      : '-',
  title: 'Type',
}
