import React, { useState } from 'react'
import {
  BREAKPOINTS,
  Button,
  chain,
  Header,
  Popup,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import {
  completeOnboardingCheckpoint,
  updateOnboardingCheckpointCategoryCurrentStep,
} from '@src/api/onboardingChecklistV2'
import { timeOffPolicyTempAssignmentsAssign } from '@src/api/timeOff'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@src/components/Page/PageActions'
import { timeManagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { pathToUrl } from '@src/utils/router'
import { useNextConfig } from '../../common/hooks'

export const SubmitTempAssignments = ({
  stepIsCompleted,
}: {
  stepIsCompleted: boolean
}) => {
  const nextConfig = useNextConfig(timeManagementConfig)
  const statusPopup = useStatusPopup()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)

  const navigateToPath = (path: string | null) => {
    navigateTo(pathToUrl(path ?? ROUTES.MAIN))
  }

  const showLoadingPopup = () => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Applying policies</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const showSuccessPopup = () => {
    statusPopup.show(
      <StatusPopup variant="success-result" onClose={statusPopup.hide}>
        <StatusPopup.Title>Completed</StatusPopup.Title>
        <StatusPopup.Description>
          {timeManagementConfig.popup.description}
        </StatusPopup.Description>
        <StatusPopup.Actions>
          {nextConfig ? (
            <Button
              elevated
              onClick={() => {
                statusPopup.hide()
                navigateToPath(nextConfig.path)
              }}
            >
              {chain('Next', nextConfig.title)}
            </Button>
          ) : null}
          <Button
            onClick={() => {
              statusPopup.hide()
              navigateTo(ROUTES.MAIN)
            }}
            variant="secondary"
          >
            Go to Dashboard
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const showErrorPopup = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>Error applying policies</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const handleSubmit = async () => {
    setLoadingSubmit(true)
    setOpenConfirmation(false)
    showLoadingPopup()
    try {
      await timeOffPolicyTempAssignmentsAssign()
      await updateOnboardingCheckpointCategoryCurrentStep(
        timeManagementConfig.category,
        'Policy assignments',
      )
      await completeOnboardingCheckpoint(timeManagementConfig.category)
      showSuccessPopup()
    } catch (e) {
      showErrorPopup()
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <PageActions maxWidthMd={BREAKPOINTS.lg}>
      <Button
        onClick={() => {
          if (stepIsCompleted) {
            navigateToPath(nextConfig?.path || null)
          } else {
            setOpenConfirmation(true)
          }
        }}
        pending={loadingSubmit}
      >
        {stepIsCompleted ? 'Next' : 'Confirm & apply'}
      </Button>
      {openConfirmation && (
        <Popup
          open
          onClose={() => {
            setOpenConfirmation(false)
          }}
          variant="bottom-sheet"
        >
          <Header>
            <Header.Title>Confirm your policies</Header.Title>
            <Header.Description>
              Policies in this step will be applied, please be sure they are correct, you
              will not be able to go back to this step afterwards. Policies can still be
              adjusted later on through the time management settings.
            </Header.Description>
          </Header>
          <Popup.Actions horizontal>
            <Button onClick={() => setOpenConfirmation(false)} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => handleSubmit()}>Confirm & apply</Button>
          </Popup.Actions>
        </Popup>
      )}
    </PageActions>
  )
}
