import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Color, IconButton, Input, InputGroup, MoreBar } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import { PageBody } from '@components/Page/PageBody'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectorKeys } from '@src/constants/api'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import {
  CustomFieldInterface,
  OfferCreationInterface,
} from '@src/interfaces/offerCreation'
import {
  useGetRequisition,
  useGetRequisitionsBySpecialisation,
} from '@src/api/requisitions'
import {
  LocationInterface,
  RequisitionBySpecialisationInterface,
} from '@src/interfaces/requisitions'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { SeniorityInterface } from '@src/interfaces/seniority'
import BenchmarkWidget from '@src/pages/Forms/OfferCreation/BenchmarkWidget'
import { useGetOfferTemplateCustomFields } from '@src/api/offerTemplates'
import { OfferPlaceholderSection } from '@src/interfaces/offerTemplates'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import OfferCreationCustomFields from '@src/pages/Forms/OfferCreation/OfferCreationCustomFields'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import { pathToUrl } from '@src/utils/router'
import { navigateReplace } from '@src/actions/RouterActions'
import ReapprovalBanner from '@src/features/ApprovalFlow/ReapprovalBanner'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import pluralize from 'pluralize'
import { useGetSelectors } from '@src/api/selectors'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import Tooltip from '@components/Tooltip/Tooltip'
import { ArrowRepeat, ListBullet, Pencil } from '@revolut/icons'
import {
  getRecommendedJobTitle,
  useGetEligibleSpecialisations,
  useGetEligibleEntities,
} from '@src/api/offerCreation'
import { navigateTo } from '@src/actions/RouterActions'
import OfferTemplatePreview from '@src/pages/Forms/OfferTemplate/OfferTemplatePreview'
import SettingsButtons from '@src/features/SettingsButtons'
import { ContractTypeOption } from '@src/features/Contracts/ContractTypeOption'
import { EmployeeContractType } from '@src/interfaces/employees'
import { useGetOfferSettings } from '@src/api/settings'
import ShowIfCommercial from '@components/ShowIfCommercial/ShowIfCommercial'
import useIsCommercial from '@src/hooks/useIsCommercial'

type Props = {
  candidate?: CandidateInterface
  candidateLoading?: boolean
}

const OfferCreation = ({ candidate, candidateLoading }: Props) => {
  const { values, initialValues, errors, apiErrors } =
    useLapeContext<OfferCreationInterface>()
  const { data: customFields, isLoading: customFieldsLoading } =
    useGetOfferTemplateCustomFields(values.offer_template?.id)

  const [customFieldsBySection, setCustomFieldsBySection] =
    useState<{ [key in OfferPlaceholderSection]: React.ReactNode[] }>()
  const [showPendingChanges, setShowPendingChanges] = useState(true)
  const [editingJobTitle, setEditingJobTitle] = useState(false)
  const [confirmEditingJobTitlePopupOpen, setConfirmEditingJobTitlePopupOpen] =
    useState(false)
  const [recommendedJobTitle, setRecommendedJobTitle] = useState<string>()

  const { data: contractTypeSelector } = useGetSelectors(selectorKeys.contract_types)

  const { data: requisitions, isLoading: requisitionsLoading } =
    useGetRequisitionsBySpecialisation(values.specialisation?.id)
  const { data: eligibleSpecialisations, isLoading: specialisationsLoading } =
    useGetEligibleSpecialisations(candidate?.active_interview_round?.role?.id)
  const { data: entities, isLoading: entitiesLoading } = useGetEligibleEntities(
    values.location?.id,
  )
  const { data: offerSettings } = useGetOfferSettings()
  const isCommercial = useIsCommercial()

  const { data: requisition, isLoading: loadingRequisition } = useGetRequisition(
    values?.requisition?.id,
  )
  const locationOptions = (requisition?.locations ?? []).map(location => ({
    label: location.name,
    value: location,
  }))
  useEffect(() => {
    if (requisition) {
      if (values.line_manager?.id !== requisition?.line_manager.id) {
        values.line_manager = {
          id: requisition.line_manager.id,
          name: requisition.line_manager.full_name,
        }
      }
      if (values.team?.id !== requisition.team.id) {
        values.team = {
          id: requisition.team.id!,
          name: requisition.team.name!,
        }
      }
    }
  }, [requisition])

  const offersEnabled = offerSettings?.enable_offer_templates

  const permissions = useSelector(selectPermissions)
  const canViewOfferTemplates = permissions?.includes(
    PermissionTypes.ViewOfferFormTemplate,
  )

  const fetchRecommendedJobTitle = useCallback(async () => {
    if (!values.seniority || !values.specialisation) {
      return undefined
    }

    const resp = await getRecommendedJobTitle(
      values.seniority.id,
      values.specialisation.id,
    )

    return resp.data.job_title
  }, [values.seniority, values.specialisation])

  const initRecommendedJobTitle = async () => {
    const jobTitle = await fetchRecommendedJobTitle()
    setRecommendedJobTitle(jobTitle)

    if (!values.job_title) {
      values.job_title = jobTitle
    }

    if (jobTitle !== values.job_title) {
      setEditingJobTitle(true)
    }
  }

  useEffect(() => {
    if (candidate && !values.candidate) {
      values.candidate = { ...candidate }
    }

    if (!values.candidate_first_name) {
      values.candidate_first_name = candidate?.full_name?.split(' ')[0]
    }

    if (!values.candidate_last_name) {
      values.candidate_last_name = candidate?.full_name?.split(' ')?.slice(1)?.join(' ')
    }

    if (candidate?.country && !values.candidate_country) {
      values.candidate_country = candidate.country
    }

    if (!values.seniority) {
      values.seniority = candidate?.active_interview_round?.seniority
    }

    // always prefill the field, as this is read only
    values.specialisation = candidate?.active_interview_round?.specialisation

    if (candidate) {
      initRecommendedJobTitle()
    }
  }, [candidate])

  useEffect(() => {
    if (!values.contract_type && contractTypeSelector) {
      const fullTimeOption = contractTypeSelector.find(item => item.id === 'full_time')
      if (fullTimeOption) {
        values.contract_type = { ...fullTimeOption }
      }
    }
  }, [contractTypeSelector])

  const fetchAndSetRecommendedTitle = async () => {
    const jobTitle = await fetchRecommendedJobTitle()
    setRecommendedJobTitle(jobTitle)
    values.job_title = jobTitle
  }

  const onAfterSpecialisationChange = () => {
    values.requisition = undefined
    fetchAndSetRecommendedTitle()
  }

  useEffect(() => {
    if (!values.is_equity_offered) {
      values.equity_amount = 0
      if (!values.equity_type) {
        values.equity_type = {
          id: 'no_bonus',
          name: 'No bonus',
        }
      }
    } else {
      values.equity_type = {
        id: 'shares',
        name: 'Equity bonus',
      }
    }
  }, [values.is_equity_offered])

  const requisitionOptions =
    requisitions?.map(item => {
      const label = `${item.requisition_title} #${item.id} (${
        item.available_headcount > 0
          ? `${item.available_headcount} available ${pluralize(
              'headcount',
              item.available_headcount,
            )}`
          : 'No available headcount'
      })`
      return {
        label,
        disabled: item.available_headcount <= 0,
        value: {
          ...item,
          name: label,
          requisition_title: label,
        },
      }
    }) || []

  const customFieldBuilder = (placeholder: CustomFieldInterface, idx: number) => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const error = apiErrors.custom_fields?.find(
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      item => item?.[placeholder.placeholder!],
    )?.[placeholder.placeholder!]

    const commonProps = {
      key: `${idx}_${placeholder.label}`,
      name: `custom_fields.${idx}.value`,
      message: placeholder.tooltip || error?.value,
      required: placeholder.is_required,
      hasError: !!error?.value,
    }

    switch (placeholder.field_type?.id) {
      case 'text':
        return <LapeNewInput {...commonProps} label={placeholder.label} />

      case 'number':
        return <LapeNewInput {...commonProps} label={placeholder.label} type="number" />

      case 'currency':
        return (
          <LapeRadioSelectInput
            {...commonProps}
            label={placeholder.label}
            selector={selectorKeys.currencies}
          />
        )

      case 'userpicker':
        return (
          <LapeRadioSelectInput
            {...commonProps}
            label={placeholder.label}
            selector={selectorKeys.employee}
          />
        )

      case 'dropdown':
        return (
          <LapeRadioSelectInput
            {...commonProps}
            label={placeholder.label}
            options={placeholder.choice_options?.map(item => ({
              label: item,
              value: {
                id: item,
                name: item,
              },
            }))}
          />
        )

      case 'date':
        return <LapeDatePickerInput {...commonProps} label={placeholder.label} />

      default:
        return null
    }
  }

  const updateCustomFieldsSections = () => {
    setCustomFieldsBySection(
      values.custom_fields?.reduce(
        (result, item, idx) => {
          const key = item.offer_section?.id
          if (key && key in result) {
            result[key].push(customFieldBuilder(item, idx))
          } else {
            result.additional.push(customFieldBuilder(item, idx))
          }

          return result
        },
        {
          team: [],
          candidate_details: [],
          compensation: [],
          relocation: [],
          position_details: [],
          additional: [],
        } as { [key in OfferPlaceholderSection]: React.ReactNode[] },
      ),
    )
  }

  useEffect(() => {
    values.custom_fields =
      customFields?.map(item => ({
        ...item,
        value:
          initialValues.custom_fields?.find(initialItem => initialItem.id === item.id)
            ?.value || null,
      })) || []

    updateCustomFieldsSections()
  }, [customFields])

  useEffect(() => {
    updateCustomFieldsSections()
  }, [errors])

  const renderJobTitleAction = () =>
    editingJobTitle ? (
      <Tooltip placement="top" text="Reset the job title to the recommended">
        <IconButton
          onClick={() => {
            values.job_title = recommendedJobTitle
            setEditingJobTitle(false)
          }}
          useIcon={ArrowRepeat}
          data-testid="btn-reset-job-title"
        />
      </Tooltip>
    ) : (
      <Tooltip placement="top" text="Change the job title">
        <IconButton
          onClick={() => {
            setConfirmEditingJobTitlePopupOpen(true)
          }}
          useIcon={Pencil}
          color={Color.FOREGROUND}
          data-testid="btn-change-job-title"
        />
      </Tooltip>
    )

  return (
    <>
      <PageBody>
        {offersEnabled && (
          <>
            <SettingsButtons pb="s-16">
              <OfferTemplatePreview
                id={values?.offer_template?.id}
                message="Please pick a offer template for preview"
                preview={!!values?.offer_template?.id}
              />
              {canViewOfferTemplates && (
                <MoreBar.Action
                  useIcon={ListBullet}
                  onClick={() =>
                    navigateTo(pathToUrl(ROUTES.FORMS.OFFER_FORMS.OFFER_TEMPLATES))
                  }
                >
                  See all templates
                </MoreBar.Action>
              )}
            </SettingsButtons>
            <InputGroup>
              {values.is_reapproval && (
                <ReapprovalBanner
                  dataType="Offer"
                  actions={
                    <MoreBar>
                      <SwitchButton
                        onClick={() => setShowPendingChanges(!showPendingChanges)}
                        checked={showPendingChanges}
                      >
                        Show pending changes
                      </SwitchButton>
                    </MoreBar>
                  }
                />
              )}
              <LapeRadioSelectInput
                name="offer_template"
                label="Offer template"
                selector={selectorKeys.offer_templates}
              />
            </InputGroup>
          </>
        )}

        <AutoStepper>
          <NewStepperTitle title="Candidate details" />
          <InputGroup>
            <Input
              label="Candidate full name"
              value={candidate?.full_name || ''}
              required
              disabled
            />
            <LapeNewInput
              name="candidate_first_name"
              label="First name"
              required
              disabled={candidateLoading}
              pending={candidateLoading}
            />
            <LapeNewInput
              name="candidate_middle_name"
              label="Middle name"
              disabled={candidateLoading}
              pending={candidateLoading}
            />
            <LapeNewInput
              name="candidate_last_name"
              label="Last name"
              required
              disabled={candidateLoading}
              pending={candidateLoading}
            />
            <LapeRadioSelectInput
              name="candidate_country"
              label="Location of candidate"
              selector={selectorKeys.countries}
            />
            <OfferCreationCustomFields
              loading={customFieldsLoading}
              fields={customFieldsBySection?.candidate_details}
            />
          </InputGroup>

          <NewStepperTitle title="Position details" />
          <InputGroup>
            <LapeRadioSelectInput<EmployeeContractType>
              name="contract_type"
              label="Contract type"
              selector={selectorKeys.contract_types}
            >
              {option => <ContractTypeOption option={option} />}
            </LapeRadioSelectInput>
            <LapeRadioSelectInput
              name="specialisation"
              label="Specialisation (assessed from requisition)"
              options={
                eligibleSpecialisations?.options?.map(value => ({
                  label: value.name,
                  value,
                })) || []
              }
              selector={selectorKeys.approved_specialisations}
              loading={candidateLoading || specialisationsLoading}
              onAfterChange={onAfterSpecialisationChange}
              disabled={
                candidateLoading ||
                specialisationsLoading ||
                (offersEnabled &&
                  !eligibleSpecialisations?.is_offer_specialisation_editable)
              }
            />
            <LapeRadioSelectInput
              name="seniority"
              label="Seniority"
              selector={selectorKeys.seniority}
              loading={candidateLoading}
              message={
                offersEnabled
                  ? 'Seniority prefilled with the value from hiring panel interview'
                  : undefined
              }
              onAfterChange={fetchAndSetRecommendedTitle}
            />
            <LapeRadioSelectInput<RequisitionBySpecialisationInterface>
              name="requisition"
              label="Requisition"
              options={requisitionOptions}
              labelPath="requisition_title"
              message="Requisitions created for the above specialisation shown in the list"
              disabled={requisitionsLoading}
              loading={requisitionsLoading}
              referenceUrl={
                values.requisition?.id
                  ? pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {
                      id: values.requisition.id,
                    })
                  : undefined
              }
            />
            <LapeNewInput
              name="job_title"
              label="Job title"
              required
              disabled={offersEnabled && !editingJobTitle}
              renderAction={offersEnabled ? renderJobTitleAction : undefined}
            />
            <OfferCreationCustomFields
              loading={customFieldsLoading}
              fields={customFieldsBySection?.position_details}
            />
          </InputGroup>

          <NewStepperTitle title="Team" />
          <InputGroup>
            <LapeRadioSelectInput
              name="line_manager"
              label="Line manager (filled from requisition)"
              selector={selectorKeys.employee}
              loading={requisitionsLoading || loadingRequisition}
            />
            <LapeRadioSelectInput
              name="team"
              label="Team (filled from requisition)"
              selector={selectorKeys.team}
              disabled
              loading={requisitionsLoading || loadingRequisition}
            />
            <LapeRadioSelectInput
              name="location"
              label="Location where you are hiring"
              options={locationOptions}
              onAfterChange={() => {
                values.entity = undefined
              }}
              loading={loadingRequisition}
            />
            <LapeRadioSelectInput
              name="entity"
              label="Legal entity"
              options={
                entities?.options?.map(value => ({
                  label: value.name,
                  value,
                })) || []
              }
              loading={entitiesLoading}
              message="Entities filled based on selected location. If the list is empty, entities for selected location are not maintained"
            />
            <OfferCreationCustomFields
              loading={customFieldsLoading}
              fields={customFieldsBySection?.team}
            />
          </InputGroup>

          <NewStepperTitle title="Relocation" />
          <InputGroup>
            <LapeNewRadioButtons
              name="is_relocation_required"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              variant="cell"
              label="Relocation required?"
            />

            {values.is_relocation_required && (
              <>
                <LapeNewRadioButtons
                  name="relocation_visa_approval_received"
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  variant="cell"
                  label="Relocation/Visa Approval Received"
                />
                <LapeNewInput
                  name="relocation_visa_approval_jira_ticket_url"
                  label="Relocation/Visa Approval JIRA Ticket Link"
                />
              </>
            )}
            <OfferCreationCustomFields
              loading={customFieldsLoading}
              fields={customFieldsBySection?.relocation}
            />
          </InputGroup>

          <NewStepperTitle title="Compensation" />
          <InputGroup>
            <LapeRadioSelectInput
              name="currency"
              label="Currency"
              selector={selectorKeys.currencies}
            />
            <InputGroup variant="horizontal">
              <LapeNewInput
                name="compensation_amount"
                label="Offer amount"
                required
                type="number"
              />
              <LapeRadioSelectInput
                name="compensation_period"
                label="Period"
                selector={selectorKeys.contract_salary_time_units}
              />
            </InputGroup>
            {offersEnabled && (
              <BenchmarkWidget
                specialisation={values.specialisation as SpecialisationInterface}
                seniority={values.seniority as SeniorityInterface}
                location={values.location as LocationInterface}
              />
            )}
            <LapeRadioSelectInput
              name="compensation_frequency"
              label="Payment frequency"
              selector={selectorKeys.contract_salary_payment_frequencies}
            />
            <LapeNewRadioButtons
              name="is_equity_offered"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              variant="cell"
              label="Equity offered?"
            />
            {values.is_equity_offered && (
              <InputGroup variant="horizontal">
                <LapeNewInput
                  type="number"
                  name="equity_amount"
                  label={`Offered sign-on equity${!isCommercial ? ' in GBP' : ''}`}
                  required
                />
                <ShowIfCommercial>
                  <LapeRadioSelectInput
                    name="equity_currency"
                    label="Equity currency"
                    selector={selectorKeys.currencies}
                  />
                </ShowIfCommercial>
              </InputGroup>
            )}
            <OfferCreationCustomFields
              loading={customFieldsLoading}
              fields={customFieldsBySection?.compensation}
            />
          </InputGroup>

          <NewStepperTitle title="Additional" />
          <InputGroup>
            <LapeDatePickerInput
              name="anticipated_start_date"
              label="Anticipated start date"
              required
            />
            {offersEnabled && (
              <LapeNewTextArea
                name="notes"
                label="Enter your message for the approver here"
                rows={2}
              />
            )}
            <OfferCreationCustomFields
              loading={customFieldsLoading}
              fields={customFieldsBySection?.additional}
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<OfferCreationInterface>
          successText={offersEnabled ? 'Offer saved successfully' : 'Candidate hired'}
          onAfterSubmit={resp => {
            if (offersEnabled) {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.OFFER_CREATION.PREVIEW, {
                  candidateId: resp.candidate?.id,
                  id: resp.id,
                }),
              )

              return
            }

            navigateReplace(
              pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                id: resp.candidate?.id,
              }),
            )
          }}
          useValidator
          hideWhenNoChanges
        />
      </PageActions>
      <ConfirmationDialog
        open={confirmEditingJobTitlePopupOpen}
        onClose={() => setConfirmEditingJobTitlePopupOpen(false)}
        onConfirm={() => {
          setEditingJobTitle(true)
          setConfirmEditingJobTitlePopupOpen(false)
        }}
        onReject={() => setConfirmEditingJobTitlePopupOpen(false)}
        label="Are you sure you want to change the job title?"
        body="Changing the recommended job title will require additional approval in offer from Head of Function"
        yesMessage="Change"
        noMessage="Cancel"
      />
    </>
  )
}

export default connect(OfferCreation)
