import React from 'react'
import { ActionButton, Avatar, Box, IconName, Item, Tile, Token } from '@revolut/ui-kit'

import { OnboardingCheckpointAppCategory } from '@src/interfaces/onboardingChecklistV2'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { useAppOnboardingState } from '../common/hooks'

const peopleAppsIconColor = 'rgb(44, 100, 226)'

const getConfig = (
  category: OnboardingCheckpointAppCategory,
): {
  title: string
  path: string
  image: string
  icon: IconName
  iconColor: string
} | null => {
  if (category === 'employeeRecords') {
    return {
      title: 'Setup Employees',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-employees.png',
      icon: 'People',
      iconColor: peopleAppsIconColor,
    }
  }
  if (category === 'documents') {
    return {
      title: 'Setup Documents',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-documents.png',
      icon: 'DocumentsPair',
      iconColor: peopleAppsIconColor,
    }
  }
  if (category === 'timeManagement') {
    return {
      title: 'Setup Time management',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-time-management.png',
      icon: 'CalendarDate',
      iconColor: peopleAppsIconColor,
    }
  }
  if (category === 'payroll') {
    return {
      title: 'Setup Payroll',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.PAYROLL.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-payroll.png',
      icon: 'Credit',
      iconColor: peopleAppsIconColor,
    }
  }
  if (category === 'jobs') {
    return {
      title: 'Setup Jobs',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-jobs.png',
      icon: 'Megaphone',
      iconColor: Token.color.teal,
    }
  }
  if (category === 'candidates') {
    return {
      title: 'Setup Candidates',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-candidates.png',
      icon: 'Profile',
      iconColor: Token.color.teal,
    }
  }
  if (category === 'interviews') {
    return {
      title: 'Setup Interviews',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.INTERVIEWS.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-interviews.png',
      icon: 'Chat',
      iconColor: Token.color.teal,
    }
  }
  if (category === 'goals') {
    return {
      title: 'Setup Goals',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-goals.png',
      icon: 'Target',
      iconColor: Token.color.deepPurple,
    }
  }
  if (category === 'reviews') {
    return {
      title: 'Setup Reviews',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-reviews.png',
      icon: 'Questionnaire',
      iconColor: Token.color.deepPurple,
    }
  }
  if (category === 'teams') {
    return {
      title: 'Setup Teams',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.TEAMS.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-teams.png',
      icon: 'People',
      iconColor: Token.color.deepGrey,
    }
  }
  if (category === 'roles') {
    return {
      title: 'Setup Roles',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.ROLES.INTRO,
      image: 'https://assets.revolut.com/revoluters/assets/onboard-roles.png',
      icon: 'RepairTool',
      iconColor: Token.color.deepGrey,
    }
  }

  return null
}

interface OnboardingAppTileProps {
  category: OnboardingCheckpointAppCategory
  disabled: boolean
}

export const OnboardingAppTile = ({ category, disabled }: OnboardingAppTileProps) => {
  const categoryConfig = getConfig(category)

  const state = useAppOnboardingState(category)

  if (!categoryConfig || state.onboarded) {
    return null
  }

  return (
    <Box maxWidth={536}>
      <Tile
        variant="voucher"
        image={categoryConfig.image}
        onClick={() => navigateTo(categoryConfig.path)}
        disabled={disabled}
      >
        <Tile.Avatar>
          <Avatar
            useIcon={categoryConfig.icon}
            size={56}
            color={categoryConfig.iconColor}
            bg={Token.color.white}
          />
        </Tile.Avatar>
        <Tile.Title>{categoryConfig.title}</Tile.Title>
        <Tile.Footer>
          <Item>
            <Item.Content>
              <Item.Title>Get set up in minutes and leave the rest to us.</Item.Title>
            </Item.Content>
            <Item.Side>
              <ActionButton use="span">Get started</ActionButton>
            </Item.Side>
          </Item>
        </Tile.Footer>
      </Tile>
    </Box>
  )
}
