import React from 'react'

import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { ApprovalSteps } from '../common/ApprovalSteps'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'

interface ApprovalProcessesFieldsProps {
  disableEdit: boolean
}

export const ApprovalProcessesFields = ({
  disableEdit,
}: ApprovalProcessesFieldsProps) => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()

  return (
    <>
      <NewStepperTitle
        title="Employee KPIs"
        subtitle="Approval settings for Employee KPIs"
      />
      <ApprovalSteps
        title="Enable Employee KPIs approval"
        description="Whenever a new Employee KPI is created or an existing one is changed it asks for approval"
        switchField="enable_employee_kpi_approvals"
        approvalProcess="employee_kpis_approvals"
        entity="employee KPI"
        disabled={disableEdit}
        formRef={values}
      />

      <NewStepperTitle title="Team KPIs" subtitle="Approval settings for Team KPIs" />
      <ApprovalSteps
        title="Enable Team KPIs approval"
        description="Whenever a new Team KPI is created or an existing one is changed it asks for approval"
        switchField="enable_team_kpi_approvals"
        approvalProcess="team_kpis_approvals"
        entity="team KPI"
        disabled={disableEdit}
        formRef={values}
      />

      <NewStepperTitle
        title="Department KPIs"
        subtitle="Approval settings for Department KPIs"
      />
      <ApprovalSteps
        title="Enable Department KPIs approval"
        description="Whenever a new Department KPI is created or an existing one is changed it asks for approval"
        switchField="enable_department_kpi_approvals"
        approvalProcess="department_kpis_approvals"
        entity="department KPI"
        disabled={disableEdit}
        formRef={values}
      />

      <NewStepperTitle
        title="Company KPIs"
        subtitle="Approval settings for Company KPIs"
      />
      <ApprovalSteps
        title="Enable Company KPIs approval"
        description="Whenever a new Company KPI is created or an existing one is changed it asks for approval"
        switchField="enable_company_kpi_approvals"
        approvalProcess="company_kpis_approvals"
        entity="company KPI"
        disabled={disableEdit}
        formRef={values}
      />

      {values.enable_function_role_specialisation_kpis ? (
        <>
          <NewStepperTitle
            title="Function KPIs"
            subtitle="Approval settings for Function KPIs"
          />
          <ApprovalSteps
            title="Enable Function KPIs approval"
            description="Whenever a new Function KPI is created or an existing one is changed it asks for approval "
            switchField="enable_function_kpi_approvals"
            approvalProcess="function_kpis_approvals"
            entity="function KPI"
            disabled={disableEdit}
            formRef={values}
          />

          <NewStepperTitle title="Role KPIs" subtitle="Approval settings for Role KPIs" />
          <ApprovalSteps
            title="Enable Role KPIs approval"
            description="Whenever a new Role KPI is created or an existing one is changed it asks for approval"
            switchField="enable_role_kpi_approvals"
            approvalProcess="role_kpis_approvals"
            entity="role KPI"
            disabled={disableEdit}
            formRef={values}
          />
        </>
      ) : null}
    </>
  )
}
