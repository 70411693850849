import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { candidateSettings, useGlobalSettings } from '@src/api/settings'
import { PageBody } from '@src/components/Page/PageBody'
import { Group } from '@revolut/ui-kit'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'

const ListPage = () => {
  const { settings } = useGlobalSettings()
  const permissions = useSelector(selectPermissions)
  const canViewDataRetentionSettings = permissions.includes(
    PermissionTypes.ViewDataRetentionPeriod,
  )
  const canViewOffers = permissions.includes(PermissionTypes.ViewOfferFormPreferences)
  const canViewHiringProcess = permissions.includes(
    PermissionTypes.ViewHiringProcessPreferences,
  )

  return (
    <PageBody mb="s-64">
      <Group mb="s-24">
        <NewSelectionCard
          icon="Profile"
          title="Candidate settings"
          to={pathToUrl(ROUTES.SETTINGS.CANDIDATES.GENERAL)}
          subtitle={
            <FormattedMessage
              id="settings.candidates.general.description"
              defaultMessage="Basic settings for all candidates"
            />
          }
          use={InternalLink}
        />
        {canViewDataRetentionSettings && (
          <NewSelectionCard
            icon="Database"
            title="Data retention settings"
            to={pathToUrl(ROUTES.SETTINGS.CANDIDATES.DATA_RETENTION_SETTINGS)}
            subtitle={
              <FormattedMessage
                id="settings.candidates.dataRetention.description"
                defaultMessage="Configure Data Retention Policies for Candidate’s locations"
              />
            }
            use={InternalLink}
          />
        )}
        {canViewOffers && (
          <NewSelectionCard
            icon="Profile"
            title="Offer settings"
            to={pathToUrl(ROUTES.SETTINGS.CANDIDATES.OFFERS)}
            subtitle={
              <FormattedMessage
                id="settings.candidates.offers.description"
                defaultMessage="Settings governing the way offers are handled"
              />
            }
            use={InternalLink}
          />
        )}
        {settings.applicants_tracking_enabled && canViewHiringProcess && (
          <>
            <NewSelectionCard
              icon="People"
              title="Recruitment groups"
              to={pathToUrl(ROUTES.SETTINGS.CANDIDATES.RECRUITMENT_GROUPS)}
              use={InternalLink}
            />
            <NewSelectionCard
              icon="Questionnaire"
              title="Screen call scorecards"
              to={pathToUrl(ROUTES.SETTINGS.CANDIDATES.SCREEN_CALL_SCORECARDS)}
              use={InternalLink}
            />
          </>
        )}
      </Group>
    </PageBody>
  )
}

const routes = [
  {
    title: 'Candidates settings',
    path: ROUTES.SETTINGS.CANDIDATES.LIST,
    url: ROUTES.SETTINGS.CANDIDATES.LIST,
    canView: [PermissionTypes.ViewCandidatePreferences],
    component: ListPage,
  },
]

export const List = () => {
  return <SettingsForm routes={routes} api={candidateSettings} />
}
