import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  COLORS,
  Flex,
  Group,
  Text,
  VStack,
  DetailsCell,
  HStack,
  MoreBar,
  Cell,
  Avatar,
  Box,
  Details,
  ExpandableCell,
} from '@revolut/ui-kit'
import { BarChart, Reverted, Chat } from '@revolut/icons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  EmployeeResignationInterface,
  ResignationApprovalInterface,
} from '@src/interfaces/employeeResignation'
import { KeyPersonResponsibilityAssignmentInterface } from '@src/interfaces/keyPerson'
import { cancelResignation } from '@src/api/employeeResignation'
import { getAllKeyPersonResponsibilitiesAssignments } from '@src/api/keyPerson'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageBody } from '@src/components/Page/PageBody'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { SubmitPreview } from '../Components/SubmitPreview'
import { ReasonsPreview } from '../Components/ReasonsPreview'
import { LetterFileWithStatus } from '../Components/LetterFileWithStatus'
import { SubmitPopup } from '../Components/SubmitPopup'
import { RouteParams } from '../types'
import { useResignationPermissions } from '../hooks'
import { NextStepInfo } from './NextStepInfo'
import { CommentsSidebar } from './CommentsSidebar'
import { formatApproval } from '../common'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useGlobalSettings } from '@src/api/settings'
import { getAvatarUrl } from '@src/utils/employees'

type Props = {
  resignation?: EmployeeResignationInterface
  getResignation: () => Promise<void>
  approval?: ResignationApprovalInterface
  getApprovals: () => Promise<void>
}
const ResignationDashboard = ({
  resignation,
  approval,
  getResignation,
  getApprovals,
}: Props) => {
  const { id, employeeId } = useParams<RouteParams>()
  const { values } = useLapeContext<Partial<ResignationApprovalInterface>>()
  const { canView, canApprove, canComment } = useResignationPermissions(resignation)
  const { settings } = useGlobalSettings()
  const keyPersonEnabled = !!settings?.key_person_enabled

  const [isCommentsShown, setIsCommentsShown] = useState<boolean>(false)
  const [keyPersonRoles, setKeyPersonRoles] = useState<
    KeyPersonResponsibilityAssignmentInterface[]
  >([])
  const [isCancelResignationPopupShown, setIsCancelResignationPopupShown] =
    useState<boolean>(false)
  const [isCancelResignationPending, setIsCancelResignationPending] =
    useState<boolean>(false)

  useEffect(() => {
    if (!keyPersonEnabled) {
      return
    }

    const getKeyPersonRoles = async () => {
      const { data: keyPersonData } = await getAllKeyPersonResponsibilitiesAssignments(
        Number(employeeId),
      )({})
      setKeyPersonRoles(keyPersonData.results)
    }
    getKeyPersonRoles()
  }, [resignation, keyPersonEnabled])

  if (!resignation) {
    return <PageLoading />
  }
  return (
    <PageBody>
      {canView && (
        <VStack space="s-16">
          <MoreBar>
            {canApprove && resignation.status.id !== 'cancelled' && (
              <MoreBar.Action
                useIcon={Reverted}
                variant="negative"
                onClick={() => setIsCancelResignationPopupShown(true)}
              >
                Cancel resignation
              </MoreBar.Action>
            )}
            {canComment && (
              <MoreBar.Action
                useIcon={Chat}
                onClick={() => setIsCommentsShown(!isCommentsShown)}
              >
                Comments
              </MoreBar.Action>
            )}
          </MoreBar>
          <NextStepInfo
            resignation={resignation}
            getResignation={getResignation}
            approval={approval}
            getApprovals={getApprovals}
          />
          <Group>
            <Cell>
              <Flex flex={1} justifyContent="space-between">
                <VStack space="s-4">
                  <Text fontSize="h4" fontWeight={600}>
                    {resignation.employee.display_name}
                  </Text>
                  <Text fontWeight={500}>{resignation.employee.job_title}</Text>
                  <Text color={COLORS['grey-tone-50']}>
                    {/* @ts-ignore FIXME: REVPI-19 support optional team value */}
                    {resignation.employee.team.name}
                  </Text>
                </VStack>
                <Avatar size={56} image={getAvatarUrl(resignation.employee?.avatar)} />
              </Flex>
            </Cell>
            <DetailsCell>
              <DetailsCell.Title>Regrettable employee</DetailsCell.Title>
              <DetailsCell.Content>
                {resignation?.is_regrettable ? 'Yes' : 'No'}
              </DetailsCell.Content>
            </DetailsCell>
            <DetailsCell>
              <DetailsCell.Title>Last performance grade</DetailsCell.Title>
              <DetailsCell.Content>
                {resignation.last_performance_grade ? (
                  <HStack align="center" use="div" space="s-16">
                    <Text>{resignation.last_performance_grade}</Text>
                    <BarChart size={16} color={COLORS['grey-tone-50']} />
                  </HStack>
                ) : (
                  '-'
                )}
              </DetailsCell.Content>
            </DetailsCell>
            {keyPersonEnabled ? (
              <ExpandableCell>
                <ExpandableCell.Title>Key person roles</ExpandableCell.Title>
                <ExpandableCell.Content>
                  {keyPersonRoles.length || '-'}
                </ExpandableCell.Content>
                {!!keyPersonRoles.length && (
                  <ExpandableCell.Note>
                    <VStack>
                      {keyPersonRoles.map(({ id: roleId, key_person_type }, idx) => (
                        <Details key={roleId}>
                          <Details.Title>{`Role ${idx + 1}`}</Details.Title>
                          <Details.Content>{key_person_type.name}</Details.Content>
                        </Details>
                      ))}
                    </VStack>
                  </ExpandableCell.Note>
                )}
              </ExpandableCell>
            ) : null}
          </Group>

          <Text fontWeight={500} color={COLORS['grey-tone-50']} use="div" mt="s-16">
            Resignation form
          </Text>
          <LetterFileWithStatus
            file={resignation.notice_file}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.DASHBOARD_LETTER_APPROVE, {
              id,
              employeeId,
            })}
            resignationId={Number(id)}
            employeeId={employeeId}
            approval={formatApproval(approval)}
          />
          <SubmitPreview resignation={resignation} />

          <Text fontWeight={500} color={COLORS['grey-tone-50']} use="div" mt="s-16">
            Reasons to leave
          </Text>
          <ReasonsPreview resignation={resignation} />
        </VStack>
      )}
      <SubmitPopup
        open={isCancelResignationPopupShown}
        pending={isCancelResignationPending}
        title="Are you sure you want to cancel employee’s resignation?"
        subtitle='Resignation status will be changed to "Cancelled".'
        onClose={() => setIsCancelResignationPopupShown(false)}
        content={
          <Box mt="s-16">
            <LapeNewTextArea
              required
              name="rejection_comment"
              label="Reasons of cancellation"
            />
          </Box>
        }
        onSubmit={async () => {
          setIsCancelResignationPending(true)
          try {
            await cancelResignation(values, {
              employeeId: String(resignation.employee.id),
              resignationId: String(resignation.id),
            })
            await getResignation()
          } finally {
            setIsCancelResignationPending(false)
            setIsCancelResignationPopupShown(false)
          }
        }}
      />

      {canComment && (
        <CommentsSidebar
          employeeId={employeeId}
          resignationId={id}
          isCommentsShown={isCommentsShown}
          setIsCommentsShown={(isShown: boolean) => setIsCommentsShown(isShown)}
        />
      )}
    </PageBody>
  )
}

export default ResignationDashboard
