import React from 'react'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useInitialFilters } from '@src/features/Goals/GoalsTab'
import { GoalsTableFormWidget } from '@src/features/Goals/GoalsTableWidget'

export const GoalsTable = () => {
  // @TODO: implemented exactly as in the main component but worth fixing for instances where this request fails
  // so that we don't end up with infinite loader https://revolut.atlassian.net/browse/REVC-6207
  const { data: currentCycle } = useGetReviewCycleByOffset(0)
  const initialFilters = useInitialFilters(currentCycle)
  return initialFilters && currentCycle ? (
    <GoalsTableFormWidget initialFilters={initialFilters} />
  ) : (
    <PageLoading />
  )
}
