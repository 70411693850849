import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Plus } from '@revolut/icons'
import { FilterButton, MoreBar, TableWidget } from '@revolut/ui-kit'

import { useTable } from '@src/components/Table/hooks'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  payCycleEntityColumn,
  payCycleStartDateColumn,
  payCycleEndDateColumn,
  payCycleSubmissionDateColumn,
  payCyclePayDayColumn,
  payCycleHeadcountColumn,
  payCycleIssuesColumn,
  payCycleTotalGrossColumn,
  payCycleTotalNetColumn,
  payCycleStatusColumn,
  payCycleTotalDeductionsColumn,
  payCycleTotalContributionColumn,
  payCycleCountryColumn,
} from '@src/constants/columns/payCycles'
import Stat from '@src/components/Stat/Stat'
import { payCycleReportsTableRequests } from '@src/api/payroll'
import { PayCycleInterface } from '@src/interfaces/payroll'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { AddNewPayCycleSidebar } from './AddNewPayCycleSidebar'
import { PermissionTypes } from '@src/store/auth/types'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'

const Row: RowInterface<PayCycleInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...payCycleCountryColumn,
      width: 150,
    },
    {
      ...payCycleEntityColumn,
      width: 150,
    },
    {
      ...payCycleHeadcountColumn,
      width: 100,
    },
    {
      ...payCycleStartDateColumn,
      width: 130,
    },
    {
      ...payCycleEndDateColumn,
      width: 130,
    },
    {
      ...payCycleSubmissionDateColumn,
      width: 100,
    },
    {
      ...payCyclePayDayColumn,
      width: 100,
    },
    {
      ...payCycleIssuesColumn,
      width: 140,
    },
    {
      ...payCycleStatusColumn,
      width: 100,
    },
  ],
}

export const PayCyclesTableBase = () => {
  const [selectedCountryFilter, setSelectedCountryFilter] = useState<OptionInterface[]>(
    [],
  )
  const [selectedEntityFilter, setSelectedEntityFilter] = useState<OptionInterface[]>([])
  const [isAddNewSidebarOpen, setIsAddNewSidebarOpen] = useState(false)

  const { data: countries } = useGetSelectors(selectorKeys.countries)
  const { data: entities } = useGetSelectors(selectorKeys.entity)

  const [ownedByMe, setOwnedByMe] = useLocalStorage(
    LOCAL_STORAGE.PAYCYCLE_OWNED_BY_ME,
    false,
  )
  const [withIssues, setWithIssues] = useLocalStorage(
    LOCAL_STORAGE.PAYCYCLE_WITH_ISSUES,
    false,
  )

  const isCommercial = useIsCommercial()
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const canAddPayCycle = permissions.includes(PermissionTypes.AddPayCycle)
  const canViewPayrollPreferences = permissions.includes(
    PermissionTypes.ViewPayrollPreferences,
  )

  const getOwnedByMeFilter = (enabled: boolean): FilterByInterface => ({
    columnName: 'owner__id',
    filters: enabled ? [{ name: user.display_name, id: user.id }] : [],
    nonResettable: true,
  })

  const getWithIssuesFilter = (enabled: boolean): FilterByInterface => ({
    columnName: 'issue_count',
    filters: enabled
      ? [
          { id: '1', name: '1' },
          { id: '', name: '' }, // passing empty values here to simulate range "> 1"
        ]
      : [],
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const initialFilters: FilterByInterface[] = [
      {
        columnName: 'current',
        filters: [{ id: 'true', name: 'true' }],
        nonResettable: true,
      },
    ]
    if (ownedByMe) {
      initialFilters.push(getOwnedByMeFilter(true))
    }
    return initialFilters
  }

  const table = useTable(payCycleReportsTableRequests, getInitialFilters())

  const toggleOwnedByMe = () => {
    setOwnedByMe(prev => {
      const newOwnedByMe = !prev
      table.onFilterChange(getOwnedByMeFilter(newOwnedByMe))
      return newOwnedByMe
    })
  }

  const toggleWithIssues = () => {
    setWithIssues(prev => {
      const newWithIssues = !prev
      table.onFilterChange(getWithIssuesFilter(newWithIssues))
      return newWithIssues
    })
  }

  const selectCountries = (selection: OptionInterface[] | undefined) => {
    setSelectedCountryFilter(selection || [])
    table.onFilterChange({
      filters: selection || [],
      columnName: 'pay_group__country__id',
    })
  }

  const selectEntities = (selection: OptionInterface[] | undefined) => {
    setSelectedEntityFilter(selection || [])
    table.onFilterChange({
      filters: selection || [],
      columnName: 'pay_group__company_entity__id',
    })
  }

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <Stat
            label="Total"
            val={table.loading ? undefined : table.stats?.total_pay_cycles}
          />
          <Stat
            label="Open cycles"
            val={table.loading ? undefined : table.stats?.open_pay_cycles}
          />
        </TableWidget.Info>
        <TableWidget.Filters>
          <FilterButton onClick={toggleOwnedByMe} active={ownedByMe}>
            Owned by me
          </FilterButton>
          <FilterButton onClick={toggleWithIssues} active={withIssues}>
            With issues
          </FilterButton>
          <FilterButtonCheckboxSelect
            label="Country"
            searchable
            width={300}
            options={countries || []}
            value={selectedCountryFilter}
            onChange={selectCountries}
          />
          <FilterButtonCheckboxSelect
            label="Entity"
            searchable
            width={300}
            options={entities || []}
            value={selectedEntityFilter}
            onChange={selectEntities}
          />
        </TableWidget.Filters>
        <TableWidget.Actions>
          <MoreBar>
            {canAddPayCycle && (
              <MoreBar.Action useIcon={Plus} onClick={() => setIsAddNewSidebarOpen(true)}>
                Add new
              </MoreBar.Action>
            )}
            <MoreBar.Action
              to={pathToUrl(ROUTES.APPS.PAYROLL.PAYMENTS_TABLE)}
              use={InternalLink}
              useIcon="Pencil"
            >
              Manage payments
            </MoreBar.Action>
            {canViewPayrollPreferences && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.SETTINGS.PAYROLL.GENERAL)}
                use={InternalLink}
                useIcon="Gear"
              >
                Settings
              </MoreBar.Action>
            )}
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Table>
          <AdjustableTable
            name={TableNames.PayCycles}
            row={Row}
            useWindowScroll
            hiddenCells={{
              [payCycleTotalGrossColumn.idPoint]: isCommercial,
              [payCycleTotalContributionColumn.idPoint]: isCommercial,
              [payCycleTotalDeductionsColumn.idPoint]: isCommercial,
              [payCycleTotalNetColumn.idPoint]: isCommercial,
            }}
            {...table}
          />
        </TableWidget.Table>
      </TableWidget>
      {canAddPayCycle && (
        <AddNewPayCycleSidebar
          isOpen={isAddNewSidebarOpen}
          onClose={() => setIsAddNewSidebarOpen(false)}
          onSuccess={() => {
            setIsAddNewSidebarOpen(false)
            table.refresh()
          }}
        />
      )}
    </>
  )
}

export const PayCyclesTable = () => {
  return (
    <OnboardingAppScreen category="payroll">
      <PayCyclesTableBase />
    </OnboardingAppScreen>
  )
}
