import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import {
  BottomSheet,
  Box,
  Header,
  InputGroup,
  StatusPopup,
  Text,
  Token,
  useStatusPopup,
  Widget,
} from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { LocationInterface } from '@src/interfaces/locations'
import { useGetSelectors } from '@src/api/selectors'
import { API, selectorKeys } from '@src/constants/api'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { InputSkeleton } from '@components/Skeletons/InputsSkeleton'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { newLocationRequests } from '@src/api/locations'
import { IdAndName } from '@src/interfaces'

interface CreateLocationPopupProps {
  open: boolean
  onClose: () => void
  onSuccess?: (res: IdAndName) => void
  showSuccessPopup?: boolean
}
export const CreateLocationPopup = ({
  open,
  onClose,
  onSuccess,
  showSuccessPopup = true,
}: CreateLocationPopupProps) => {
  const statusPopup = useStatusPopup()
  const queryClient = useQueryClient()

  if (!open) {
    return null
  }

  const onAfterSubmit = (res?: LocationInterface) => {
    if (res && onSuccess) {
      onSuccess({ id: res.id, name: res.location_name })
    }
    queryClient.invalidateQueries([
      API.SELECTORS,
      'v1',
      {
        params: {
          name: selectorKeys.location,
        },
      },
    ])
    if (showSuccessPopup) {
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>New location created</StatusPopup.Title>
        </StatusPopup>,
      )
    }
    onClose()
  }

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>Create new location</Header.Title>
      </Header>
      <Form<LocationInterface>
        api={newLocationRequests}
        forceParams={{ id: undefined }}
        disableLocalStorageCaching
      >
        <CreateLocationForm onAfterSubmit={onAfterSubmit} />
      </Form>
    </BottomSheet>
  )
}

type AddNewLocationTooltipProps = {
  onClick: () => void
}
export const AddNewLocationTooltip = ({ onClick }: AddNewLocationTooltipProps) => {
  return (
    <Box color={Token.color.white} width={180} p="s-8">
      To create a new location please{' '}
      <Text style={{ cursor: 'pointer' }} textDecoration="underline" onClick={onClick}>
        click here
      </Text>
    </Box>
  )
}

const CreateLocationForm = ({
  onAfterSubmit,
}: {
  onAfterSubmit: (res?: LocationInterface) => void
}) => {
  const { values } = useLapeContext<LocationInterface>()
  const { data: options } = useGetSelectors(selectorKeys.location_types)

  useEffect(() => {
    const officeType = options?.find?.(option => option.name === 'Office')
    if (officeType && !values.type) {
      values.type = officeType
    }
  }, [options])

  return (
    <>
      <InputGroup>
        <LapeNewInput name="location_name" label="Location name" required />

        {options?.length ? (
          <Widget p="s-16">
            <InputGroup>
              <LapeNewRadioButtons
                name="type"
                useMappingAsValue
                options={options.map(option => ({
                  label: option.name,
                  value: option.id,
                }))}
                variant="regular"
              />
            </InputGroup>
          </Widget>
        ) : (
          <InputSkeleton />
        )}
      </InputGroup>
      <BottomSheet.Actions>
        <NewSaveButtonWithPopup<LocationInterface>
          noPopup
          useValidator
          onAfterSubmit={onAfterSubmit}
        >
          Confirm
        </NewSaveButtonWithPopup>
      </BottomSheet.Actions>
    </>
  )
}
