import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  CompetencyMatrixInterface,
  CompetencyPerformanceWeightType,
  RoleInterface,
  SkillLevels,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { API } from '@src/constants/api'
import Icon from '@components/Icon/Icon'
import { SeniorityInterface } from '@src/interfaces/seniority'
import Loader from '@components/CommonSC/Loader'
import { rolesRequests } from '@src/api/roles'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SenioritiesRange from '@src/pages/Forms/RoleForm/CompetencyMatrix/SenioritiesRange'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { colorGetter } from '@src/styles/colors'
import {
  Box,
  Button,
  Color,
  Flex,
  HStack,
  Widget,
  Text,
  useTooltip,
  Link,
  Tooltip,
} from '@revolut/ui-kit'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import StickyContainerLape from '@src/features/Form/Containers/StickyContainer'
import AutoStepper from '@components/Stepper/AutoStepper'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import { connect, useLape, useLapeEffect } from 'lape'
import { Statuses } from '@src/interfaces'
import { PermissionTypes } from '@src/store/auth/types'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import { formatNumber } from '@src/utils/format'
import WarningMessage from '@components/WarningMessage/WarningMessage'
import { Grid } from '@components/CommonSC/Grid'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import CollapsibleStepperSection from '@components/Stepper/CollapsibleStepperSection'
import SpecialisationSublevels from '@src/pages/Forms/SpecialisationForm/CompetencyMatrix/SpecialisationSublevels'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useGetOrganisationSettings, useGetSkillsSettings } from '@src/api/settings'
import { useNextSpecialisationStepButtonLink } from '@src/pages/Forms/SpecialisationForm/hooks'
import { InfoOutline } from '@revolut/icons'
import { getDefaultCompetencyFor } from '@src/features/CompetencyMatrixTable/utils'
import { getSeniorityList } from '@src/api/seniority'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

const LockIconWrapper = styled.div`
  display: inline-block;
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  margin-left: 5px;
`

const Message = styled(WarningMessage)`
  padding: 6px 16px;
`

const CURRENT_STEP = 'competency_matrix'

const CompetencyMatrix = ({ matrixValidated }: { matrixValidated: boolean }) => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const [seniorities, setSeniorities] = useState<SeniorityInterface[]>([])
  const [role, setRole] = useState<RoleInterface>()
  const [loading, setLoading] = useState(false)
  const [filteredSeniorities, setFilteredSeniorities] = useState<SeniorityInterface[]>([])
  const [defaultCultureSkills, setDefaultCultureSkills] = useState<
    CompetencyMatrixInterface[]
  >(values.culture_competency_matrix || [])
  const state = useLape<{ totalWeight: number }>({
    totalWeight: 100,
  })
  const [renderKey, setRenderKey] = useState(0)
  const nextLink = useNextSpecialisationStepButtonLink(CURRENT_STEP)

  const { data: settings } = useGetOrganisationSettings()
  const { data: skillsSettings } = useGetSkillsSettings()

  const canEdit =
    !values.id ||
    [PermissionTypes.AddSpecialisation, PermissionTypes.ChangeSpecialisation].some(p =>
      values.field_options?.permissions?.includes(p),
    )

  const canEditWeights = Boolean(values.id && values.performance_weights)
  const canEditSublevels = values.field_options?.permissions?.includes(
    PermissionTypes.ChangeSpecialisationSublevels,
  )

  useEffect(() => {
    if (values.status === 'draft') {
      workspaceLocalStorage.removeItem(
        pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {}),
      )
    }
  }, [])

  useEffect(() => {
    fetchRole()
  }, [values.role])

  useLapeEffect(() => {
    state.totalWeight =
      values.performance_weights?.reduce((sum, item) => sum + item.weight, 0) || 0
  })

  const weightsError = canEditWeights && state.totalWeight !== 100

  useEffect(() => {
    if (seniorities.length && values.functional_competency_matrix) {
      // it's needed for old roles those don't have seniority_level
      values.functional_competency_matrix = values.functional_competency_matrix.map(
        item => ({
          ...item,
          competencies: item.competencies?.map(competency => {
            const seniorityLevel = seniorities.find(
              seniority => seniority.id === competency.seniority_id,
            )?.level

            return { ...competency, seniority_level: seniorityLevel }
          }),
        }),
      )
    }
  }, [seniorities])

  const fetchRole = async () => {
    if (values?.role?.id) {
      try {
        setLoading(true)
        const result = await rolesRequests.getItem(values.role.id)
        if (result.data) {
          setDefaultCultureSkills(result.data.culture_competency_matrix)
          setRole(result.data)
          await fetchSeniorities(result.data)
          if (!values.seniority_max) {
            values.seniority_max = result.data.seniority_max
          }

          if (!values.seniority_min) {
            values.seniority_min = result.data.seniority_min
          }
        }
      } finally {
        setLoading(false)
      }
    } else {
      fetchSeniorities()
    }
  }

  if (!values.functional_competency_matrix) {
    values.functional_competency_matrix = []
  }

  const fetchSeniorities = async (fetchedRole?: RoleInterface) => {
    const response = await getSeniorityList({
      sortBy: [{ sortBy: 'level', direction: SORT_DIRECTION.DESC }],
    })

    const senioroties = fetchedRole
      ? response.data.results.filter(seniority => {
          if (
            fetchedRole?.seniority_min?.level &&
            seniority.level < fetchedRole?.seniority_min?.level
          ) {
            return false
          }
          if (
            fetchedRole?.seniority_max?.level &&
            seniority.level > fetchedRole?.seniority_max?.level
          ) {
            return false
          }
          return true
        })
      : response.data.results

    setSeniorities(senioroties)
  }

  const changeSkillsWeights = (data: CompetencyMatrixInterface[]) => {
    data.forEach(skillItem => {
      const weightItem = values.performance_weights!.find(
        item => item.skill_id === skillItem.skill?.id,
      )

      const skillWeight =
        skillItem.weight && (skillItem.weight >= 5 ? skillItem.weight : 5)

      const weight = skillWeight || 0

      if (weightItem) {
        weightItem.weight = weight
      } else {
        values.performance_weights!.push({
          skill_id: skillItem.skill?.id || null,
          weight_type: CompetencyPerformanceWeightType.Skill,
          weight,
        })
      }
    })
  }

  const onChangeSpecialisationMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data) {
      return
    }

    values.functional_competency_matrix = data.map(item => ({
      ...item,
      id: undefined,
      weight: undefined,
    }))

    if (values.performance_weights) {
      const matrix = [...(roleFunctionalMatrix || []), ...data]
      values.performance_weights = values.performance_weights.filter(item =>
        matrix.find(skillItem => {
          if (item.weight_type === CompetencyPerformanceWeightType.Skill) {
            return skillItem.skill?.id === item.skill_id
          }
          return true
        }),
      )

      changeSkillsWeights(data)
    }
  }

  const onChangeRoleMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data || !values.performance_weights) {
      return
    }

    changeSkillsWeights(data)
  }

  const onChangeDeliverablesMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data?.[0]) {
      return
    }

    const row = data[0]
    values.deliverables_competencies = row.competencies

    if (values.performance_weights) {
      const weightItem = values.performance_weights.find(
        item => item.weight_type === CompetencyPerformanceWeightType.Deliverables,
      )

      const weight = row.weight || 0

      if (weightItem) {
        weightItem.weight = weight
      } else {
        values.performance_weights.push({
          skill_id: null,
          weight_type: CompetencyPerformanceWeightType.Deliverables,
          weight,
        })
      }
    }
  }

  if (loading) {
    return (
      <Box pt="s-8">
        <Loader size="medium" />
      </Box>
    )
  }

  const deliverablesMatrix: CompetencyMatrixInterface[] = [
    {
      skill: {
        id: null,
        name: 'Deliverables',
      },
      competencies: values.deliverables_competencies || [],
      weight: values.performance_weights?.find(
        w => w.weight_type === CompetencyPerformanceWeightType.Deliverables,
      )?.weight,
    },
  ]

  const roleFunctionalMatrix =
    role?.functional_competency_matrix &&
    role.functional_competency_matrix.map(item => ({
      ...item,
      weight: values.performance_weights?.find(
        w =>
          w.weight_type === CompetencyPerformanceWeightType.Skill &&
          w.skill_id === item.skill?.id,
      )?.weight,
    }))

  const specialisationFunctionalMatrix = values.functional_competency_matrix?.map(
    item => ({
      ...item,
      weight: values.performance_weights?.find(
        w =>
          w.weight_type === CompetencyPerformanceWeightType.Skill &&
          w.skill_id === item.skill?.id,
      )?.weight,
    }),
  )

  const addSkill = () => {
    const competencies = filteredSeniorities.map(seniority =>
      getDefaultCompetencyFor(seniority),
    )

    values.functional_competency_matrix = [
      ...(values.functional_competency_matrix || []),
      {
        skill: {
          id: null,
          name: null,
        },
        competencies,
      },
    ]
  }

  const onSeniorityRangeIncreased = (seniority: SeniorityInterface) => {
    if (
      values.functional_competency_matrix &&
      values.functional_competency_matrix.length
    ) {
      values.functional_competency_matrix = values.functional_competency_matrix.map(
        ({ skill, competencies }) => {
          if (!competencies) {
            return { skill, competencies }
          }

          const lastCompetency = competencies[competencies?.length - 1]
          const lastSeniority = lastCompetency.seniority_level || 0

          if (seniority.level < lastSeniority) {
            return { skill, competencies }
          }

          const resultCompetencies = competencies.concat(
            seniorities
              .filter(({ level }) => level > lastSeniority && level <= seniority.level)
              .map(s =>
                getDefaultCompetencyFor(
                  s,
                  (lastCompetency.competency_level && {
                    min: lastCompetency.competency_level,
                  }) ||
                    undefined,
                ),
              ),
          )

          return {
            skill,
            competencies: resultCompetencies,
          }
        },
      )
    }

    if (values.deliverables_competencies) {
      const length = values.deliverables_competencies.length
      const lastCompetency = values.deliverables_competencies[length - 1]
      const minLevel = lastCompetency?.competency_level || SkillLevels.Poor
      const minSeniority = lastCompetency?.seniority_level || 0
      const targetSeniorities = seniorities.filter(
        s => s.level > minSeniority && s.level <= seniority.level,
      )
      values.deliverables_competencies = values.deliverables_competencies.concat(
        targetSeniorities.map(s => getDefaultCompetencyFor(s, { min: minLevel })),
      )
    }
  }

  const onSeniorityRangeDescreased = (seniority: SeniorityInterface) => {
    if (
      values.functional_competency_matrix &&
      values.functional_competency_matrix.length
    ) {
      values.functional_competency_matrix = (
        values.functional_competency_matrix || []
      ).map(({ skill, competencies }) => {
        if (!competencies) {
          return { skill, competencies }
        }

        const firstCompetency = competencies[0]
        const firstSeniority = firstCompetency.seniority_level || 0

        if (seniority.level > firstSeniority) {
          return { skill, competencies }
        }

        const resultCompetencies = [
          ...seniorities
            .filter(({ level }) => level < firstSeniority)
            .map(s =>
              getDefaultCompetencyFor(
                s,
                (firstCompetency.competency_level && {
                  max: firstCompetency.competency_level,
                }) ||
                  undefined,
              ),
            ),
          ...competencies,
        ]

        return {
          skill,
          competencies: resultCompetencies,
        }
      })
    }
    if (values.deliverables_competencies) {
      const firstCompetency = values.deliverables_competencies[0]
      const maxLevel = firstCompetency?.competency_level || SkillLevels.Poor
      const minSeniority = firstCompetency?.seniority_level || 0
      const targetSeniorities = seniorities.filter(
        s => s.level < minSeniority && s.level >= seniority.level,
      )
      values.deliverables_competencies = [
        ...targetSeniorities.map(s => getDefaultCompetencyFor(s, { max: maxLevel })),
        ...values.deliverables_competencies,
      ]
    }
  }

  return (
    <AutoStepper pb="100px" width="100%">
      <Flex flexDirection="column" alignItems="flex-start" width="100%">
        <AutoStepperTitle
          title="Allowed seniorities"
          subtitle="Select the seniorities that can be considered for this role"
        />
        <SenioritiesRange
          disabled={!canEdit}
          seniorities={seniorities}
          seniorityMaxValue={values.seniority_max}
          seniorityMinValue={values.seniority_min}
          onChangeMax={val => {
            values.seniority_max = val
            onSeniorityRangeIncreased(val)
          }}
          onChangeMin={val => {
            values.seniority_min = val
            onSeniorityRangeDescreased(val)
          }}
          onFilter={(minIndex, maxIndex) => {
            setFilteredSeniorities(seniorities.slice(minIndex, maxIndex + 1))
          }}
        />
      </Flex>
      <Flex flexDirection="column" alignItems="flex-start" width="100%">
        <AutoStepperTitle title="Competency matrix" />
        <Widget p="s-16">
          <CompetencyMatrixTable
            competencyMatrices={[
              {
                sectionTitle: 'Deliverables',
                children: deliverablesMatrix,
                onChange: onChangeDeliverablesMatrix,
              },
              {
                sectionTitle: (
                  <ParentSkillsTitle link={`/role/competency-matrix/${values.role.id}`} />
                ),
                children: roleFunctionalMatrix,
                onChange: onChangeRoleMatrix,
                disableWeights: false,
                disabled: true,
              },
              {
                sectionTitle: 'Specialised skills',
                children: specialisationFunctionalMatrix,
                matrixToFilterOut: roleFunctionalMatrix,
                onChange: onChangeSpecialisationMatrix,
                staticSkill: false,
                isSkillOptional: true,
              },
            ]}
            minSeniority={values.seniority_min}
            maxSeniority={values.seniority_max}
            withWeightColumn={canEditWeights}
            firstRowTitle=""
            weightsError={weightsError}
            isAdjustable={false}
            filterNonExistent
            readOnly={!canEdit}
          />
          <Flex flexDirection="column" display="flex" alignItems="flex-start">
            <Grid
              flow="column"
              gap={6}
              justifyContent="space-between"
              mt="s-24"
              width="100%"
            >
              {canEdit && (
                <Button variant="secondary" size="sm" onClick={addSkill}>
                  Add skill
                </Button>
              )}
              {weightsError && (
                <Box width={450}>
                  <Message>
                    The sum of all weights should be 100%, currently{' '}
                    {formatNumber(state.totalWeight)}%
                  </Message>
                </Box>
              )}
            </Grid>
          </Flex>
        </Widget>
      </Flex>
      {!skillsSettings?.behaviours_assessment_enabled && (
        <CollapsibleStepperSection
          title={
            <>
              Culture fit skills
              <LockIconWrapper>
                <Icon type="Lock" size="tiny" />
              </LockIconWrapper>
            </>
          }
          subtitle="Culture fit competency matrix is defined at company level. You cannot make any change here."
        >
          <Flex flexDirection="column" alignItems="flex-start">
            {!!role && (
              <Widget p="s-16" variant="plain">
                <CompetencyMatrixTable
                  competencyMatrices={[
                    {
                      sectionTitle: 'Deliverables',
                      children: defaultCultureSkills,
                      disabled: true,
                      hideActionsColumn: true,
                    },
                  ]}
                  firstRowTitle="Deliverables"
                  minSeniority={values.seniority_min}
                  maxSeniority={values.seniority_max}
                  isAdjustable={false}
                  readOnly={!canEdit}
                />
              </Widget>
            )}
          </Flex>
        </CollapsibleStepperSection>
      )}
      {!!settings?.enable_multiple_levels_per_seniority && (
        <CollapsibleStepperSection title="Seniority levels" defaultOpen>
          {values.id && values.status !== Statuses.draft ? (
            <SpecialisationSublevels
              id={values.id}
              canEdit={canEditSublevels}
              key={renderKey}
            />
          ) : (
            <Box pb="s-32" maxWidth={624}>
              <ActionWidget text="Seniority levels can be renamed after the specialisation is created." />
            </Box>
          )}
        </CollapsibleStepperSection>
      )}
      {canEdit && (
        <StickyContainerLape sticky isCenter maxWidth={624}>
          <>
            {values.status === Statuses.draft && (
              <RoleSaveDraftButton
                title="specialisation"
                pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
                pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX}
                isNew
                notification={{
                  path: ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX,
                  updateMsg: 'Specialisation draft successfully updated.',
                  createMsg: 'Specialisation draft successfully created.',
                }}
              />
            )}
            <ContinueRoleButton
              api={API.SPECIALISATIONS}
              step={CURRENT_STEP}
              type="specialisation"
              to={nextLink}
              disabled={weightsError || !matrixValidated}
              onAfterSubmit={() => setRenderKey(Math.random())}
            />
          </>
        </StickyContainerLape>
      )}
    </AutoStepper>
  )
}

export default connect(CompetencyMatrix)

function ParentSkillsTitle({ link }: { link: string }) {
  const tooltip = useTooltip()

  return (
    <section {...tooltip.getAnchorProps()}>
      <HStack space="s-8">
        <Text>Parent skills</Text>
        <InfoOutline size={16} />
      </HStack>
      <Tooltip {...tooltip.getTargetProps()} width={450}>
        <Text>
          These skills and their ratings are defined by the role and are used by all
          specialisations within this role.{' '}
          <Link href={link} target="_blank">
            Click here
          </Link>{' '}
          to go to the role.
        </Text>
      </Tooltip>
    </section>
  )
}
