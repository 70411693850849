import {
  JobPostingInterface,
  JobPostingLocationInterface,
  PublishingStatuses,
} from '@src/interfaces/jobPosting'
import { Color } from '@revolut/ui-kit'
import { RevolutTheme } from '@src/styles/theme'
import { getColor } from '@src/styles/colors'

export const getNormalizedLocations = (locations: JobPostingLocationInterface[]) =>
  locations.reduce<{ office: string[]; remote: string[] }>(
    (acc, item) => {
      switch (item.type) {
        case 'remote': {
          acc.remote.push(item.location_name)
          break
        }

        case 'office': {
          acc.office.push(item.location_name)
          break
        }

        default: {
          acc.remote.push(item.location_name)
        }
      }
      return acc
    },
    {
      office: [],
      remote: [],
    },
  )

export const getJobPostingStatusColor = (
  status: PublishingStatuses,
  theme: RevolutTheme,
) => {
  let color

  switch (status) {
    case PublishingStatuses.closed:
      color = Color.GREY_20
      break

    case PublishingStatuses.fully_published:
    case PublishingStatuses.published_internally:
      color = Color.TEAL
      break

    default:
      color = Color.FOREGROUND
  }

  return getColor(theme, color)
}

export const isPublished = (jobPosting: JobPostingInterface) =>
  jobPosting.status !== PublishingStatuses.unpublished &&
  jobPosting.status !== PublishingStatuses.closed
