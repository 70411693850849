import React from 'react'
import { MoreBar } from '@revolut/ui-kit'

import { InternalLink } from '@components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { API, selectorKeys } from '@src/constants/api'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import { getBulkSessionFieldsMap } from '@src/features/GenericEditableTable/helpers'
import { TableView } from '../common/TableView'
import { bulkFieldsDependencies, mandatoryBulkSessionFields, row } from './common'

type Props = {
  sessionRoute: string
}
export const EditableEmployeesTableSimple = ({ sessionRoute }: Props) => {
  const bulkSessionFieldsMap = getBulkSessionFieldsMap(
    mandatoryBulkSessionFields,
    bulkFieldsDependencies,
  )
  return (
    <TableView
      row={row}
      sessionRoute={sessionRoute}
      bulkSessionFieldsMap={bulkSessionFieldsMap}
      renderEditActionsLeft={() => (
        <MoreBar.Action
          use={InternalLink}
          to={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT}
          useIcon="Upload"
        >
          Import employees
        </MoreBar.Action>
      )}
      renderEditActionsRight={props => (
        <BulkEditExistingEntitiesAction
          sessionRoute={sessionRoute}
          buttonIcon="Switches"
          field="access_level"
          label="access group"
          selector={selectorKeys.groups}
          {...props}
          apiEndpoint={API.EMPLOYEE_UPLOADS}
          fieldsForBulkEdit={bulkSessionFieldsMap.access_groups}
        />
      )}
    />
  )
}
