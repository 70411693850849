import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import React, { ChangeEvent } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { CollapsibleRadioSwitch } from '@src/pages/Settings/common/CollapsibleRadioSwitch'
import { Box } from '@revolut/ui-kit'
import { Managers } from '@src/pages/Settings/Performance/Managers'

export const PipSettings = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const handlePIPSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    values.enable_pip = e.target.checked
    if (!e.target.checked) {
      values.enforce_pip_committee_review = false
    }
  }
  return (
    <CollapsibleRadioSwitch
      itemTypeProps={{
        title: 'Enable PIP',
        description:
          'Allows putting employees who are not performing well on a performance improvement plan',
      }}
      name="enable_pip"
      onChange={handlePIPSwitch}
    >
      <Box m="-s-16">
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Enforce PIP committee review',
            description:
              'The final decisions at the end of PIP is taken by a group of people who will form the PIP committee',
          }}
          name="enforce_pip_committee_review"
        />
        <Managers type="pip" />
      </Box>
    </CollapsibleRadioSwitch>
  )
}
