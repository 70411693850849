import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import {
  Box,
  Banner,
  Avatar,
  VStack,
  TableWidget,
  HStack,
  Token,
  H5,
  FilterButton,
} from '@revolut/ui-kit'

import { useTable } from '@src/components/Table/hooks'
import {
  activateTimeOffPolicy,
  getTimeOffPoliciesEligibleEmployeesTableRequests,
  useTimeOffReviewStats,
} from '@src/api/timeOff'
import { TimeOffPolicyReviewEligibleEmployeeInterface } from '@src/interfaces/timeOff'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import Stat from '@src/components/Stat/Stat'
import { TableNames } from '@src/constants/table'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import {
  timeOffPolicyReviewCurrentBalanceColumn,
  timeOffPolicyReviewEmployeeColumn,
  timeOffPolicyReviewNewBalanceColumn,
  timeOffPolicyReviewStatusColumn,
} from '@src/constants/columns/timeOffPolicyReview'
import { PolicyEditTabCommonProps } from './common'
import { NextTabButton } from './NextTabButton'

const ROW: RowInterface<TimeOffPolicyReviewEligibleEmployeeInterface> = {
  cells: [
    {
      ...timeOffPolicyReviewEmployeeColumn,
      width: 200,
    },
    {
      ...timeOffPolicyReviewCurrentBalanceColumn,
      width: 200,
    },
    {
      ...timeOffPolicyReviewNewBalanceColumn,
      width: 200,
    },
    {
      ...timeOffPolicyReviewStatusColumn,
      width: 200,
    },
  ],
}

export const PolicyEditReviewTab = ({
  basicStepInfo,
  nextTabPath,
  onNavigateToNextTab,
}: PolicyEditTabCommonProps) => {
  const { id } = useParams<{ id: string }>()

  const { data: stats } = useTimeOffReviewStats(id)
  const table = useTable(getTimeOffPoliciesEligibleEmployeesTableRequests(id))

  const filtersByStatus =
    table.filterBy.find(
      filterBy => filterBy.columnName === 'auto_assignments_details__status__id',
    )?.filters || []

  const isFilteredByWarnings =
    filtersByStatus.some(filter => filter.id === 'policy_will_not_apply') &&
    filtersByStatus.some(filter => filter.id === 'policy_will_partially_apply')

  const toggleFilterByWarnings = () => {
    table.onFilterChange(
      isFilteredByWarnings
        ? {
            columnName: 'auto_assignments_details__status__id',
            filters: [],
          }
        : {
            columnName: 'auto_assignments_details__status__id',
            filters: [
              { id: 'policy_will_not_apply', name: 'policy_will_not_apply' },
              { id: 'policy_will_partially_apply', name: 'policy_will_partially_apply' },
            ],
          },
    )
  }
  const isCustomerOnboarding = useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.ANY)

  return (
    <>
      <VStack gap="s-16">
        <Banner>
          <Banner.Avatar>
            <Avatar variant="brand" useIcon="Lightbulb" size={56} />
          </Banner.Avatar>
          <Banner.Content>
            <Banner.Title>
              Let’s review the policy assignment before applying it
            </Banner.Title>
            <Banner.Description>
              Please check any warnings so that employees don’t have wrong policies
              applied
            </Banner.Description>
          </Banner.Content>
        </Banner>
        <Box>
          <TableWidget>
            <TableWidget.Info>
              <VStack gap="s-16">
                <H5>Review and confirm these employees affected by your new policy</H5>
                <HStack gap="s-32">
                  <Stat label="Total" mb="s-12" val={stats?.total} />
                  <Stat
                    label="Warning"
                    mb="s-12"
                    val={stats?.warning}
                    color={Token.color.orange}
                  />
                  <Stat label="OK" mb="s-12" val={stats?.ok} color={Token.color.green} />
                </HStack>
              </VStack>
            </TableWidget.Info>
            <TableWidget.Filters>
              <FilterButton
                active={isFilteredByWarnings}
                onClick={toggleFilterByWarnings}
                pending={table.loading}
              >
                Show warnings
              </FilterButton>
            </TableWidget.Filters>
            <TableWidget.Table>
              <AdjustableTable
                {...table}
                dataType="Employees"
                name={TableNames.TimeOffPoliciesReview}
                noDataMessage="No employees will be affected as auto-assign is disabled."
                row={ROW}
                useWindowScroll
              />
            </TableWidget.Table>
          </TableWidget>
        </Box>
      </VStack>
      <NextTabButton
        disabled={!basicStepInfo?.id}
        onAfterSubmit={async () => {
          if (isCustomerOnboarding) {
            goBack()
          } else {
            // The policy should be activated by the draft id (not the real policy id)
            // Since we don't have data entity for the final step, the id is taken from the basicStepInfo data
            const { data: activatedPolicy } = await activateTimeOffPolicy(
              basicStepInfo!.id,
            )
            navigateReplace(
              pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.PREVIEW, {
                id: activatedPolicy.id,
              }),
            )
          }
        }}
        nextTabPath={nextTabPath}
        onNavigateToNextTab={onNavigateToNextTab}
      />
    </>
  )
}
