import { VStack, Text, Token, Flex, Box, VisuallyHidden } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import {
  EpicOption,
  KpiInterface,
  KpiReviewCycle,
  UpdateTypes,
} from '@src/interfaces/kpis'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  LapeFormInterface,
  useLapeContext,
  useLapeField,
} from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { JiraSearchWidget } from '@src/pages/OnboardingChecklist/Roadmaps/JiraSearch'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { kpisRequestsNew } from '@src/api/kpis'
import InputsSkeleton from '@src/components/Skeletons/InputsSkeleton'
import Form from '@src/features/Form/Form'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { useFormObserver } from '../Widgets/FormObserverProvider'
import { TargetFormFields } from './TargetFormFields'
import toString from 'lodash/toString'
import { GoalTargetCycleSelector } from '../../common/GoalTargetCycleSelector'
import { captureException } from '@sentry/react'
import { selectorKeys } from '@src/constants/api'

export const TargetFieldsByType = ({
  type,
  cycle,
  variant = 'new',
}: {
  type?: UpdateTypes
  cycle?: ReviewCyclesInterface
  variant?: 'new' | 'side-edit'
}) => {
  const form = useLapeContext<KpiInterface>()
  const { values } = form
  const jiraEpicsField = useLapeField('target_epics.0.epics')

  const epics = useMemo(() => {
    return values.target_epics?.length
      ? values.target_epics[0].epics.reduce((acc, epicOption) => {
          if (epicOption.key) {
            return {
              ...acc,
              [epicOption.key]: {
                display_name: epicOption.name,
                epic_name: epicOption.name,
                epic_url: epicOption.url,
                id: epicOption.id,
                issue_type: 'Epic', // only epics could be selected
                key: epicOption.key,
              },
            }
          }
          return acc
        }, {})
      : undefined
  }, [values.target_epics?.length])

  switch (type) {
    case UpdateTypes.roadmap:
      return (
        <>
          <VisuallyHidden>
            <LapeNewInput name="target_epics.0.epics" />
          </VisuallyHidden>
          <JiraSearchWidget
            message={jiraEpicsField?.error && toString(jiraEpicsField.error)}
            aria-invalid={!!jiraEpicsField?.error}
            forceSelected={epics}
            onSelectionChange={selected => {
              if (!values.target_epics) {
                values.target_epics = []
              }

              const target = values.target_epics[0] || {}
              target.epics = Object.values(selected).map(
                epic =>
                  ({
                    key: epic.key,
                    name: epic.epic_name,
                    owner: epic.owner,
                    url: epic.epic_url,
                    review_cycle: cycle as KpiReviewCycle,
                  } as EpicOption),
              )
              values.target_epics[0] = target
            }}
          />
        </>
      )

    case UpdateTypes.sql:
    case UpdateTypes.manual:
    case UpdateTypes.looker:
      return (
        <TargetFormFields
          variant={variant}
          hideLooker
          codeEditorProps={{
            responsive: true,
            width: '100%',
            height: 350,
            wrapResponsive: true,
          }}
        />
      )
    default:
      return null
  }
}

const AddGoalTargetForm = ({
  initialCycle,
}: {
  initialCycle?: ReviewCyclesInterface
}) => {
  const validator = useFormValidator()
  const form = useLapeContext<KpiInterface & { tempId?: number }>()
  const { values } = form
  const formRef = useRef({ form, validator })
  const { registerForm, unregisterForm } = useFormObserver()
  const [formId] = useState(values.tempId || values.id)

  useEffect(() => {
    formRef.current = { form, validator }
  }, [form, validator])

  const hasDeliverables = values.update_type === UpdateTypes.roadmap

  const target =
    (hasDeliverables
      ? values.target_epics && values.target_epics[0]
      : values.targets && values.targets[0]) || undefined

  const targetCycle = (target?.review_cycle ||
    target?.employee_cycle ||
    initialCycle) as ReviewCyclesInterface

  const [cycle, setCycle] = useState<ReviewCyclesInterface | undefined>(targetCycle)

  useEffect(() => {
    if (cycle && target && target.review_cycle?.id !== cycle?.id) {
      target.review_cycle = cycle
    }
  }, [cycle])

  const id = values.id || values.tempId

  useEffect(() => {
    if (typeof id === 'number') {
      registerForm(() => formRef.current, id)
    } else {
      console.error('Failed to register form due to a missed id', id)
      captureException('Failed to register form due to a missed id')
    }

    return () => unregisterForm(values.id)
  }, [formId])

  const updateTypesDictionary = {
    [UpdateTypes.manual]: {
      name: 'Manual',
      description: 'Manually update the current value',
    },
    [UpdateTypes.sql]: {
      name: 'SQL',
      description: 'Use a SQL query to automatically update the current value',
    },
    [UpdateTypes.roadmap]: {
      name: 'Roadmap',
      description: 'Track the total progress of linked roadmap items',
    },
    [UpdateTypes.looker]: {
      name: 'Looker',
      description: 'Use a Look to automatically update the current value',
    },
  }

  const updateTypeOption = values.update_type
    ? { id: values.update_type, ...updateTypesDictionary[values.update_type] }
    : undefined

  useEffect(() => {
    const opposites = {
      review_cycle: 'employee_cycle',
      employee_cycle: 'review_cycle',
    }
    if (cycle && target) {
      const cycleKey = [ReviewCycleCategory.Probation, ReviewCycleCategory.PIP].includes(
        cycle?.category,
      )
        ? 'employee_cycle'
        : 'review_cycle'
      const fieldToClear = opposites[cycleKey] as 'employee_cycle' | 'review_cycle'

      if (target[cycleKey]?.id !== cycle.id) {
        target[cycleKey] = cycle
        target[fieldToClear] = undefined
      }
    }
  }, [cycle, target])

  const detailField = useLapeField('detail')

  const cycleError =
    typeof detailField?.apiError === 'string' &&
    (detailField?.apiError.includes('review cycle') ||
      detailField?.apiError.includes('Only one metric per cycle'))
      ? detailField?.apiError
      : undefined

  return (
    <>
      <VStack space="s-16">
        <VisuallyHidden>
          <LapeNewInput name="detail" />
        </VisuallyHidden>
        <GoalTargetCycleSelector
          onSelect={selection => {
            detailField.cleanErrors()
            setCycle(selection)
          }}
          ownerId={values.owner?.id}
          isEmployee={values.is_employee}
          initialCycle={targetCycle}
          hasError={!!cycleError}
          message={cycleError}
        />
        <LapeNewInput name="name" label="Metric name" required />
        {values.id ? null : (
          <LapeRadioSelectInput<IdAndName<UpdateTypes>>
            label="Metric type"
            value={updateTypeOption}
            onChange={option => {
              values.update_type = option?.id
            }}
            name="update_type"
            selector={selectorKeys.goal_target_types}
            searchable={false}
          >
            {option => (
              <VStack m="-s-2">
                <Text variant="primary">
                  {updateTypesDictionary[option.value.id].name}
                </Text>
                <Text variant="caption" color={Token.color.greyTone50}>
                  {updateTypesDictionary[option.value.id].description}
                </Text>
              </VStack>
            )}
          </LapeRadioSelectInput>
        )}
        <TargetFieldsByType type={values.update_type} cycle={cycle} />
      </VStack>
    </>
  )
}

export type TargetFormInterface = LapeFormInterface<KpiInterface & { tempId?: number }>

type AddGoalTargetFormProps = {
  initialValues: Partial<KpiInterface>
  initialCycle?: ReviewCyclesInterface
}

export default ({ initialValues, initialCycle }: AddGoalTargetFormProps) => {
  // todo: make loadingState dynamic based on initialValues.update_type and epics count (nice to have)
  const loadingState = (
    <Flex flexDirection="column" justifyContent="space-between" height="100%" gap="s-24">
      <VStack space="s-16" mt="s-16">
        <InputsSkeleton />
        <InputsSkeleton />
      </VStack>
    </Flex>
  )
  return (
    <Box>
      <Form<KpiInterface>
        api={kpisRequestsNew}
        forceParams={{ id: initialValues.id ? String(initialValues.id) : undefined }}
        initialValues={initialValues}
        disableLocalStorageCaching
        ignoreLocationState
        loadingState={loadingState}
      >
        <AddGoalTargetForm initialCycle={initialCycle} />
      </Form>
    </Box>
  )
}
