import React, { useState } from 'react'
import {
  Avatar,
  Button,
  Flex,
  IconName,
  Item,
  ItemSkeleton,
  StatusWidget,
  TextButton,
  VStack,
} from '@revolut/ui-kit'

import { ConfigurableField } from '@src/features/BulkDataImportV2/helpers'
import { useGetSectionCustomFields } from '@src/api/customFields'
import {
  CustomFieldsForSectionInterface,
  SectionOptions,
} from '@src/interfaces/customFields'
import { supportedEmployeeCustomFieldsTypes } from '@src/features/EditableEmployeesTable/common/constants'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { CustomFieldTypes } from '@src/constants/customFields'

type CustomFieldInputProps = Omit<Props, 'onCreateNewField'> & {
  customField: CustomFieldsForSectionInterface
}
const CustomFieldItem = ({
  customField,
  onAddField,
  onRemoveField,
  addedFields,
}: CustomFieldInputProps) => {
  const OtherCategory = 'Other'
  const isAdded = addedFields.some(
    addedField =>
      addedField.custom_field_id && addedField.custom_field_id === customField.uid,
  )
  const configurableField = {
    name: customField.name,
    category: customField.sub_section?.name || OtherCategory,
    column_name: customField.field_name,
    custom_field_id: customField.uid,
  }
  const inputTypeToIcon: Record<CustomFieldTypes, { icon?: IconName; label?: string }> = {
    [CustomFieldTypes.Text]: { icon: 'Text' },
    [CustomFieldTypes.Number]: { label: '123' },
    [CustomFieldTypes.Date]: { icon: 'CalendarDate' },
    [CustomFieldTypes.Dropdown]: { icon: 'ViewListMedium' },
    [CustomFieldTypes.MultipleSelector]: { icon: 'BulkSelection' },
  }
  const { icon, label } = inputTypeToIcon[customField.input_type.id] || {}

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon={icon} label={label} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{customField.name}</Item.Title>
        <Item.Description>
          {`${customField.input_type.name} in ${
            customField.sub_section?.name || OtherCategory
          }`}
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <TextButton
          aria-label={`Add custom field ${customField.name}`}
          onClick={() =>
            isAdded ? onRemoveField(configurableField) : onAddField(configurableField)
          }
        >
          {isAdded ? 'Remove' : 'Add'}
        </TextButton>
      </Item.Side>
    </Item>
  )
}

type Props = {
  onAddField: (field: ConfigurableField) => void
  onRemoveField: (field: ConfigurableField) => void
  addedFields: ConfigurableField[]
  onCreateNewField: () => void
}
export const EmployeeCustomFields = ({
  onAddField,
  onRemoveField,
  addedFields,
  onCreateNewField,
}: Props) => {
  const [filters, setFilters] = useState<OptionInterface[]>([])

  const { data: nameFilterOptions = [] } = useGetSelectors(
    selectorKeys.custom_field_names,
  )
  const { data, isLoading } = useGetSectionCustomFields(
    SectionOptions.EmployeeProfile,
    [],
    [],
    [
      {
        columnName: 'id',
        filters: filters.map(f => ({ id: f.id, name: f.name })),
      },
    ],
  )
  const options =
    data?.results.filter(cf =>
      supportedEmployeeCustomFieldsTypes.includes(cf.input_type.id),
    ) || []

  return (
    <VStack space="s-16">
      <Flex justifyContent="space-between">
        <Button size="sm" useIcon="Plus" variant="secondary" onClick={onCreateNewField}>
          Create new
        </Button>
        <FilterButtonCheckboxSelect
          searchable
          width={300}
          options={nameFilterOptions}
          disabled={!nameFilterOptions.length}
          label="Search by name"
          value={filters}
          onChange={newFilters => {
            if (newFilters) {
              setFilters(newFilters)
            }
          }}
        />
      </Flex>
      {(() => {
        if (isLoading) {
          return (
            <>
              <ItemSkeleton />
              <ItemSkeleton />
              <ItemSkeleton />
            </>
          )
        }
        if (!options.length) {
          return (
            <StatusWidget>
              <StatusWidget.Image image="https://assets.revolut.com/assets/3d-images-v2/3D086.png" />
              <StatusWidget.Title>Nothing found</StatusWidget.Title>
              <StatusWidget.Action onClick={() => setFilters([])}>
                Clear filters
              </StatusWidget.Action>
            </StatusWidget>
          )
        }
        return options.map(customField => (
          <CustomFieldItem
            key={customField.id}
            customField={customField}
            onAddField={onAddField}
            onRemoveField={onRemoveField}
            addedFields={addedFields}
          />
        ))
      })()}
    </VStack>
  )
}
