import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { MoreBar } from '@revolut/ui-kit'

import { useTableReturnType } from '@components/Table/hooks'
import {
  DocumentUploadRequestInterface,
  EmployeeDocumentInterface,
} from '@src/interfaces/documents'
import {
  deleteDocumentUpload,
  newDocumentRequests,
  updateDocumentUploadInfo,
} from '@src/api/documents'
import {
  ImportInterface,
  ImportSessionStatsInterface,
} from '@src/interfaces/bulkDataImport'
import { GetRequestData, IdAndName } from '@src/interfaces'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { TableNames } from '@src/constants/table'
import { PermissionTypes } from '@src/store/auth/types'
import { API, selectorKeys } from '@src/constants/api'
import { GenericEditableTable } from '@src/features/GenericEditableTable/GenericEditableTable'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { CreateDocumentCategoryPopup } from '@src/features/GenericEditableTable/CellPopups/CreateDocumentCategory'
import { AttachedDocument } from '@src/features/DocumentSidebar/DocumentSidebar'
import SideBar from '@components/SideBar/SideBar'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { AddDocumentSidebar } from './AddDocumentSidebar'
import { existingItemsRow } from './row'

type CreateNewItemType = 'category'

export const DocumentsList = () => {
  const permissions = useSelector(selectPermissions)
  const showDocumentButtons = permissions.includes(PermissionTypes.UploadDocuments)

  const [isPreviewOpened, setIsPreviewOpened] = useState(false)
  const [document, setDocument] = useState<DocumentUploadRequestInterface | null>(null)

  const [isAddNewSidebarOpen, setIsAddNewSidebarOpen] = useState(false)
  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: CreateNewItemType
    onChangeAction: (entity: IdAndName) => void
  }>()

  const onCreateNew = (
    type: CreateNewItemType,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, onChangeAction })
  }

  const tableRef =
    React.useRef<
      useTableReturnType<
        ImportInterface<EmployeeDocumentInterface>,
        ImportSessionStatsInterface,
        GetRequestData<ImportInterface<EmployeeDocumentInterface>>
      >
    >()

  return (
    <>
      <GenericEditableTable
        apiEndpoint={`${API.EMPLOYEES}/documents`}
        tableName={TableNames.DocumentsOnboarding}
        row={existingItemsRow(onCreateNew)}
        entity="document"
        variant="existingEntities"
        sortByInitial={[{ sortBy: 'issued_date_time', direction: SORT_DIRECTION.ASC }]}
        setTableRef={ref => {
          tableRef.current = ref
        }}
        hiddenColumns={{ action: false }}
        onActionColumnPreview={data => {
          const { data: documentData } = data
          setIsPreviewOpened(true)

          newDocumentRequests
            .get({
              id: String(documentData.recipient_id),
              employeeId: String(documentData.employee.id),
            })
            .then(res => {
              setDocument(res.data)
            })
        }}
        getCustomEditCellAction={async data => {
          let response
          try {
            response = await updateDocumentUploadInfo(
              data.employee.id,
              data.recipient_id,
              {
                // name field key doesn't match between table list API (`document_name`) and item edit API (`name`)
                name: data.document_name,
                category: data.category,
                employee: data.employee,
              } as DocumentUploadRequestInterface,
            )
          } catch (error) {
            // since the name field key doesn't match (see the comment above),
            // we need to map errors data here as well
            if ('name' in error.response?.data || {}) {
              error.response.data.document_name = error.response.data.name
            }
            throw error
          }
          return response
        }}
        existingEntitiesDeleteHandler={rowId => {
          const { data: documentToDelete } =
            tableRef.current?.data.find(doc => doc.id === rowId) || {}

          if (!documentToDelete?.recipient_id || !documentToDelete?.employee) {
            throw new Error("This document upload can't be deleted")
          }
          return deleteDocumentUpload(String(documentToDelete.id))
        }}
        tableActions={tableActionProps => (
          <MoreBar>
            {showDocumentButtons && (
              <>
                <MoreBar.Action
                  useIcon="Plus"
                  onClick={() => setIsAddNewSidebarOpen(!isAddNewSidebarOpen)}
                >
                  Add document
                </MoreBar.Action>
                <MoreBar.Action
                  to={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.FILES)}
                  use={InternalLink}
                  useIcon="ShareIOs"
                >
                  Import documents
                </MoreBar.Action>
              </>
            )}
            <BulkEditExistingEntitiesAction
              sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION}
              buttonIcon="Suitcase"
              field="category"
              selector={selectorKeys.document_categories}
              {...tableActionProps}
              apiEndpoint={API.DOCUMENTS_UPLOADS_BULK}
              fieldsForBulkEdit={['name', 'employee', 'category']}
            />
            <BulkEditExistingEntitiesAction
              sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION}
              buttonIcon="Profile"
              field="employee"
              label="assignee"
              selector={selectorKeys.all_employees_avatar_email}
              selectorField="email"
              {...tableActionProps}
              apiEndpoint={API.DOCUMENTS_UPLOADS_BULK}
              fieldsForBulkEdit={['name', 'employee', 'category']}
            />
          </MoreBar>
        )}
      />
      <AddDocumentSidebar
        isOpen={isAddNewSidebarOpen}
        onClose={() => setIsAddNewSidebarOpen(false)}
        onSubmit={() => {
          tableRef.current?.refresh()
          setIsAddNewSidebarOpen(false)
        }}
      />
      <CreateDocumentCategoryPopup
        open={createNewTypeState?.type === 'category'}
        onSuccess={newCategory => {
          createNewTypeState?.onChangeAction(newCategory)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
      <SideBar
        title="Preview document"
        isOpen={isPreviewOpened}
        onClose={() => {
          setDocument(null)
          setIsPreviewOpened(false)
        }}
        sideProps={{ resizable: true }}
      >
        {document && <AttachedDocument id={document.id} document={document} />}
      </SideBar>
    </>
  )
}
