import React from 'react'

import { EditableGoalsTable } from '@src/features/EditableGoalsTable'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

export const ImportGoals = () => {
  return (
    <EditableGoalsTable
      importRoute={ROUTES.FORMS.IMPORT_DATA.GOALS.IMPORT}
      sessionRoute={ROUTES.FORMS.IMPORT_DATA.GOALS.SESSION}
      anyRoute={ROUTES.FORMS.IMPORT_DATA.GOALS.ANY}
      header={
        <PageHeader
          backUrl={ROUTES.FORMS.COMPANY.GOALS.GENERAL}
          title="Import data"
          subtitle="Goals"
        />
      }
    />
  )
}
