import { TableWidget, Token, Text, useStatusPopup, StatusPopup } from '@revolut/ui-kit'
import { captureException } from '@sentry/react'
import { useGetGoal } from '@src/api/goals'
import { kpisRequests } from '@src/api/kpis'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { UpdateTypes } from '@src/interfaces/kpis'
import React, { useState } from 'react'
import { useConfirmationDialog } from '../../../common/utils'
import {
  goalTargetActionsColumn,
  goalTargetCurrentValueColumn,
  goalTargetInitialValueColumn,
  goalTargetNameColumn,
  goalTargetPerformanceColumn,
  goalTargetReviewCycleColumn,
  goalTargetTargetValueColumn,
  goalTargetUnitColumn,
  goalTargetUpdateTypeColumn,
} from '@src/constants/columns/goals'

export const TargetsList = ({
  onSelected,
  viewMode,
}: {
  onSelected?: (kpi: GoalKpiDetails) => void
  viewMode: boolean
}) => {
  const { values, errors } = useLapeContext<GoalsInterface>()
  const [pendingDeleteId, setPendingDeleteId] = useState<number>()
  const statusPopup = useStatusPopup()
  const { confirmationDialog, confirm } = useConfirmationDialog({
    variant: 'compact',
  })

  const showError = (title: string) =>
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{title}</StatusPopup.Title>
      </StatusPopup>,
    )

  // cascaded kpi could be only the one
  const cascadedTarget = values.update_type?.id === 'cascaded'

  const hasErrors = !!errors.kpis

  // need to fetch goal because goals list endpoint doesn't return review cycles for targets
  const { data: parentGoal } = useGetGoal(values.parent?.id)

  const getKPIsProps = () => {
    return cascadedTarget
      ? {
          data: parentGoal?.kpis || [],
          count: values.parent?.kpis?.length || 0,
          noDataMessage: values.parent?.kpis
            ? undefined
            : 'Select parent goal to see cascaded targets',
          hiddenCells: { actions_column: true },
        }
      : {
          data: values.kpis.map(kpi => {
            if (kpi.update_type === UpdateTypes.roadmap) {
              // a quick hack to render target epics since UI requirements are not yet finalized (https://revolut.atlassian.net/browse/REVC-5794)
              // @ts-expect-error
              kpi.children = kpi.target_epics?.[0]?.epics || [] // fixMe: KPI endpoint doesn't return targets epics but these are must. ask BE to address
            } else {
              // BE sends targets as children from KPI API because we need it in old KPI table. can be refactored after full deprecation
              // @ts-expect-error
              delete kpi.children
            }

            return kpi
          }),
          count: values.kpis.length,
        }
  }

  const deleteTarget = async (data: GoalKpiDetails) => {
    try {
      const confirmed = await confirm({
        body: `Are you sure you want to delete ${data.name} target`,
        yesBtnVariant: 'negative',
        yesMessage: 'Delete',
      })

      if (confirmed.status === 'confirmed') {
        setPendingDeleteId(data.id)
        await kpisRequests.deleteItem(data.id)
        values.kpis = values.kpis.filter(kpi => kpi !== data)
      }
    } catch (err) {
      captureException(err)
      showError('Failed to delete target')
    } finally {
      setPendingDeleteId(undefined)
    }
  }

  const cells = viewMode
    ? [
        {
          ...goalTargetNameColumn,
          width: 300,
        },
        {
          ...goalTargetReviewCycleColumn,
          width: 100,
        },
        {
          ...goalTargetUpdateTypeColumn,
          width: 100,
        },
        {
          ...goalTargetPerformanceColumn,
          width: 100,
        },
        {
          ...goalTargetInitialValueColumn,
          width: 80,
        },
        {
          ...goalTargetCurrentValueColumn,
          width: 80,
        },
        {
          ...goalTargetTargetValueColumn,
          width: 80,
        },
        {
          ...goalTargetUnitColumn,
          width: 100,
        },
        {
          ...goalTargetActionsColumn({ onSelect: onSelected }),
          width: 50,
        },
      ]
    : [
        {
          ...goalTargetNameColumn,
          width: 300,
        },
        {
          ...goalTargetReviewCycleColumn,
          width: 100,
        },
        {
          ...goalTargetUpdateTypeColumn,
          width: 150,
        },
        {
          ...goalTargetInitialValueColumn,
          width: 80,
        },
        {
          ...goalTargetCurrentValueColumn,
          width: 80,
        },
        {
          ...goalTargetTargetValueColumn,
          width: 80,
        },
        {
          ...goalTargetUnitColumn,
          width: 100,
        },
        {
          ...goalTargetActionsColumn({
            onSelect: onSelected,
            onDelete: deleteTarget,
            pendingDeleteId,
          }),
          width: 80,
        },
      ]

  const table = getKPIsProps()
  const onlyRoadmapTargets = table.data.every(
    kpi => kpi.update_type === UpdateTypes.roadmap,
  )
  return table.data.length ? (
    <TableWidget style={{ padding: 0 }}>
      {hasErrors ? (
        <TableWidget.Status>
          <Text variant="caption" color={Token.color.error}>
            Some of the targets are invalid
          </Text>
        </TableWidget.Status>
      ) : null}
      <TableWidget.Table>
        <AdjustableTable<GoalKpiDetails & { actions_column?: never }>
          rowHeight="medium"
          hideCountAndButtonSection
          childrenOpenByDefault
          expandableType="chevron"
          name={TableNames.KPIs}
          dataType="Target"
          row={{ cells, noChildrenRequest: true }}
          hiddenCells={{
            unit: onlyRoadmapTargets,
            initial_value: onlyRoadmapTargets,
            target: onlyRoadmapTargets,
          }}
          {...getKPIsProps()}
        />
      </TableWidget.Table>
      {confirmationDialog}
    </TableWidget>
  ) : null
}
