import React from 'react'
import groupBy from 'lodash/groupBy'
import {
  Item,
  VStack,
  Text,
  Token,
  TextButton,
  Subheader,
  Cell,
  HStack,
  Box,
} from '@revolut/ui-kit'

import SideBar from '@components/SideBar/SideBar'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { BulkUploadConfigurableField } from '@src/interfaces/bulkDataImport'
import { useGetConfigurableBulkUploadFields } from '@src/api/bulkDataImport'
import { ConfigurableField } from '../helpers'
import { getAddFieldButtonProps } from './helpers'
import { EmployeeCustomFields } from './EmployeeCustomFields'

enum EmployeeTabs {
  DefaultFields = 'Default',
  CustomFields = 'Custom',
}

type Props = {
  isOpen: boolean
  onClose: () => void
  entityName: string
  onAddField: (field: ConfigurableField) => void
  onRemoveField: (field: ConfigurableField) => void
  addedFields: ConfigurableField[]
  mandatoryColumns: string[]
  onCreateNewField: () => void
}
export const AddFieldsSidebar = ({
  isOpen,
  onClose,
  entityName,
  onAddField,
  onRemoveField,
  addedFields,
  mandatoryColumns,
  onCreateNewField,
}: Props) => {
  const { data: configurableFieldsData = [] } = useGetConfigurableBulkUploadFields(
    entityName === 'contract'
      ? 'contracts'
      : entityName === 'goals'
      ? 'goals'
      : 'employees',
  )
  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs: [EmployeeTabs.DefaultFields, EmployeeTabs.CustomFields],
    highlightSelected: false,
  })
  const isEmployeesUpload = entityName === 'employee'
  const showStandardFields =
    !isEmployeesUpload || currentTab === EmployeeTabs.DefaultFields

  const groupedFieldsData: Record<string, BulkUploadConfigurableField[]> = groupBy(
    configurableFieldsData,
    field => field.category,
  )
  return (
    <SideBar title="Add fields" isOpen={isOpen} onClose={onClose}>
      {isEmployeesUpload && <Box mb="s-24">{tabBar}</Box>}
      {showStandardFields && (
        <VStack space="s-16">
          {Object.entries(groupedFieldsData).map(([group, fields]) => (
            <React.Fragment key={group}>
              <Subheader variant="nested">
                <Subheader.Title>{group}</Subheader.Title>
              </Subheader>
              <Cell p={0} mt="-s-16">
                <VStack width="100%">
                  {fields.map(field => {
                    const isMandatoryColumn =
                      field.is_required || mandatoryColumns.includes(field.column_name)

                    const mandatoryColumnAction = isMandatoryColumn ? (
                      <Text
                        color={Token.color.greyTone50}
                        aria-label={`${field.name} preset`}
                      >
                        Preset
                      </Text>
                    ) : undefined

                    const removeFieldAction = (
                      <TextButton
                        onClick={() => onRemoveField(field)}
                        aria-label={`Remove ${field.name}`}
                      >
                        Remove
                      </TextButton>
                    )

                    const addFieldAction = (
                      <TextButton
                        onClick={() => onAddField(field)}
                        {...getAddFieldButtonProps(entityName, field, addedFields)}
                        aria-label={`Add ${field.name}`}
                      >
                        Add
                      </TextButton>
                    )

                    const optionalFieldAction = addedFields.some(
                      f => f.name === field.name,
                    )
                      ? removeFieldAction
                      : addFieldAction

                    return (
                      <Item key={field.name}>
                        <Item.Content>
                          <HStack space="s-8" align="center">
                            <Text>{field.name}</Text>
                          </HStack>
                        </Item.Content>
                        <Item.Side>
                          {mandatoryColumnAction || optionalFieldAction}
                        </Item.Side>
                      </Item>
                    )
                  })}
                </VStack>
              </Cell>
            </React.Fragment>
          ))}
        </VStack>
      )}
      {currentTab === EmployeeTabs.CustomFields && (
        <EmployeeCustomFields
          onAddField={onAddField}
          onRemoveField={onRemoveField}
          addedFields={addedFields}
          onCreateNewField={onCreateNewField}
        />
      )}
    </SideBar>
  )
}
