import {
  TabBar,
  VStack,
  Text,
  Flex,
  Ellipsis,
  Box,
  DataPoint,
  Tag,
  HStack,
  TabBarSkeleton,
  ActionButton,
  Spacer,
} from '@revolut/ui-kit'
import { useGoalTargetPerformanceGraph } from '@src/api/goals'
import {
  BaseChartInner,
  timeRangeTabs,
} from '@src/components/Charts/BaseChart/BaseChartInner'
import { getStatusColor } from '@src/components/CommonSC/General'
import { PerformanceTimeRange } from '@src/constants/api'
import { GoalKpiDetails } from '@src/interfaces/goals'
import { DateSwitch } from './DateSwitch'
import React, { useEffect, useState } from 'react'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

export const TargetsCharts = ({
  targets,
  onEdit,
}: {
  targets: GoalKpiDetails[]
  onEdit: (kpi: GoalKpiDetails) => void
}) => {
  const defaultTimeRangeType = PerformanceTimeRange.month
  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle
  const [increment, setIncrement] = useState(0)
  const [timeRangeType, setTimeRangeType] =
    useState<PerformanceTimeRange>(defaultTimeRangeType)

  const [selected, setSelected] = useState<GoalKpiDetails | undefined>(targets[0])

  const { data } = useGoalTargetPerformanceGraph({
    id: selected?.id,
    range: timeRangeType,
    increment,
  })
  const maxTabWidth = 150

  useEffect(() => {
    if (!selected && targets.length) {
      setSelected(targets[0])
    }
  }, [targets])

  const renderHeader = () => {
    if (!targets.length) {
      return <TabBarSkeleton />
    }
    if (!isMultipleGoalsTargetsEnabled) {
      return (
        <HStack align="center" py="s-16">
          <Text use="h5" variant="h5">
            {targets[0].name}
          </Text>
          <Spacer />
          <ActionButton
            useIcon="Pencil"
            onClick={() => {
              onEdit(targets[0])
            }}
          >
            Update progress
          </ActionButton>
        </HStack>
      )
    }
    return (
      <TabBar
        maxWidth="100%"
        variant="segmented fit"
        behaviour="scroll"
        value={String(selected?.id)}
        onChange={id => id && setSelected(targets.find(t => String(t.id) === id))}
      >
        {targets.map(t => (
          <TabBar.Item to={String(t.id)} key={t.id}>
            <Text variant="h6" maxWidth={maxTabWidth}>
              <Ellipsis tooltip="auto">{t.name}</Ellipsis>
            </Text>
          </TabBar.Item>
        ))}
      </TabBar>
    )
  }
  return (
    <VStack space="s-16">
      <Box width="100%">{renderHeader()}</Box>
      <VStack height="100%" mt="s-16">
        {selected ? (
          <VStack space="s-16">
            <Flex justifyContent="space-between">
              <Flex flex={1} justifyContent="start" alignItems="center">
                <DataPoint>
                  <DataPoint.Value>
                    <HStack gap="s-8" align="center">
                      <Text variant="h4">{selected.current_progress}</Text>
                      <Tag variant="outlined" color={getStatusColor(selected.status.id)}>
                        {selected.status.name}
                      </Tag>
                    </HStack>
                  </DataPoint.Value>
                  <DataPoint.Label>Current progress</DataPoint.Label>
                </DataPoint>
              </Flex>

              <Flex flex={1} justifyContent="center" alignItems="center">
                {data && (
                  <DateSwitch
                    startDate={new Date(data?.progress_history[0].progress_datetime)}
                    lastDate={
                      new Date(
                        data?.progress_history[
                          data.progress_history.length - 1
                        ].progress_datetime,
                      )
                    }
                    onSwitchBack={() => setIncrement(increment - 1)}
                    onSwitchForward={() => setIncrement(increment + 1)}
                  />
                )}
              </Flex>

              <Flex alignItems="center" justifyContent="flex-end" flex={1}>
                <TabBar variant="segmented">
                  {timeRangeTabs.map(timeRange => {
                    const isSelected = timeRangeType === timeRange
                    return (
                      <TabBar.Item
                        key={timeRange}
                        aria-selected={isSelected}
                        onClick={() => {
                          setTimeRangeType(timeRange)
                        }}
                      >
                        <Text
                          use="div"
                          fontSize="small"
                          px="s-12"
                          color={isSelected ? 'light-blue' : 'grey-tone-50'}
                          fontWeight={500}
                        >
                          {timeRange[0]?.toUpperCase()}
                        </Text>
                      </TabBar.Item>
                    )
                  })}
                </TabBar>
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              minHeight={0}
              style={{ position: 'relative' }}
              height={{ all: 570, md: 400, sm: 250 }}
            >
              <BaseChartInner
                data={data}
                id={selected.id}
                graphTimeRange={timeRangeType}
              />
            </Flex>
          </VStack>
        ) : null}
      </VStack>
    </VStack>
  )
}
