import React, { useState } from 'react'
import {
  Action,
  ActionButton,
  Avatar,
  Bar,
  HStack,
  Icon,
  Item,
  ItemSkeleton,
  MoreBar,
  TableWidget,
  Token,
  Tooltip as UIKitTooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useTable } from '@components/Table/hooks'
import { jobPostingsRequests } from '@src/api/jobPosting'
import { RowInterface } from '@src/interfaces/data'
import { getSelectCellConfig } from '@components/Table/AdvancedCells/SelectCell/SelectCell'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import {
  jobDescriptionActionColumn,
  jobDescriptionGenericNameColumn,
  jobDescriptionOfficeLocationsColumn,
  jobDescriptionPlatformsColumn,
  jobDescriptionPublishedStatusColumn,
  jobDescriptionRemoteLocationsColumn,
} from '@src/constants/columns/jobDescription'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'
import kebabCase from 'lodash/kebabCase'
import { useGetCareerSettings, useGetJobPostingSettings } from '@src/api/settings'
import { getLocationDescriptor, useOpenNewTab } from '@src/actions/RouterActions'
import { Link } from 'react-router-dom'
import { useOnPublishClick } from '@src/pages/OnboardingChecklistV2/Jobs/utils'
import Tooltip from '@components/Tooltip/Tooltip'
import { Statuses } from '@src/interfaces'
import { PreviewButton } from '@src/pages/Careers/PreviewButton'

type JobPostingPublishButtonProps = {
  data: JobPostingInterface
  refresh: () => void
  isPublishingEnabled?: boolean
}

const JobPostingPublishButton = ({
  data,
  isPublishingEnabled,
  refresh,
}: JobPostingPublishButtonProps) => {
  const { onPublishClick, publishLoading } = useOnPublishClick(refresh)
  return (
    <Action
      disabled={!isPublishingEnabled}
      pending={publishLoading}
      onClick={() => {
        onPublishClick(
          [data.id],
          data.status === PublishingStatuses.unpublished
            ? PublishingStatuses.fully_published
            : PublishingStatuses.unpublished,
        )
      }}
    >
      {data.status === PublishingStatuses.unpublished ? 'Publish' : 'Unpublish'}
    </Action>
  )
}

type ActionsProps = {
  isPublishingEnabled: boolean
  data: JobPostingInterface
  refresh: () => void
}

const Actions = ({ isPublishingEnabled, data, refresh }: ActionsProps) => {
  const navigateTo = useOpenNewTab()

  return (
    <HStack gap="s-8">
      <Action
        onClick={() => {
          navigateTo(
            pathToUrl(PUBLIC_ROUTES.CAREERS.POSITION, {
              id: `${kebabCase(data.name)}-${data.public_id}`,
            }),
          )
        }}
      >
        Preview
      </Action>
      <JobPostingPublishButton
        data={data}
        isPublishingEnabled={isPublishingEnabled}
        refresh={refresh}
      />
    </HStack>
  )
}

const row: (
  refresh: () => void,
  isPublishingEnabled?: boolean,
  isMonsterEnabled?: boolean,
) => RowInterface<JobPostingInterface> = (
  refresh,
  isPublishingEnabled,
  isMonsterEnabled,
) => ({
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...jobDescriptionGenericNameColumn,
      width: 250,
    },
    {
      ...jobDescriptionOfficeLocationsColumn,
      width: 250,
    },

    {
      ...jobDescriptionRemoteLocationsColumn,
      width: 250,
    },
    {
      ...jobDescriptionPublishedStatusColumn,
      width: 250,
      colors: data =>
        data.status === PublishingStatuses.fully_published
          ? Token.color.green
          : Token.color.foreground,
    },
    {
      ...jobDescriptionPlatformsColumn,
      width: 250,
      insert: ({ data }) => {
        const published = data.status === PublishingStatuses.fully_published

        return (
          <HStack gap="s-8">
            <Tooltip placement="top">
              <Icon
                size={20}
                name="Globe"
                color={published ? Token.color.foreground : Token.color.greyTone50}
              />
            </Tooltip>

            <Tooltip
              placement="top"
              text="It might take a couple of hours for job postings to be reflected on Monster"
            >
              <Avatar
                size={20}
                label="M"
                color={
                  published && isMonsterEnabled
                    ? Token.color.foreground
                    : Token.color.greyTone50
                }
              />
            </Tooltip>
          </HStack>
        )
      },
    },
    {
      ...jobDescriptionActionColumn,
      width: 250,
      insert: ({ data }) => (
        <Actions
          data={data}
          isPublishingEnabled={!!isPublishingEnabled}
          refresh={refresh}
        />
      ),
    },
  ],
})

export const JobsPreview = () => {
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<JobPostingInterface>>()
  const initialFilterBy = [
    {
      columnName: 'approval_status',
      filters: [
        { id: Statuses.pending, name: Statuses.pending },
        { id: Statuses.approved, name: Statuses.approved },
      ],
    },
  ]
  const table = useTable(jobPostingsRequests, initialFilterBy)
  const { data: careersSettings, isLoading: isCareersSettingsLoading } =
    useGetCareerSettings()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const { onPublishClick, publishLoading } = useOnPublishClick(table.refresh)
  const tooltip = useTooltip()

  const getSelectedItems = () => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData.map(r => r.id)
    }

    if (selectedData?.isAllSelected) {
      return table.data
        ?.map(r => r.id)
        .filter(r => !selectedData.excludeListIds.has(`${r}`))
    }

    return []
  }

  const isCareersPublished = careersSettings?.publish_career_website
  const actionsDisabled = !isCareersPublished || !selectedData?.someSelected

  return (
    <VStack gap="s-16">
      {isCareersSettingsLoading ? (
        <ItemSkeleton />
      ) : (
        <Item>
          <Item.Avatar>
            <Avatar
              color={isCareersPublished ? Token.color.green : Token.color.orange}
              useIcon={isCareersPublished ? 'Check' : 'ExclamationTriangle'}
            />
          </Item.Avatar>
          <Item.Content>
            {isCareersPublished ? (
              <>
                <Item.Title>Career website is published</Item.Title>
                <Item.Description>
                  All published jobs are now visible to external candidates. You can
                  change it in Publishing Settings anytime
                </Item.Description>
              </>
            ) : (
              <>
                <Item.Title>Career website is not published</Item.Title>
                <Item.Description>
                  To make your jobs visible to candidates, you have to configure career
                  website and job boards first. Go to “Publishing settings” to start.
                </Item.Description>
              </>
            )}

            <Bar mt="s-12">
              <ActionButton
                useIcon="Gear"
                use={Link}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={getLocationDescriptor(ROUTES.SETTINGS.CAREER_PAGE_ONBOARDING)}
                hasDot={!isCareersPublished}
              >
                Publishing settings
              </ActionButton>
              <PreviewButton />
            </Bar>
          </Item.Content>
        </Item>
      )}

      <TableWidget>
        <TableWidget.Actions>
          <MoreBar>
            {!isCareersPublished && (
              <UIKitTooltip {...tooltip.getTargetProps()}>
                You need to publish careers website before publishing job postings
              </UIKitTooltip>
            )}
            <MoreBar.Action
              {...tooltip.getAnchorProps()}
              useIcon="Globe"
              aria-disabled={actionsDisabled}
              pending={publishLoading}
              onClick={() => {
                if (actionsDisabled) {
                  return
                }
                onPublishClick(getSelectedItems(), PublishingStatuses.fully_published)
              }}
            >
              Publish
            </MoreBar.Action>
            <MoreBar.Action
              {...tooltip.getAnchorProps()}
              useIcon="EyeHide"
              pending={publishLoading}
              aria-disabled={actionsDisabled}
              variant="negative"
              onClick={() => {
                if (actionsDisabled) {
                  return
                }
                onPublishClick(getSelectedItems(), PublishingStatuses.unpublished)
              }}
            >
              Unpublish
            </MoreBar.Action>
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
          >
            <AdjustableTable
              name={TableNames.OnboardingJobsPreview}
              row={row(
                table.refresh,
                isCareersPublished,
                jobPostingSettings?.enable_monster_integration,
              )}
              useWindowScroll
              {...table}
            />
          </SelectTableWrapper>
        </TableWidget.Table>
      </TableWidget>
    </VStack>
  )
}
