import React, { useState } from 'react'
import { BottomSheet, Button, Header, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { bulkEditTimeOffPolicies } from '@src/api/timeOff'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export type BulkUpdateField = 'category' | 'group'

interface BulkUpdateFieldPopupProps {
  field: BulkUpdateField
  selectedItems: number[]
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

const getTitleFromField = (field: BulkUpdateField) => {
  switch (field) {
    case 'category':
      return 'Change category'
    case 'group':
      return 'Change group'
    default:
      return ''
  }
}

const getLabelFromField = (field: BulkUpdateField) => {
  switch (field) {
    case 'category':
      return 'Category'
    case 'group':
      return 'Group'
    default:
      return ''
  }
}

const getSelectorFromField = (field: BulkUpdateField) => {
  switch (field) {
    case 'category':
      return selectorKeys.time_off_policy_categories
    case 'group':
      return selectorKeys.dynamic_groups
    default:
      return null
  }
}

export const BulkUpdateFieldPopup = ({
  open,
  onClose,
  onSuccess,
  field,
  selectedItems,
}: BulkUpdateFieldPopupProps) => {
  const selector = getSelectorFromField(field)
  const statusPopup = useStatusPopup()
  const [pending, setPending] = useState(false)
  const [value, setValue] = useState<IdAndName | null>(null)

  const onSubmit = () => {
    if (!value) {
      return
    }
    setPending(true)
    const payload = { [field]: value.id }

    bulkEditTimeOffPolicies(selectedItems, payload)
      .then(() => {
        setPending(false)
        setValue(null)
        onSuccess()
      })
      .catch(error => {
        setPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>{getTitleFromField(field)}</Header.Title>
      </Header>
      <RadioSelectInput
        label={getLabelFromField(field)}
        onChange={option => {
          setValue(option)
        }}
        selector={selector}
        value={value}
      />
      <BottomSheet.Actions>
        <Button disabled={!value} elevated onClick={onSubmit} pending={pending}>
          Update
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
