import { isWorkspacesEnabled } from '@src/utils'
import { history } from '../utils/router'
import { LocationState } from 'history'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'

export const getBackUrl = (fallbackUrl?: string, state: LocationState = {}) => {
  const locationExists =
    history.location.state &&
    history.location.state.history &&
    history.location.state.history.length

  const historyStack = locationExists
    ? history.location.state.history.slice(0, -1)
    : undefined
  const path = locationExists
    ? history.location.state.history[history.location.state.history.length - 1]
    : fallbackUrl

  const [url, search] = path.split('?')
  const newState = JSON.parse(
    JSON.stringify({ history: historyStack, search, ...(state as object) }),
  )

  return { pathname: url, search, state: newState }
}

export const goBack = (
  fallbackUrl?: string,
  state: LocationState = {},
  replace?: boolean,
) => {
  const { pathname, search, state: prevState } = getBackUrl(fallbackUrl, state)
  const path = search ? `${pathname}?${search}` : pathname

  if (replace) {
    history.replace(path, prevState)
  } else {
    history.push(path, prevState)
  }
}

export const getLocationPathnameWithoutWorkspace = () => {
  const workspacesEnabled = isWorkspacesEnabled()

  if (!workspacesEnabled) {
    return window.location.pathname
  }

  const pathname = window.location.pathname
  return pathname.substring(pathname.indexOf('/', 1))
}

export const getLocationDescriptor = (url: string, state: LocationState = {}) => {
  const newRoute = getLocationPathnameWithoutWorkspace() + window.location.search
  const historyStack = history.location.state?.history
    ? history.location.state.history.concat(newRoute)
    : [newRoute]
  return { pathname: url, state: { history: historyStack, ...(state as object) } }
}

export const getPrevLocationDescriptor = (
  url: string | object,
  state: LocationState = {},
) => {
  const historyStack = history.location.state?.history || []
  if (typeof url === 'object') {
    return { ...url, state: { history: historyStack, ...(state as object) } }
  }

  return { pathname: url, state: { history: historyStack, ...(state as object) } }
}

export const navigateTo = (
  url: string,
  state: LocationState = {},
  withSearch = false,
) => {
  const descriptor = getLocationDescriptor(url, state)
  const newState = JSON.parse(
    JSON.stringify({ history: descriptor.state.history, ...(state as object) }),
  )

  history.push(
    `${descriptor.pathname}${withSearch ? history.location.search : ''}`,
    newState,
  )
}

export const navigateReplace = (
  url: string,
  state: LocationState = {},
  withSearch = false,
) => {
  const newState = JSON.parse(
    JSON.stringify({ history: history.location?.state?.history, ...(state as object) }),
  )

  history.replace(`${url}${withSearch ? history.location.search : ''}`, newState)
}

export const useGetPathWithWorkspace = () => {
  const workspaceContext = useWorkspaceContext()

  return (path: string) =>
    workspaceContext?.workspace
      ? `/${workspaceContext.workspace}${path.startsWith('/') ? path : `/${path}`}`
      : path
}

export const useOpenNewTab = () => {
  const getPathWithWorkspace = useGetPathWithWorkspace()

  return (actionUrl: string) => {
    const isExternalLink = !!actionUrl.match(/^https?:\/\//i)

    if (isExternalLink) {
      window.open(actionUrl, '_blank')
    } else {
      window.open(getPathWithWorkspace(actionUrl), '_blank')
    }
  }
}
