import React, { useMemo } from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Bar, Box, FilterButton, Flex, MoreBar, Widget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import {
  departmentCommunicationColumn,
  nipsColumn,
} from '@src/constants/columns/department'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { Statuses } from '@src/interfaces'
import {
  functionBudgetColumn,
  functionGenericColumn,
  functionHeadcountColumn,
  functionKPIColumn,
  functionLinkColumn,
  functionRequisitionsColumn,
  functionRoadmapColumn,
  functionRoleColumn,
} from '@src/constants/columns/function'
import { functionsRequests } from '@src/api/functions'
import { FunctionInterface, TalentStatsInterface } from '@src/interfaces/functions'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetRoadmapSettings } from '@src/api/settings'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { useGetSlackPreferences } from '@src/api/integrations'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'

const FunctionsTable = () => {
  const user = useSelector(selectUser)
  const [showMyFunctions, setShowMyFunctions] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_FUNCTIONS,
    false,
  )
  const [showArchivedFunctions, setShowArchivedFunctions] = useLocalStorage(
    LOCAL_STORAGE.SHOW_ARCHIVED_FUNCTIONS,
    false,
  )

  const { data: settings } = useGetPerformanceSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: slackSettings } = useGetSlackPreferences()

  const kpisEnabled = !!settings?.enable_function_role_specialisation_kpis
  const slackEnabled = !!slackSettings?.enabled

  const row = useMemo((): RowInterface<FunctionInterface> => {
    return {
      linkToForm: ({ id }) => {
        if (id) {
          navigateTo(pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, { id }))
        } else {
          navigateTo(pathToUrl(ROUTES.FORMS.FUNCTION.SETTINGS, { id }))
        }
      },
      cells: [
        {
          ...functionGenericColumn,
          width: 260,
        },
        {
          ...ownerNameColumn,
          width: 260,
        },
        {
          ...functionHeadcountColumn,
          width: 100,
        },
        {
          ...functionRequisitionsColumn,
          width: 100,
        },
        kpisEnabled
          ? {
              ...functionKPIColumn,
              width: 100,
            }
          : null,
        {
          ...nipsColumn,
          width: 100,
        },
        {
          ...functionRoleColumn,
          width: 100,
        },
        {
          ...functionRoadmapColumn,
          width: 100,
        },
        {
          ...functionBudgetColumn,
          width: 100,
        },
        slackEnabled
          ? {
              ...departmentCommunicationColumn,
              width: 100,
            }
          : null,
        {
          ...functionLinkColumn,
          width: 100,
        },
      ].filter(Boolean),
    }
  }, [kpisEnabled])

  const { FavouritesFilter, initialFilter } = useFavouritesFilter('function')

  const getFilterByOwner = (setFilter: boolean) => ({
    filters: setFilter ? [{ name: user.display_name, id: user.id }] : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getFilterByStatus = (archived: boolean) => ({
    filters: archived
      ? [{ name: Statuses.archived, id: Statuses.archived }]
      : [{ name: Statuses.active, id: Statuses.active }],
    columnName: 'status',
  })

  const getInitialFilter = () => {
    const filter = []

    filter.push(getFilterByStatus(showArchivedFunctions))
    filter.push(getFilterByOwner(showMyFunctions))

    if (initialFilter) {
      filter.push(initialFilter)
    }

    return filter
  }

  const filterBy = getInitialFilter()
  const sortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const table = useTable<FunctionInterface, TalentStatsInterface>(
    functionsRequests,
    filterBy,
    sortBy,
  )
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddFunction)

  const onToggleMyFunctions = () => {
    setShowMyFunctions(!showMyFunctions)
    table.onFilterChange(getFilterByOwner(!showMyFunctions))
  }

  const onToggleArchivedFunctions = () => {
    setShowArchivedFunctions(!showArchivedFunctions)
    table.onFilterChange(getFilterByStatus(!showArchivedFunctions))
  }

  return (
    <Widget display="flex" p="s-16" width="100%">
      <Flex flexDirection="column" width="100%">
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex mb="s-24">
            <Stat
              label="Total"
              val={table?.loading ? undefined : table?.count}
              mr="s-32"
            />
          </Flex>
          <SearchTable
            placeholder="Search by function name"
            mb="s-24"
            onFilter={table.onFilterChange}
          />
        </Flex>
        <Flex mb="s-16" justifyContent="space-between">
          <Box>
            <MoreBar>
              {canAdd && (
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.FUNCTION.SETTINGS)}
                  useIcon={Plus}
                >
                  Add Function
                </MoreBar.Action>
              )}
              <SettingsButton
                path={ROUTES.SETTINGS.ROLES.GENERAL}
                canView={settingsConfig.roles.canView}
              />
            </MoreBar>
          </Box>
          <Bar>
            <FilterButton onClick={onToggleMyFunctions} active={showMyFunctions}>
              My functions
            </FilterButton>
            <FilterButton
              onClick={onToggleArchivedFunctions}
              active={showArchivedFunctions}
            >
              Archived functions
            </FilterButton>
            <FavouritesFilter table={table} />
          </Bar>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<FunctionInterface, TalentStatsInterface>
            name={TableNames.Functions}
            useWindowScroll
            dataType="Function"
            row={row}
            {...table}
            noDataMessage="Functions will appear here."
            hiddenCells={{
              [functionRoadmapColumn.idPoint]: !roadmapSettings?.enabled,
            }}
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default withFavourites(FunctionsTable)
