import {
  Banner,
  Flex,
  HStack,
  Text,
  FilterButton,
  FilterButtonSkeleton,
  useDropdown,
  ActionMenu,
  Box,
  RadioSelect,
  SelectOptionItemType,
} from '@revolut/ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import { OnboardingPreviewScorecardState, previewState } from './previewState'
import { IdAndName, Statuses } from '@src/interfaces'
import { ReviewCategory, ReviewerRelation } from '@src/interfaces/performance'
import { selectorKeys } from '@src/constants/api'
import set from 'lodash/set'
import { ContributorType } from '@src/interfaces/talent/performance'
import { useGetSelectors } from '@src/api/selectors'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'

const Filter = <T extends number | string>({
  value,
  options,
  onChange,
}: {
  value?: IdAndName<T>
  options: IdAndName<T>[]
  onChange: (value: IdAndName<T> | null) => void
}) => {
  const dropdown = useDropdown()

  return value ? (
    <>
      <FilterButton
        useIcon="ChevronDown"
        {...dropdown.getAnchorProps()}
        style={{ height: '30px' }}
        px="s-12"
      >
        {value.name}
      </FilterButton>
      <ActionMenu {...dropdown.getTargetProps()}>
        <ActionMenu.Group>
          {options.map(o => (
            <ActionMenu.Item key={o.id} onClick={() => onChange(o)}>
              {o.name}
            </ActionMenu.Item>
          ))}
        </ActionMenu.Group>
      </ActionMenu>
    </>
  ) : (
    <FilterButtonSkeleton />
  )
}

const FilterWithSearch = <T extends number | string>({
  value,
  options,
  onChange,
}: {
  value?: IdAndName<T>
  options: SelectOptionItemType<IdAndName<T, string>>[]
  onChange: (value: IdAndName<T> | null) => void
}) => {
  const [selectOpen, setSelectOpen] = useState(false)
  const selectRef = useRef<HTMLButtonElement>(null)

  return value ? (
    <Box>
      <FilterButton
        useIcon="ChevronDown"
        ref={selectRef}
        onClick={() => setSelectOpen(!selectOpen)}
        style={{ height: '30px' }}
        px="s-12"
      >
        {value.name}
      </FilterButton>
      <RadioSelect
        open={selectOpen}
        onClose={() => setSelectOpen(false)}
        options={options}
        value={value}
        onChange={option => {
          if (option) {
            onChange(option)
            setSelectOpen(false)
          }
        }}
        labelList="Roles"
        labelSearch="Search roles"
        labelNoResults="No results found"
        indicatorStyle="highlight"
        width={300}
        searchable
      >
        {option => (
          <>
            <Text variant="caption" fontWeight={500}>
              {option.value.name}
            </Text>
          </>
        )}
      </RadioSelect>
    </Box>
  ) : (
    <FilterButtonSkeleton />
  )
}

interface ReviewCategoryPreset {
  name: string
  description?: string // todo: Make description required after PO provides wording REVC-6126
  state: Partial<OnboardingPreviewScorecardState>
}

enum ReviewStatePreset {
  SELF_REVIEW = 'SELF_REVIEW',
  LM_REVIEW = 'LM_REVIEW',
  FM_REVIEW = 'FM_REVIEW',
  PEER_REVIEW = 'PEER_REVIEW',
  UPWARDS_REVIEW = 'UPWARDS_REVIEW',
}

const getPresets = (settings?: PerformanceSettingsInterface) => {
  const presetsList: { [key in ReviewStatePreset]?: ReviewCategoryPreset } = {
    [ReviewStatePreset.SELF_REVIEW]: {
      name: 'Self review',
      state: {
        category: { id: ReviewCategory.Performance, name: 'Performance review' },
        seenBy: { id: ReviewerRelation.Self, name: 'Self' },
        contributorType: { id: ContributorType.IC, name: 'Individual contributor' },
      },
    },
    [ReviewStatePreset.LM_REVIEW]: {
      name: 'Line manager review',
      state: {
        category: { id: ReviewCategory.Performance, name: 'Performance review' },
        seenBy: { id: ReviewerRelation.LineManager, name: 'Line manager' },
        contributorType: { id: ContributorType.IC, name: 'Individual contributor' },
      },
    },
    [ReviewStatePreset.FM_REVIEW]: {
      name: 'Functional manager review',
      state: {
        category: { id: ReviewCategory.Performance, name: 'Performance review' },
        seenBy: { id: ReviewerRelation.FunctionalManager, name: 'Functional manager' },
        contributorType: { id: ContributorType.IC, name: 'Individual contributor' },
      },
    },
  }

  if (settings?.enable_peer_reviews) {
    presetsList[ReviewStatePreset.PEER_REVIEW] = {
      name: 'Peer review',
      state: {
        category: { id: ReviewCategory.Performance, name: 'Performance review' },
        seenBy: { id: ReviewerRelation.Other, name: 'Other' },
        contributorType: { id: ContributorType.IC, name: 'Individual contributor' },
      },
    }
  }

  if (settings?.enable_upwards_reviews) {
    presetsList[ReviewStatePreset.UPWARDS_REVIEW] = {
      name: 'Upwards review',
      state: {
        category: { id: ReviewCategory.Upwards, name: 'Performance review' },
        seenBy: { id: ReviewerRelation.Other, name: 'Other' },
        contributorType: { id: ContributorType.Mgr, name: 'High impact individual' },
      },
    }
  }

  return presetsList
}

export const PreviewSetupBanner = () => {
  const { role } = previewState
  const { data: allSpecialisations } = useGetSelectors<SpecialisationInterface>(
    selectorKeys.specialisations,
  )
  const { data: performanceSettings } = useGetPerformanceSettings()
  const [specialisations, setSpecialisations] = useState<SpecialisationInterface[]>()
  const [presets, setPresets] =
    useState<{ [key in ReviewStatePreset]?: ReviewCategoryPreset }>()
  const [preset, setPreset] = useState<IdAndName<ReviewStatePreset> | undefined>()

  useEffect(() => {
    if (allSpecialisations) {
      setSpecialisations(
        allSpecialisations.filter(item => item.status !== Statuses.archived),
      )
    }
  }, [allSpecialisations])

  useEffect(() => {
    if (performanceSettings) {
      const presetsList = getPresets(performanceSettings)
      setPresets(presetsList)
      setPreset({
        id: ReviewStatePreset.SELF_REVIEW,
        name: presetsList[ReviewStatePreset.SELF_REVIEW]?.name || '',
      })
    }
  }, [performanceSettings])

  const bold = (text: string) => (
    <Text minWidth="fit-content" variant="h5">
      {text}
    </Text>
  )

  if (!presets) {
    return null
  }

  return (
    <Banner>
      <Banner.Content>
        <Banner.Title>
          <Flex flexWrap="wrap" gap="s-8">
            <HStack space="s-8" align="center">
              {bold('This is an example of a')}
              <Filter<ReviewStatePreset>
                value={preset}
                options={Object.entries(presets).map(([id, { name }]) => ({
                  id: id as ReviewStatePreset,
                  name,
                }))}
                onChange={newPreset => {
                  if (newPreset) {
                    const newPresetState = presets[newPreset?.id]?.state
                    if (newPresetState) {
                      Object.entries(newPresetState).forEach(([stateKey, value]) => {
                        set(previewState, stateKey, value)
                      })
                      setPreset(newPreset)
                    }
                  }
                }}
              />
            </HStack>
            <HStack space="s-8" align="center">
              {bold('scorecard for the')}
              <FilterWithSearch<number | string>
                value={role}
                options={
                  specialisations?.map(item => ({
                    key: item.id,
                    value: item,
                    label: item.name,
                  })) || []
                }
                onChange={newRole => {
                  if (newRole) {
                    previewState.role = newRole
                  }
                }}
              />
              {bold('role')}
            </HStack>
          </Flex>
        </Banner.Title>
      </Banner.Content>
    </Banner>
  )
}
