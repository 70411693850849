import { api, apiWithoutHandling } from '@src/api'
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions as UseQueryOptionsBase,
} from 'react-query'
import { QueryFunctionContext } from 'react-query/types/core/types'
import {
  ApiError,
  ApiHandlerInterface,
  ApiVersion,
  UseFetchResult,
} from '@src/interfaces'
import { AxiosError, AxiosResponse } from 'axios'
import { useContext } from 'react'
import { ExtensionApiHandlerContext } from '@src/utils/extension'

export type QueryKeyT = [string, ApiVersion, any]

export type UseQueryOptions<T, E extends Error = Error> = UseQueryOptionsBase<
  T,
  E,
  T,
  QueryKeyT
>

export const fetcher = <T>(
  { queryKey }: QueryFunctionContext<QueryKeyT>,
  withoutHandling?: boolean,
  apiHandler?: ApiHandlerInterface,
  isExternal?: boolean,
): Promise<T> => {
  const [url, version, config] = queryKey

  let apiFunc = withoutHandling ? apiWithoutHandling : api

  if (apiHandler) {
    apiFunc = apiHandler
  }

  return apiFunc.get<T>(url, config, version, isExternal).then(res => res.data)
}
export interface UseFetchProps<T, E extends Error = Error> {
  url: string | null
  version?: ApiVersion
  params?: any
  withoutHandling?: boolean
  queryOptions?: UseQueryOptions<T, E>
  isExternal?: boolean
}

const isProps = <T, E extends Error>(
  input: UseFetchProps<T, E> | string | null,
): input is UseFetchProps<T, E> => {
  return input !== null && typeof input !== 'string'
}

export const useFetch = <T, E extends Error = ApiError>(
  ...args: [
    url: string | null | UseFetchProps<T, E>,
    version?: ApiVersion,
    params?: any,
    withoutHandling?: boolean,
    queryOptions?: UseQueryOptions<T, E>,
    isExternal?: boolean,
  ]
): UseFetchResult<T, E> => {
  const {
    params,
    url,
    version,
    queryOptions,
    withoutHandling,
    isExternal,
  }: UseFetchProps<T, E> = isProps<T, E>(args[0])
    ? args[0]
    : {
        url: args[0],
        version: args[1],
        params: args[2],
        withoutHandling: args[3],
        queryOptions: args[4],
        isExternal: args[5],
      }
  const apiHandler = useContext(ExtensionApiHandlerContext)
  const context = useQuery<T, E, T, QueryKeyT>(
    [url!, version || 'v1', params],
    ({ queryKey, meta }) =>
      fetcher({ queryKey, meta }, withoutHandling, apiHandler, isExternal),
    {
      enabled: !!url,
      ...queryOptions,
    },
  )

  return { ...context, refetch: url ? context.refetch : () => Promise.resolve(context) }
}

export const useDelete = <T>(
  url: string,
  version: ApiVersion = 'v1',
  params?: any,
  updater?: (oldData: T, id?: string | number) => T,
  withoutHandling?: boolean,
) => {
  const queryClient = useQueryClient()
  const apiHandler = withoutHandling ? apiWithoutHandling : api

  return useMutation<AxiosResponse, AxiosError, string | number | undefined>(
    id => apiHandler.delete(id ? `${url}/${id}` : url, params, version),
    {
      onSuccess: (response, id) => {
        if (response && updater) {
          queryClient.setQueryData<T>([url!, version, params], oldData =>
            updater(oldData!, id),
          )
        }
      },
    },
  )
}

export const usePost = <T, S = T, D = S>(
  url: string,
  version: ApiVersion = 'v1',
  params?: any,
  updater?: (oldData: T, newData: S) => T,
  withoutHandling?: boolean,
) => {
  const queryClient = useQueryClient()
  const apiHandler = withoutHandling ? apiWithoutHandling : api

  return useMutation<AxiosResponse<S>, AxiosError, D>(
    data => apiHandler.post<S>(url, data, params, version),
    {
      onSuccess: response => {
        if (response.data && updater) {
          queryClient.setQueryData<T>([url!, version, params], oldData =>
            updater(oldData!, response.data),
          )
        }
      },
    },
  )
}

export const useUpdate = <T, S>(
  url: string,
  version: ApiVersion = 'v1',
  params?: any,
  usePut?: boolean,
  updater?: (newData: T, oldData: T, id: string | number | undefined) => T,
  withoutHandling?: boolean,
  useId: boolean = true,
) => {
  const queryClient = useQueryClient()
  const apiHandler = withoutHandling ? apiWithoutHandling : api

  return useMutation<AxiosResponse<T>, AxiosError, [string | number | undefined, S]>(
    ([id, data]) =>
      usePut
        ? apiHandler.put<T>(id ? `${url}/${id}` : url, data, params, version)
        : apiHandler.patch<T>(id ? `${url}/${id}` : url, data, params, version),
    {
      onSuccess: (response, [id]) => {
        if (response.data && updater) {
          queryClient.setQueryData<T>(
            [id && useId ? `${url}/${id}` : url, version, params],
            oldData => updater(response.data, oldData!, id),
          )
        }
      },
    },
  )
}
