import React, { useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  IconButton,
  MoreBar,
  StatusPopup,
  TableWidget,
  Token,
  useStatusPopup,
} from '@revolut/ui-kit'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklistV2'
import {
  adjustBalanceTmpAssignment,
  deleteTmpAssignment,
  getTimeOffPolicyTempAssignmentsEmployees,
} from '@src/api/timeOff'
import {
  onboardingTimeManagementTempPolicyAssignmentsActionColumn,
  onboardingTimeManagementTempPolicyAssignmentsEmployeeColumn,
  onboardingTimeManagementTempPolicyAssignmentsLocationColumn,
  onboardingTimeManagementTempPolicyAssignmentsProjectedBalanceColumn,
} from '@src/constants/columns/onboardingTimeManagement'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  ChildTempPolicyAssignmentsEmployeeInterface,
  NormalizedTempPolicyAssignmentsEmployeeInterface,
  OnboardingTempPolicyAssignmentsEmployeeInterface,
  TempPolicyAssignmentsPolicyInterface,
} from '@src/interfaces/timeOff'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { timeManagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { StepCompletedWidget } from '@src/pages/OnboardingChecklist/components/StepCompletedWidget'
import {
  CellCss,
  CellErrorCss,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'
import { AssignPolicyPopup } from './components/AssignPolicyPopup'
import { SubmitTempAssignments } from './components/SubmitTempAssignments'

const getRow = (
  onEditAction: (
    data: ChildTempPolicyAssignmentsEmployeeInterface,
    field: keyof TempPolicyAssignmentsPolicyInterface,
    value: number,
  ) => void,
  onDeleteTmpAssignment: (id: number) => void,
): RowInterface<OnboardingTempPolicyAssignmentsEmployeeInterface> => ({
  noChildrenRequest: true,
  cells: [
    {
      ...onboardingTimeManagementTempPolicyAssignmentsEmployeeColumn,
      width: 200,
    },
    {
      ...onboardingTimeManagementTempPolicyAssignmentsLocationColumn,
      width: 150,
    },
    {
      ...onboardingTimeManagementTempPolicyAssignmentsProjectedBalanceColumn(
        onEditAction,
      ),
      width: 150,
      wrapperCss: (rowData: OnboardingTempPolicyAssignmentsEmployeeInterface) =>
        !('children' in rowData) && rowData.errors.projected_balance_amount
          ? CellErrorCss
          : CellCss,
    },
    {
      ...onboardingTimeManagementTempPolicyAssignmentsActionColumn,
      width: 100,
      insert: ({ data }) => {
        if ('children' in data) {
          return ''
        }

        return (
          <IconButton
            color={Token.color.greyTone50}
            onClick={() => onDeleteTmpAssignment(data.id)}
            size={16}
            useIcon="Delete"
          />
        )
      },
    },
  ],
})

export const PolicyAssignments = () => {
  const statusPopup = useStatusPopup()
  const [popupOpen, setPopupOpen] = useState(false)
  const table = useTable({ getItems: getTimeOffPolicyTempAssignmentsEmployees })

  const { data: checkpoint } = useGetOnboardingCheckpointCategory(
    timeManagementConfig.category,
  )
  const stepIsCompleted = checkpoint?.state.id === 'completed'

  const produceTableData = (
    cb: (
      rowData: NormalizedTempPolicyAssignmentsEmployeeInterface,
    ) => NormalizedTempPolicyAssignmentsEmployeeInterface,
  ) => {
    table.setData(prev => prev.map(r => cb(r)))
  }

  const hasChildrenToEdit = (
    id: number,
    row: NormalizedTempPolicyAssignmentsEmployeeInterface,
  ) => row.children.find(child => child.id === id)

  const onEditAction = (
    rowData: ChildTempPolicyAssignmentsEmployeeInterface,
    field: keyof TempPolicyAssignmentsPolicyInterface,
    value: number,
  ) => {
    if (rowData.data[field] === value) {
      return
    }

    produceTableData(row => {
      if (hasChildrenToEdit(rowData.id, row)) {
        const producedChildren = row.children.map(child =>
          child.id === rowData.id
            ? {
                ...child,
                data: {
                  ...child.data,
                  [field]: value,
                },
                errors: { ...child.errors, [field]: undefined },
                loading: { ...child.loading, [field]: true },
              }
            : child,
        )

        return cloneDeep({
          ...row,
          children: producedChildren,
        })
      }

      return row
    })

    adjustBalanceTmpAssignment(rowData.id, value)
      .then(response => {
        produceTableData(row => {
          if (hasChildrenToEdit(rowData.id, row)) {
            const producedChildren = row.children.map(child =>
              child.id === rowData.id
                ? {
                    ...child,
                    ...response.data,
                    data: {
                      ...child.data,
                      [field]: value,
                    },
                    errors: { ...child.errors, [field]: undefined },
                    loading: { ...child.loading, [field]: false },
                  }
                : child,
            )

            return cloneDeep({
              ...row,
              children: producedChildren,
            })
          }

          return row
        })
      })
      .catch(error => {
        const fieldError = getStringMessageFromError(error, 'Something went wrong')

        produceTableData(row => {
          if (hasChildrenToEdit(rowData.id, row)) {
            const producedChildren = row.children.map(child =>
              child.id === rowData.id
                ? {
                    ...child,
                    data: {
                      ...child.data,
                      [field]: value,
                    },
                    errors: { ...child.errors, [field]: [fieldError] },
                    loading: { ...child.loading, [field]: false },
                  }
                : child,
            )

            return cloneDeep({
              ...row,
              children: producedChildren,
            })
          }
          return row
        })
      })
  }

  const onDeleteTmpAssignment = (id: number) => {
    deleteTmpAssignment(id)
      .then(() => table.refresh())
      .catch(error => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to delete</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <>
      {stepIsCompleted ? (
        <StepCompletedWidget />
      ) : (
        <TableWidget>
          <TableWidget.Actions>
            <MoreBar>
              <MoreBar.Action
                to={pathToUrl(
                  ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.UPLOAD.IMPORT,
                )}
                use={InternalLink}
                useIcon="Upload"
              >
                Import balance adjustments
              </MoreBar.Action>
              <MoreBar.Action onClick={() => setPopupOpen(true)} useIcon="Plus">
                Assign policy
              </MoreBar.Action>
            </MoreBar>
          </TableWidget.Actions>
          <TableWidget.Table>
            <AdjustableTable
              enableSettings={false}
              expandableType="chevron"
              lockFirstColumn={false}
              lockLastColumn
              name={TableNames.TempTimeOffPolicyAssignments}
              row={getRow(onEditAction, onDeleteTmpAssignment)}
              rowHeight="medium"
              useWindowScroll
              {...table}
            />
          </TableWidget.Table>
        </TableWidget>
      )}
      <SubmitTempAssignments stepIsCompleted={stepIsCompleted} />
      <AssignPolicyPopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        onSuccess={() => {
          table.refresh()
          setPopupOpen(false)
        }}
      />
    </>
  )
}
