import React from 'react'
import styled from 'styled-components'
import { Box, Flex, HStack, Text, Token } from '@revolut/ui-kit'
import {
  useCareersSettings,
  useFetchPosition,
  useIsCareersPreview,
} from '@src/api/recruitment/careers'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import MobileMenu from '@src/pages/Careers/MobileMenu'
import { useParams } from 'react-router-dom'
import { PreviewBanner } from '@src/pages/Careers/PreviewBanner'
import { getIdFromPositionSlug } from '@src/pages/Careers/utils'
import { CareersPositionPreviewResponse } from '@src/interfaces/careers'
import { notReachable } from '@src/utils/notReachable'
import { useGetPathWithWorkspace } from '@src/actions/RouterActions'

const Logo = styled.img`
  width: auto;
  height: 100%;
`

const Links = styled(HStack)`
  @media all and (max-width: 1024px) {
    display: none;
  }
`

export enum PageType {
  jobList = 'jobList',
  application = 'application',
  position = 'position',
}

type Props = {
  type: PageType
}

const Header = ({ type }: Props) => {
  const getPathWithWorkspace = useGetPathWithWorkspace()
  const { id } = useParams<{ id?: string }>()
  const { data: settings } = useCareersSettings()
  const isCareersPreview = useIsCareersPreview()
  const { data: position, refetch: refetchPosting } =
    useFetchPosition<CareersPositionPreviewResponse>(
      id && type === 'position' ? getIdFromPositionSlug(id) : null,
    )

  const getBannerContent = () => {
    switch (type) {
      case PageType.jobList:
      case PageType.application:
        return {
          title: 'This is how your applicants will see published jobs',
        }

      case PageType.position: {
        if (!position) {
          return null
        }

        return {
          title: `Job preview: ${position.text}`,
          status: position.status,
          id: position.internalId,
        }
      }

      default:
        throw notReachable(type)
    }
  }

  const bannerContent = getBannerContent()

  return (
    <>
      {isCareersPreview && bannerContent && (
        <PreviewBanner
          id={bannerContent.id}
          title={bannerContent.title}
          status={bannerContent.status}
          onRefresh={refetchPosting}
        />
      )}
      <Box
        p="s-8"
        width="100%"
        borderBottom="1px solid"
        borderBottomColor={Token.color.greyTone10}
        height={64}
        mb="s-72"
        style={{ flexShrink: 0, flexGrow: 0 }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          maxWidth="1000px"
          m="auto"
          height="100%"
        >
          <HStack gap="s-40" align="center" height="100%">
            {settings?.company_logo && (
              <a
                href={getPathWithWorkspace(PUBLIC_ROUTES.CAREERS.JOB_LIST)}
                style={{ height: '100%' }}
              >
                <Logo
                  src={settings.company_logo.url}
                  title={settings.company_logo.name}
                />
              </a>
            )}

            <Links gap="s-40" align="center" height="100%">
              {settings?.company_links?.map(item => (
                <Text
                  key={item.label}
                  variant="primary"
                  use="a"
                  href={item.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  textDecoration="none"
                  color={Token.color.foreground}
                  aria-label={item.label}
                >
                  {item.label}
                </Text>
              ))}
            </Links>
          </HStack>

          <MobileMenu
            links={settings?.company_links}
            logoUrl={settings?.company_logo?.url}
            logoName={settings?.company_logo?.name}
          />
        </Flex>
      </Box>
    </>
  )
}

export default Header
