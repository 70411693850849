import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const EmployeeRecordsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Employees.mov"
      videoPosterUrl="/intros/Intro%20-%20Employees.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.employeeRecords.intro.tips.title"
            defaultMessage="Unify and customise all employee records"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.records.title"
                defaultMessage="Employee records"
              />
            ),
            icon: 'Profile',
            text: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.records.text"
                defaultMessage="All your employee information can be stored in one place, we bring many fields for you but you are always free to add more employee fields to store all your information."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.lifecycle.title"
                defaultMessage="Employee lifecycle"
              />
            ),
            icon: 'Rocket',
            text: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.lifecycle.text"
                defaultMessage="Onboard employees into your product, providing a great experience from the beginning. When offboarding make sure you are compliant."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.compensation.title"
                defaultMessage="Contract & compensation"
              />
            ),
            icon: 'Document',
            text: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.compensation.text"
                defaultMessage="All your employment contract data is stored as a digital contract, with compensation, seniority and more data."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
