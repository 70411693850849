import React, { useState } from 'react'
import { connect } from 'lape'
import { Flex, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { SaveDraftButton } from '@src/pages/Forms/EmployeePerformanceLayout/SaveDraftButton'
import { SubmitButton } from '@src/pages/Forms/EmployeePerformanceLayout/SumbitButton'
import { PageActions } from '@components/Page/PageActions'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'
import { SkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/SkillsCard'
import { CultureValuesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/CultureValuesCard'
import { Feedback } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Feedback'
import { ProbationDeliverablesCard } from '@src/pages/Forms/ProbationLayout/Cards/ProbationDeliverablesCard'
import { getRecommendationAndType } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { BarRaiser } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/BarRaiser'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { useGetProbationMissingValues } from '@src/pages/Forms/ProbationLayout/utils'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useGetNotCompletedReviews } from '@src/api/performanceReview'
import { Statuses } from '@src/interfaces'
import { useGetReviewGradesMap } from '@src/utils/grades'

export const ProbationContent = connect(() => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const [isHelpOpen, setHelpOpen] = useState<HelpTabs | undefined>()
  const [submitChecked, setSubmitChecked] = useState(false)
  const recommendationSection = getRecommendationAndType(values)
  const { missingRating, missingBarRaiser } = useGetProbationMissingValues(values)
  const newGradesMap = useGetReviewGradesMap()
  const gradesMap = values.grade_label_mapping || newGradesMap

  const user = useSelector(selectUser)
  const reviews = useGetNotCompletedReviews(user.id)
  const reviewsList = reviews?.data?.results?.filter(
    review => review.status === Statuses.pending || review.status === Statuses.draft,
  )

  const onHelpTooltipClick = (helpTab: HelpTabs) => {
    if (isHelpOpen) {
      setHelpOpen(undefined)
    }
    setHelpOpen(helpTab)
  }

  const beforeSubmitCheck = () => {
    setSubmitChecked(true)
    return !(missingRating || missingBarRaiser)
  }

  const commonProps = {
    onHelpClick: onHelpTooltipClick,
    gradesMap,
  }

  return (
    <>
      <PerformanceHeader isHelpOpen={isHelpOpen} setHelpOpen={setHelpOpen} />
      <VStack space="s-16">
        <ProbationDeliverablesCard {...commonProps} />
        <SkillsCard {...commonProps} />
        <CultureValuesCard {...commonProps} />
        {recommendationSection && (
          <BarRaiser
            isViewMode={false}
            questions={
              recommendationSection.recommendation.pip_recommendation ||
              recommendationSection.recommendation.recommendation
            }
            type={recommendationSection.type}
            field={recommendationSection.field}
            showBeforeSubmitCheckError={submitChecked}
          />
        )}
        <Feedback
          recommendationData={recommendationSection?.recommendation}
          type={recommendationSection?.type}
          showBeforeSubmitCheckError={submitChecked}
          gradesMap={gradesMap}
        />
        <PageActions maxWidth="100%">
          <Flex gap="s-8" justifyContent="center">
            <SaveDraftButton reviewsList={reviewsList} />
            <SubmitButton
              beforeSubmitCheck={beforeSubmitCheck}
              reviewsList={reviewsList}
            />
          </Flex>
        </PageActions>
      </VStack>
    </>
  )
})
