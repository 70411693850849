import React from 'react'

import { PermissionTypes } from '@src/store/auth/types'

import { GoalsSettingsProps } from './Goals'
import Templates from '@src/pages/Forms/KpiTemplates/Templates'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'

export const GoalsSettingsTemplates = ({ templatesRoute }: GoalsSettingsProps) => {
  const isOnboarding = isOnboardingPath()
  const permissions = useSelector(selectPermissions)

  const hasPermission = permissions.includes(PermissionTypes.ViewKpiTemplate)

  return (
    <PageWrapper>
      <PageHeader
        withVerticalSpacing={isOnboarding}
        title="Templates"
        subtitle="Manage goal templates"
        backUrl={pathToUrl(ROUTES.SETTINGS.ALL)}
        key={templatesRoute}
        hideGlobalSearch
      />

      {hasPermission ? <Templates variant="goal" /> : <NoAccessError />}
    </PageWrapper>
  )
}
