import React, { ReactNode, useMemo, useState } from 'react'
import {
  ReviewCyclesInterface,
  ReviewCyclesPreviewInterface,
} from '@src/interfaces/reviewCycles'
import { HStack, Input, SelectOptionItemType, Text } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { PerformanceSelector } from '@src/interfaces/performance'
import { getCycleOffsetTitleAndColor } from '@src/utils/performance'

export interface CycleSelectorProps {
  reviewCycles:
    | ReviewCyclesInterface[]
    | PerformanceSelector[]
    | ReviewCyclesPreviewInterface[]
  value?: ReviewCyclesInterface
  onSelect: (cycle: ReviewCyclesInterface) => void
  disabled?: boolean
  message?: string
  hasError?: boolean
  children?: (cycle: SelectOptionItemType<ReviewCyclesInterface>) => ReactNode
}

export const renderCycleName = (cycle?: ReviewCyclesInterface) => {
  const { color, title } = getCycleOffsetTitleAndColor(cycle?.offset)
  return cycle ? (
    <HStack space="s-16">
      <Text>{cycle?.name}</Text>
      {cycle.status && (
        <Text ml="1em" color={color}>
          {title}
        </Text>
      )}
    </HStack>
  ) : undefined
}

const CycleSelector = ({
  onSelect,
  reviewCycles,
  value,
  disabled,
  children,
  ...selectProps
}: CycleSelectorProps) => {
  const options = useMemo(() => {
    return reviewCycles.map(cycle => ({
      value: cycle as ReviewCyclesInterface,
      label: cycle.name,
    }))
  }, [reviewCycles])
  const [selectedCycle, setSelectedCycle] = useState(value)
  const { title } = getCycleOffsetTitleAndColor(selectedCycle?.offset)

  return (
    <RadioSelectInput<ReviewCyclesInterface>
      renderInput={(open, setOpen, anchorRef) => (
        <Input
          label="Review cycle"
          containerRef={anchorRef}
          type="button"
          useIcon="ChevronDown"
          onClick={() => setOpen(!open)}
          aria-haspopup="listbox"
          aria-expanded={open}
          focused={open}
          aria-invalid={selectProps.hasError}
          errorMessage={selectProps.message}
          disabled={disabled}
          value={
            selectedCycle && selectedCycle.status
              ? `${selectedCycle.name} (${title})`
              : selectedCycle?.name
          }
        />
      )}
      options={options}
      value={value}
      disabled={disabled}
      onChange={cycle => {
        if (cycle) {
          onSelect(cycle)
        }
        setSelectedCycle(cycle || undefined)
      }}
      {...selectProps}
    >
      {option => renderCycleName(option.value)}
    </RadioSelectInput>
  )
}

export default CycleSelector
