import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const JobsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Jobs.mov"
      videoPosterUrl="/intros/Intro%20-%20Jobs.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.jobs.intro.title"
            defaultMessage="Add your jobs"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text1"
            defaultMessage="Start your recruitment process with adding jobs to the product. You can use the bulk import feature to upload a single file with all your jobs or manually add them one by one."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text2"
            defaultMessage="Every job has information about requisition (role, team, required headcount, seniority, hiring manager and recruiter) and a job description that will be published on the job boards and your careers website to attract candidates."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text3"
            defaultMessage="Configure your careers website and preview how your job postings would look like there. You can publish them right away and start receiving applications immediately or keep them unpublished for later use."
          />
        </p>
      </OnboardingIntroTextWidget>
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.jobs.intro.tips.title"
            defaultMessage="What are jobs?"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.requisitions.title"
                defaultMessage="Requisitions"
              />
            ),
            icon: 'AddContact',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.requisitions.text"
                defaultMessage="Each job is made up of two main parts. First, there are requisitions. Think of them as essential headcount planning tool highlighting how many new faces you need within a specific team, with a desired role and seniority. They are necessary to hire a candidate."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.postings.title"
                defaultMessage="Job postings"
              />
            ),
            icon: 'Megaphone',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.postings.text"
                defaultMessage="Then, there’s the job posting. This is the job advertisement you will share to attract candidates, highlighting the essence of the position and your company - responsibilities, required skills, and the unique culture of your organisation. You can keep job postings internal to be visible among your team members or share out to the world on your dedicated careers website and selected job boards. Let everyone know that you’re on the lookout for new talent."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.board.title"
                defaultMessage="Job boards and careers website"
              />
            ),
            icon: 'IndustrialGear',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.board.text"
                defaultMessage="Check and configure integrations with job boards, customise and preview your careers website and how your job postings would look like when published. You can publish them right away or keep them unpublished and activate at any time you want to advertise the job opening."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
