import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const CandidatesIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Candidates.mov"
      videoPosterUrl="/intros/Intro%20-%20Candidates.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.candidates.intro.title"
            defaultMessage="Add candidates"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.candidates.intro.text1"
            defaultMessage="When hundreds of candidates apply for a role, having all the information about them is critical to maintain a high level of hiring accuracy. At this step you can add your existing candidates to Revolut People and immediately continue your hiring activities."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.candidates.intro.text2"
            defaultMessage="All of the essential candidate data is consolidated on the candidate profile, enabling you to track their progress and jump into action at every stage of the recruitment process."
          />
        </p>

        <FormattedMessage
          id="onboardingV2.candidates.intro.text3"
          defaultMessage="Basic fields of the candidate profile are:"
        />
        <OnboardingIntroList
          items={[
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.name"
                  defaultMessage="Candidate name"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.email"
                  defaultMessage="Email"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.role"
                  defaultMessage="Role"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.country"
                  defaultMessage="Country"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.seniority"
                  defaultMessage="Seniority"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.recruiter"
                  defaultMessage="Dedicated recruiter"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.posting"
                  defaultMessage="Job posting"
                />
              ),
            },
          ]}
        />

        <FormattedMessage
          id="onboardingV2.candidates.intro.text4"
          defaultMessage="You can add more information (documents, preferred locations, preferred salary, etc) later when interacting directly with the candidate’s profile."
        />
      </OnboardingIntroTextWidget>

      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.candidates.intro.tips.title"
            defaultMessage="Set up your candidates"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.profile.title"
                defaultMessage="Candidate profile"
              />
            ),
            icon: 'Profile',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.profile.text"
                defaultMessage="Candidate profile is the hub for all the essential information about your applicants. You can review the candidate’s timeline, send emails, schedule interviews, share access, provide feedback through interview scorecards, add comments and create tasks for other members of your team, all from a single place."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.add.title"
                defaultMessage="How to add candidates?"
              />
            ),
            icon: 'AddContact',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.add.text"
                defaultMessage="There are two methods for adding your candidates. For companies with an existing pool of applicants, bulk upload is the most efficient option. During the candidate upload step, click on “Upload candidates” to add them in bulk. If you're in the beginning of the hiring process and only have a handful of applicants, consider adding them manually, one at a time, by selecting the “Add candidate” button. You can upload candidates in bulk or add them individually at any point of time in the “Candidates” app."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.permissions.title"
                defaultMessage="Candidate profile permissions"
              />
            ),
            icon: 'Shield',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.permissions.text"
                defaultMessage="To view candidate information, you must be assigned as a dedicated recruiter, be the hiring manager, or have explicit Recruiter or Recruitment Manager access. Selected profiles can be temporarily shared with other employees, ensuring everyone is aligned when hiring the top talent."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.sensitive.title"
                defaultMessage="Sensitive information"
              />
            ),
            icon: 'EyeShow',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.sensitive.text"
                defaultMessage="Certain information within the candidate profile may be sensitive. Fields such as Desired Salary or Right to Work status are accessible only to the members of the organisation actively involved in the hiring process of a specific candidate: the Assigned Recruiter and Hiring Manager."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.gdpr.title"
                defaultMessage="Data Protection Regulations"
              />
            ),
            icon: 'LockClosed',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.gdpr.text"
                defaultMessage="You can set up data protection rules and automations to ensure that your organisation is compliant with the latest regulations, no matter where you operate. Delete information about candidates who requested their data to be removed or set up the period after which the candidate information should be anonymised in the system."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
