import React from 'react'
import { RoadMapTablesProps, useRenderRoadmapTables } from './table'
import { Flex, TableWidget } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { RoadmapActionsBar, useRoadmapRefresh } from '.'
import RoadmapLockedChangesBanner from './RoadmapLockedChangesBanner'
import { RoadmapSettingsInterface } from '@src/interfaces/settings'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { TableNames } from '@src/constants/table'
import { useIsNewOrgLayout } from '@src/pages/Team/helpers'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalCycleFilter } from '../../components/GoalCycleFilter'

export interface RoadmapTabProps extends RoadMapTablesProps {
  allowRefresh: boolean
  triggerRefresh: (cycleId?: string | number) => Promise<void>
  tableInfoMessage?: React.ReactNode
  roadmapSettings?: RoadmapSettingsInterface
  showTableInfo: boolean
  newLayoutNavigation?: React.ReactElement
}

export const RoadmapTab = (props: RoadmapTabProps) => {
  const {
    triggerRefresh,
    tableInfoMessage,
    roadmapSettings,
    allowRefresh,
    showTableInfo,
    newLayoutNavigation,
    ...tableOuterProps
  } = props
  const { entity } = useOrgEntity()
  const { reviewCycle } = tableOuterProps
  const { table, changelogSwitcher, changelogState, renderTable } =
    useRenderRoadmapTables(tableOuterProps)
  const isNewLayout = useIsNewLayout()
  const isNewOrgLayout = useIsNewOrgLayout()

  const { renderCount, refreshButton, pendingTaskId } = useRoadmapRefresh({
    epics: table.data.map(r => r.key),
    onAfterRefresh: () => table.refresh(),
  })
  const moreBarPending = !roadmapSettings || !props.reviewCycle

  return (
    <TableWidget>
      <TableWidget.Info>
        {entity && (
          <Flex minWidth="fit-content">
            <GoalCycleFilter
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__id"
              selector={selectorKeys.review_cycles}
              filter={table.filterBy}
              allowEmployeeCycles={false}
            />
          </Flex>
        )}
        <OverallProgress value={table?.stats?.avg_done} />
      </TableWidget.Info>
      {!!newLayoutNavigation &&
        ((isNewLayout && props.tableProps.name === TableNames.EmployeeRoadmap) ||
          (isNewOrgLayout && props.tableProps.name === TableNames.DepartmentRoadmap)) && (
          <TableWidget.Filters>
            <Flex width="100%" justifyContent="flex-end">
              {newLayoutNavigation}
            </Flex>
          </TableWidget.Filters>
        )}
      <TableWidget.Actions>
        <RoadmapActionsBar pending={moreBarPending}>
          {changelogSwitcher}
          {allowRefresh && refreshButton}
        </RoadmapActionsBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <Flex style={{ position: 'relative' }} flex="1 0">
          {renderTable({
            loading: !!pendingTaskId,
            renderCount: changelogState.active
              ? () => <RoadmapLockedChangesBanner reviewCycle={reviewCycle || null} />
              : allowRefresh && table?.stats?.refresh_date_time
              ? count => renderCount(count, table?.stats?.refresh_date_time!)
              : undefined,
          })}
        </Flex>
      </TableWidget.Table>
    </TableWidget>
  )
}
