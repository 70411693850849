import axios, {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios'
import get from 'lodash/get'

import { API_ENDPOINT, env, Environments } from '../constants/api'
import { pushError } from '../store/notifications/actions'
import { ApiHandlerInterface, ApiVersion } from '@src/interfaces'

export const handleError = (error: AxiosError) => {
  pushError({ error })
  throw error
}

// Onboarding user can't make requests to non onboarding endpoints
// This is used to avoid error notifications when the onboarding user hits the root URL
export const onboardingHandleError = (error: AxiosError) => {
  if (
    get(error, 'response.status') === 403 &&
    get(error, 'response.data.detail') ===
      'You do not have permission to perform this action.'
  ) {
    throw error
  } else {
    return handleError(error)
  }
}

export const getConfig = (config?: AxiosRequestConfig): AxiosRequestConfig => {
  const cacheHeaders: AxiosRequestHeaders | undefined =
    env === Environments.developmentSubdomains
      ? {
          'Cache-Control': 'no-cache',
          Expires: '0',
        }
      : undefined

  if (!config) {
    return {
      withCredentials: true,
      headers: cacheHeaders,
    }
  }

  const { headers, ...rest } = config

  return {
    withCredentials: true,
    headers: {
      ...headers,
      ...(cacheHeaders || {}),
    },
    ...rest,
  }
}

// if BE endpoint uses Captcha validation it has different error handling logic (like in Careers and Reset Password)
export const normalizeCaptchaError = (error: AxiosError) => {
  if (
    error?.response?.data?.errors &&
    error?.response?.data?.message === 'validation-error'
  ) {
    // eslint-disable-next-line no-throw-literal
    throw {
      ...error,
      response: {
        ...error.response,
        data: { ...error.response.data.errors },
      },
    }
  }

  throw error
}

const getRoute = (route: string, apiVersion: ApiVersion, isExternal: boolean) => {
  return `${API_ENDPOINT()}${isExternal ? 'external/' : ''}${apiVersion}${route}`
}

export const apiWithoutHandling: ApiHandlerInterface = {
  get: <T>(
    route: string,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ) => {
    return axios.get<T>(getRoute(route, apiVersion, isExternal), getConfig(config))
  },
  post: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios.post<T>(getRoute(route, apiVersion, isExternal), data, getConfig(config))
  },
  patch: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios.patch<T>(
      getRoute(route, apiVersion, isExternal),
      data,
      getConfig(config),
    )
  },
  put: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios.put<T>(getRoute(route, apiVersion, isExternal), data, getConfig(config))
  },
  delete: <T = any>(
    route: string,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise => {
    return axios.delete<T>(getRoute(route, apiVersion, isExternal), getConfig(config))
  },
}

export const api: ApiHandlerInterface = {
  get: <T>(
    route: string,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ) => {
    return axios
      .get<T>(getRoute(route, apiVersion, isExternal), getConfig(config))
      .catch(handleError)
  },
  post: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios
      .post<T>(getRoute(route, apiVersion, isExternal), data, getConfig(config))
      .catch(handleError)
  },
  patch: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios
      .patch<T>(getRoute(route, apiVersion, isExternal), data, getConfig(config))
      .catch(handleError)
  },
  put: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios
      .put<T>(getRoute(route, apiVersion, isExternal), data, getConfig(config))
      .catch(handleError)
  },
  delete: <T = any>(
    route: string,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios
      .delete<T>(getRoute(route, apiVersion, isExternal), getConfig(config))
      .catch(handleError)
  },
}
