import React from 'react'

import { APPLICATIONS, globalSettingsAppTabsFilter } from '@src/constants/hub'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { OrgEntityProvider } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import Loader from '@src/components/CommonSC/Loader'
import { useGetCompany } from '@src/api/company'
import { EntityTypes } from '@src/constants/api'
import { HubAppWithOnboardingScreen } from '@src/features/HubApp/HubAppWithOnboardingScreen'

export const Goals = () => {
  const { data: performanceSettings } = useGetPerformanceSettings()

  const tabs = APPLICATIONS.goals.tabs.filter(tab =>
    globalSettingsAppTabsFilter(tab.homeSectionId, { performanceSettings }),
  )
  const { data, isLoading } = useGetCompany()

  if (isLoading || !data) {
    return <Loader />
  }

  return (
    <OrgEntityProvider
      entity={{
        type: EntityTypes.companyV2,
        data,
      }}
    >
      <HubAppWithOnboardingScreen
        category="goals"
        app={{ ...APPLICATIONS.goals, tabs }}
      />
    </OrgEntityProvider>
  )
}
