import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const InterviewsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Interviews.mov"
      videoPosterUrl="/intros/Intro%20-%20Interviews.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.interviews.intro.title"
            defaultMessage="Configure your interview process"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.interviews.intro.text1"
            defaultMessage="Jobs and existing candidates are in the system. Potential candidates can see job opportunities on your careers website and apply for a role. Now it is time to put interview process in place."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.interviews.intro.text2"
            defaultMessage="When set up properly, interview process in Revolut People allows you to achieve high hiring precision by assessing candidates’ skills and performance. It also optimises time and effort with automation and scheduling features."
          />
        </p>
        <FormattedMessage
          id="onboardingV2.interviews.intro.text3"
          defaultMessage="Start with reviewing default interview process."
        />
        <OnboardingIntroList
          items={[
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.cv"
                  defaultMessage="CV Screening"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.screenCall"
                  defaultMessage="Screen Call"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.skills"
                  defaultMessage="Skills Interview"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.final"
                  defaultMessage="Final Interview"
                />
              ),
            },
          ]}
        />
        <FormattedMessage
          id="onboardingV2.interviews.intro.text3"
          defaultMessage="You can adjust the default hiring process by removing the proposed stages or adding your own. This interview process will be applied to every role you have and can be adjusted later, making hiring process for each role unique and specific to your needs."
        />
      </OnboardingIntroTextWidget>

      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.interviews.intro.tips.title"
            defaultMessage="Define your interview process"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.process.title"
                defaultMessage="Interview process"
              />
            ),
            icon: '16/ListNumber',
            text: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.process.text"
                defaultMessage="Revolut People allows you to apply a default hiring process to be used across the organisation, and later adjust the processes for every role individually. Each job you created previously is assigned to a specific role, and each role can has a unique hiring process which you can change at any time. Therefore, all the candidates who applied to the same job posting will go through the same hiring process."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.stages.title"
                defaultMessage="Interview stages"
              />
            ),
            icon: 'Microphone',
            text: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.stages.text"
                defaultMessage="Interview process is built from the interview stages. Each stage is characterised by a name, type, scorecard and a set of interviewers.{br}{br}Hiring stages are categorised into several types: CV Screening, Screen Call, Home Assessment, Skills Round, Final Round and the Offer. Those are more than just labels. For example, Skills Round stage would generate a unique scorecard for each role you have to measure candidates’ role-specific skills. You can create one or more stages under each type."
                values={{
                  br: <br />,
                }}
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.scorecards.title"
                defaultMessage="Scorecards"
              />
            ),
            icon: 'Questionnaire',
            text: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.scorecards.text"
                defaultMessage="Scorecard is the list of the questions candidates will be asked during the interview. Each interview stage, except for CV screening, is connected to an interview scorecard that will help you to ask the right questions and capture candidates’ answers during the interview. Scorecards can be reused between different interview stages. For example, you can have the same Screen Call scorecard for all Screen Call interviews across the company. You can create your own scorecards or adjust the existing ones to align them with your company hiring goals."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.default.title"
                defaultMessage="Default interviews"
              />
            ),
            icon: 'Chat',
            text: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.default.text"
                defaultMessage="We provide you with a default list of interviews to help you start hiring from Day 1. Feel free to explore the settings and adjust interview questions. You can even add custom interview stages by clicking “Add interview stage” button."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
