import React from 'react'
import { getColor } from '@src/styles/colors'
import { Color, Text } from '@revolut/ui-kit'
import { FinalGrade } from '@src/interfaces/performance'
import { RevolutTheme, useTheme } from '@src/styles/theme'
import { FinalGradeToShortString, FinalGradeToString } from '@src/interfaces/scorecard'
import { GradesMapInterface } from '@src/utils/grades'

type Props = {
  grade?: FinalGrade | null
  inverse?: boolean
  className?: string
  withPending?: boolean
}

export const getColorsAndGrade = (theme: RevolutTheme, grade?: FinalGrade | null) => {
  switch (grade) {
    case FinalGrade.Poor:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.RED),
        grade: FinalGradeToShortString[FinalGrade.Poor],
      }
    case FinalGrade.AverageMinus:
      return {
        color: getColor(theme, Color.FOREGROUND),
        background: getColor(theme, Color.GREY_50_OPAQUE_10),
        grade: FinalGradeToShortString[FinalGrade.AverageMinus],
      }
    case FinalGrade.AveragePlus:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.TEAL),
        grade: FinalGradeToShortString[FinalGrade.AveragePlus],
      }
    case FinalGrade.Strong:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.TEAL),
        grade: FinalGradeToShortString[FinalGrade.Strong],
      }
    case FinalGrade.Exceptional:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.TEAL),
        grade: FinalGradeToShortString[FinalGrade.Exceptional],
      }
    default:
      return null
  }
}

export const getInverseColorsAndGrade = (
  theme: RevolutTheme,
  grade?: FinalGrade | null,
  gradesMap?: GradesMapInterface,
) => {
  const FinalGradeMap = gradesMap || FinalGradeToString
  switch (grade) {
    case FinalGrade.Poor:
      return {
        color: getColor(theme, Color.RED),
        background: 'transparent',
        grade: FinalGradeMap[FinalGrade.Poor],
      }
    case FinalGrade.AverageMinus:
      return {
        color: getColor(theme, Color.FOREGROUND),
        background: 'transparent',
        grade: FinalGradeMap[FinalGrade.AverageMinus],
      }
    case FinalGrade.AveragePlus:
      return {
        color: getColor(theme, Color.FOREGROUND),
        background: 'transparent',
        grade: FinalGradeMap[FinalGrade.AveragePlus],
      }
    case FinalGrade.Strong:
      return {
        color: getColor(theme, Color.GREEN),
        background: 'transparent',
        grade: FinalGradeMap[FinalGrade.Strong],
      }
    case FinalGrade.Exceptional:
      return {
        color: getColor(theme, Color.GREEN),
        background: 'transparent',
        grade: FinalGradeMap[FinalGrade.Exceptional],
      }
    default:
      return null
  }
}

const PerformanceGrade = ({ grade, inverse, className, withPending }: Props) => {
  const theme = useTheme()
  const data = inverse
    ? getInverseColorsAndGrade(theme, grade)
    : getColorsAndGrade(theme, grade)

  if (!data) {
    if (withPending) {
      return (
        <Text className={className} color={Color.GREY_TONE_20}>
          Pending
        </Text>
      )
    }

    return null
  }

  return (
    <Text className={className} backgroundColor={data.background} color={data.color}>
      {data.grade}
    </Text>
  )
}

export default PerformanceGrade
